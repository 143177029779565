.category-layer-wrapper {
    @include full-width-wrapper;
    //background: $color-black-10;
}

.category-layer {
    @include container;
    margin-bottom: 14px;
    padding-top: 24px;
    padding-bottom: 4px;

    @screen tablet {
        padding-top: 28px;
        padding-bottom: 28px;
    }

    .headline {
        margin-bottom: 0;
        @media (max-width: 767.98px) {
            @apply text-h3;
        }
    }

    &--wrapper {
        @screen tablet {
            @apply grid gap-5 grid-cols-4;
        }

        @screen desktop {
            @apply gap-6 grid-cols-6;
        }

    }

    &--item {
        @apply block;

        margin-top: 16px;
        @screen tablet {
            margin-top: 20px;
        }

        @screen desktop {
            margin-top: 30px;
        }
    }

    &--img {
        overflow: hidden;

        @media (max-width: 767.98px) {
            height: 96px;
        }
    }

    &--name {
        margin-top: 8px;
        @apply font-openSans font-bold text-xs mb-0;

        @screen desktop {
            @apply text-sm;
        }
    }

    &--placeholder {
        width: 100%;
        padding-top: 56.2%;
        background: $color-black-10;
    }
}

