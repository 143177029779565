@import "../variables";
@import "../mixin/common";

/* ==========================================================================
   #PRODUCT INFO
========================================================================== */

.product-detail-feature {
    .product-info {
        .feature-slider,
        .review-product {
            @screen laptop {
                margin-right: 25px;
            }

            @screen desktop {
                margin-right: 64px;
            }
        }

        .product-detail-video {
            position: relative;
            aspect-ratio: 16/9;
            margin: 40px 0;

            @screen laptop {
                padding: 50px 32px;
                max-width: 1320px;
                margin: 0 auto;
            }

            .cmplazyload {
                width: 100%;
                height: 100%;
            }

            .cmplazypreviewiframe {
                background-image: unset !important;
                position: relative !important;
                top: unset !important;
                left: unset !important;
                width: 100% !important;
                height: 100% !important;
                z-index: auto !important;
            }
        }
        &--section {
            @apply mb-10;
        }
    }
}

.jump-mark {
    position: relative;
    z-index: 1;
    box-shadow:
        16px 0 0 0 $color-white-100,
        -16px 0 0 0 $color-white-100;
    background-color: $color-white-100;
    color: $color-black-100;

    &--bar {
        @apply flex flex-no-wrap overflow-x-auto gap-6;
        row-gap: 28px;

        @screen laptop {
            @apply justify-center;
            @include hide-scrollbars();
            row-gap: 40px;
            box-shadow: 1px 0 0 0 $color-black-30;
        }

        &::before,
        &::after {
            position: absolute;
            right: -16px;
            background: $color-black-30;
            width: 100vw;
            height: 1px;
            content: "";

            @screen laptop {
                right: 0;
            }
        }

        &::before {
            top: 0;
        }

        &::after {
            bottom: 0;
        }
    }

    &--item {
        &.product-description {
            &.active--description {
                button {
                    @apply outline-none;
                    text-shadow:
                        -0.03ex 0 $color-black-100,
                        0.03ex 0 $color-black-100;

                    &::after {
                        @apply bg-black;
                    }
                }
            }
        }

        &.product-details-materials {
            &.active--materials {
                button {
                    @apply outline-none;
                    text-shadow:
                        -0.03ex 0 $color-black-100,
                        0.03ex 0 $color-black-100;

                    &::after {
                        @apply bg-black;
                    }
                }
            }
        }

        &:last-child {
            padding-right: 16px;
        }

        @screen laptop {
            &:last-child {
                padding-right: 0;
            }
        }
    }

    &--item-link {
        padding: 20px 0;
        @apply flex font-OpenSans text-base content-center relative whitespace-no-wrap;

        span {
            display: inline-block;
            margin: 0 2px;
        }

        &::after {
            bottom: 17px;
            transition: 0s ease-in background-color;
            height: 2px;
            content: "";
            @apply absolute left-0 right-0 bg-white;
        }

        &:hover,
        &:focus {
            @apply outline-none;
            text-shadow:
                -0.03ex 0 $color-black-100,
                0.03ex 0 $color-black-100;

            &::after {
                @apply bg-black;
            }
        }
    }

    .star-group {
        display: inline-block;
        height: 24px;
        line-height: 24px;
    }
}

.feature-slider {
    @apply font-OpenSans text-sm;
    margin: 0 -16px;
    background-color: $color-black-10;
    padding: 38px 0;
    overflow: hidden;

    @screen tablet {
        margin: 0;
    }

    @screen laptop {
        padding: 44px 20px;
        margin-top: 40px;
        margin-bottom: 30px;
    }

    svg {
        margin: 0 auto;
    }

    .slick-prev {
        left: -20px;
    }

    .slick-next {
        right: -20px;
    }

    &--item {
        padding: 0 10px;
        text-align: center;

        @screen laptop {
            padding: 0 6.38%;
        }
    }

    &--headline {
        @apply font-bold text-sm;
        margin-top: 10px;
        margin-bottom: 2px;
    }

    &--desc {
        @apply font-normal;
    }
}

.product-desc-and-mate {
    @apply flex flex-col gap-10;

    @screen desktop {
        max-width: 1320px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        @apply flex-row;

        &-only {
            .description-teaser {
                @screen desktop {
                    flex: 0 0 100%;
                    max-width: 100%;
                }
            }
        }
    }
}

.description-teaser {
    @apply overflow-hidden gap-6;

    @screen laptop {
        padding-left: 32px;
        padding-right: 32px;
    }

    @screen desktop {
        flex: 0 0 47%;
        max-width: 47%;
    }

    &--content {
        p,
        table,
        th,
        td,
        ul,
        li {
            @apply font-OpenSans;
        }

        ul {
            padding-left: 20px;
            list-style: disc;
        }

        th {
            @apply font-bold text-left;
        }

        @screen tablet {
            // @apply flex-1;
            padding: 0;
        }
    }

    &--headline,
    &--title {
        margin-right: 0;
        margin-left: 0;
    }

    &--title {
        @apply font-OpenSans text-base;
        margin-top: 24px;
        margin-bottom: 3px;
    }

    &--media-wrapper {
        height: 280px;
        text-align: center;

        @screen tablet {
            height: 440px;
            text-align: right;

            .is-md--reverse & {
                text-align: left;
            }

            .teaser-item-block {
                @apply self-center;
                flex: auto !important;
            }

            .is-video & {
                @apply flex;
                height: auto;
            }
        }

        img {
            display: inline-block;
            width: unset;
            max-width: 100%;
            max-height: 100%;
        }
    }

    &--media {
        @media (max-width: 767.98px) {
            margin-top: 16px;
        }

        @screen tablet {
            @apply flex-1;
        }
    }

    @screen tablet {
        &.is-md--reverse {
            @apply flex-row-reverse;
        }
    }
}
