.loading-button:after {
    content: ' ';
    display: block;
    width: 25px;
    height: 25px;
    margin: auto;
    border-radius: 50%;
    animation: loading-button 1.5s linear infinite;

    @apply button-loader-color-themed;
}

@keyframes loading-button {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
