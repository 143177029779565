//Stores Sidebar
.off-canvas-store {
    &__overlay {
        position: fixed;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        cursor: none;
        opacity: 0;
        background-color: $color-black-100;
        @include transitionPrefixMultiple(calc(#{$transition-duration} * 6), opacity);

        &.active {
            z-index: 100;
            cursor: auto;
            opacity: .24;
        }
    }

    &__container {
        position: fixed;
        z-index: 101;
        top: 0;
        right: 0;
        width: 100%;
        max-width: 100%;
        height: 100%;
        transform: translate(100%, 0);
        background-color: $color-white-100;
        box-shadow: 0 3px 14px 2px rgba(0, 0, 0, 0.12);
        @include transitionPrefixMultiple(calc(#{$transition-duration} * 5), transform);

        @screen laptop {
            width: 350px;
        }

        @screen desktopx {
            width: 440px;
        }

        &.active {
            transform: translate(0, 0);
        }

        &:before {
            position: absolute;
            z-index: 3;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 66px;
            content: '';
            cursor: none;
            @apply bg-gradient-to-t from-white;
        }
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
    }

    &__header {
        display: flex;
        padding: 16px;

        @screen desktopx {
            padding: 24px;
        }
    }

    &__form {
        position: relative;
        padding: 0 16px 13px;

        @screen desktopx {
            padding: 0 24px 13px;
        }

        .off-canvas-store__place-predictions {
            position: absolute;
            z-index: 2;
            top: 100%;
            left: 0;
            display: flex;
            overflow-y: scroll;
            flex-direction: column;
            width: 100%;
            max-height: 300px;
            background-color: $color-black-10;
            @include hide-scrollbars();

            .place-prediction-item {
                width: 100%;
                padding: 16px;
                cursor: pointer;
                border-bottom: 1px solid $color-black-20;
                @include transitionPrefixMultiple(calc(#{$transition-duration} * 3), background-color);
            }
        }

        input[type='text'] {
            padding-right: 80px;
        }

        .btn-store-sidebar {
            position: absolute;
            top: 0;
            display: flex;
            align-items: center;
            width: 32px;
            height: 48px;
            justify-items: center;

            &.btn-location-store-sidebar {
                right: 32px;
            }

            &.btn-search-store-sidebar {
                right: 0;
            }
        }
    }

    &__list-store {
        position: relative;
        overflow-x: scroll;
        @include hide-scrollbars();
        max-height: calc(100vh - 135px);
        padding: 0 16px;

        @screen desktopx {
            padding: 0 24px;
        }

        .off-canvas-store-item {
            position: relative;
            padding: 12px 0;
            cursor: pointer;
            border-bottom: 1px solid $color-black-20;
            @apply font-OpenSans;

            &__checkbox {
                position: absolute;
                top: 0;
                right: 0;
                display: flex;
                align-items: center;
                width: 20px;
                height: 100%;

                &.active {
                    span {
                        &:before {
                            position: absolute;
                            top: calc(50% - 5px);
                            right: 4px;
                            width: 10px;
                            height: 10px;
                            content: '';
                            border-radius: 50%;
                            background-color: $color-black-90;
                        }
                    }
                }

                span {
                    position: relative;
                    width: 20px;
                    height: 20px;
                    border: 1px solid $color-black-30;
                    border-radius: 50%;
                    background-color: rgba(0, 0, 0, .1);
                }
            }

            p {
                margin-bottom: 4px;
            }

            &__name {
                font-size: 16px;
                overflow: hidden;
                align-items: center;
                max-width: calc(100% - 60px);
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            &__distance {
                font-size: 12px;
                font-weight: 600;
                display: flex;
                align-items: center;
                justify-content: center;
                min-width: 49px;
                height: 20px;
                margin-left: 16px;
                border-radius: 4px;
                background-color: $color-black-20;

                span {
                    margin-left: 2px;
                }
            }

            &__status {
                font-size: 14px;
                font-weight: 600;
                text-transform: capitalize;

                &.available {
                    color: $color-success;
                }

                &.out-of-stock {
                    color: $color-not-stock;
                }

                &.no-store {
                    color: $color-black-50;
                }
            }
        }
    }
}

// Animation for appearance

.off-canvas-store-enter {
    .off-canvas-store__overlay {
        opacity: 0;
    }

    .off-canvas-store__container {
        transform: translate(100%, 0);
    }
}

.off-canvas-store-enter-active,
.off-canvas-store-enter-done {
    .off-canvas-store__overlay {
        z-index: 100;
        cursor: auto;
        opacity: .24;
    }

    .off-canvas-store__container {
        transform: translate(0, 0);
    }
}

.off-canvas-store-exit {
    .off-canvas-store__overlay {
        z-index: 100;
        cursor: auto;
        opacity: .24;
    }

    .off-canvas-store__container {
        transform: translate(0, 0);
    }
}

.off-canvas-store-exit-active {
    .off-canvas-store__overlay {
        opacity: 0;
    }

    .off-canvas-store__container {
        transform: translate(100%, 0);
    }
}
