.gallery-grid-component {
    & > a {
        & > img {
            @screen xsm {
                height: 130px;
            }

            @screen sm {
                height: 250px;
            }

            @screen tablet {
                height: 260px;
            }

            @screen laptop {
                height: 350px;
            }
        }
    }
}

.disable-overlay {
    & > a {
        pointer-events: none;
    }
}
