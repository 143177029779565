.minimal-footer-component {
    background: $color-black-100;
    color: $color-white-100;

    .footer-minimal {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        padding: 24px 16px;
        width: 100%;
        max-width: 1592px;

        @screen laptop {
            flex-direction: row;
            justify-content: space-between;
            padding: 48px 16px;
        }

        @screen desktop {
            padding: 48px 40px;
        }

        .footer-minimal-column {
            margin-bottom: 32px;
            text-align: center;
            font-size: 14px;
            font-weight: 400;

            @screen laptop {
                text-align: left;
                margin-bottom: 0;
                flex: 0 0 calc(100% / 3);
                max-width: calc(100% / 3);
            }

            &:last-of-type {
                margin-bottom: 0px;
            }

            a, .btn > span {
                @apply text-sm mb-1;
            }

            .btn-minimal-footer {
                align-items: center;
                padding: 0;
                height: auto;
                text-transform: none;
                letter-spacing: 0px;
                font-weight: 400;

                svg {
                    margin-right: 10px;

                    &:not(.no-overrides) {
                        &,
                        * {
                            fill: $color-white-100;
                        }
                    }
                }

                span {
                    &:last-of-type {
                        @include link-hover--underline;
                    }
                }
            }

            .title {
                margin-bottom: 8px;
                text-transform: uppercase;
                font-family: $ff-sport2000;
                font-weight: 700;

                @screen laptop {
                    margin-bottom: 10px;
                }
            }

            .list-footer-link {
                li {
                    margin-bottom: 10px;
                    text-decoration: underline;

                    @screen laptop {
                        display: inline-block;
                        margin-right: 20px;
                    }

                    @screen desktop {
                        margin: 0 30px 0 0;
                    }
                }
            }
        }
    }
}
