.input {
    &--wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
    }

    &--border {
        position: absolute;
        top: 47px;
        right: 0;
        left: 0;
        height: 1px;
        transition: $transition-duration transform $transition-ease;
        transform: scale3d(0, 1, 1);
        transform-origin: 0 50%;
        background: $color-black-100;

        .dark-mode & {
            background: $color-white-100;
        }
    }

    &--hint {
        font-size: 12px;
        line-height: 1.333;
        margin-top: 5px;
        user-select: none;

        &:empty {
            display: none;
        }
    }
}

/*
    Inputs
    Text Inputs
*/
input[type='text'],
input[type='tel'],
input[type='email'],
input[type='search'],
input[type='password'],
input[type='number'],
textarea {
    width: 100%;
    color: $color-black-100;
    border: 0;
    outline: 0;
    background: $color-black-10;
    box-shadow: none;
    appearance: none;

    &:not(:disabled) {
        @media (hover: hover) {
            &:hover {
                ~ label {
                    color: $color-black-100;
                }
            }
        }

        &:focus {
            outline: none;
            box-shadow: none;

            ~ label {
                font-size: 12px;
                transform: translate(-16px, -32px);
                color: $color-black-100;
            }

            ~ .input--border {
                transform: scale3d(1, 1, 1);
            }

            .error-message-text-system-error {
                color: $color-red-60;
                background: transparent;
            }
        }

        .is--valid & {
            ~ .input--border {
                transform: scale3d(1, 1, 1);
                background: $color-absolute-green-60;
            }

            ~ .input--hint {
                color: $color-absolute-green-60;
            }
        }

        .is--invalid & {
            ~ .input--border {
                transform: scale3d(1, 1, 1);
                background: $color-red-60;
            }

            ~ .input--hint {
                color: $color-red-60;
            }
        }
    }

    &:disabled {
        cursor: default;
        color: $color-black-50;
        background: $color-black-20;

        ~ .input--hint {
            color: $color-black-50;
        }
    }

    &::-ms-clear {
        display: none;
    }

    &::-moz-focus-inner {
        padding: 0;
        border: 0;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        margin: 0;
        -webkit-appearance: none;
    }

    &::placeholder {
        opacity: 0;
    }

    &:not(:placeholder-shown) {
        ~ label {
            font-size: 12px;
            transform: translate(-16px, -32px);
        }
    }

    ~ label {
        position: absolute;
        top: 12px;
        right: 16px;
        left: 16px;
        transition: $transition-duration all $transition-ease;
        pointer-events: none;
        color: $color-black-50;
    }

    .dark-mode & {
        color: $color-white-100;
        background: $color-black-80;

        &:not(:disabled) {
            @media (hover: hover) {
                &:hover {
                    ~ label {
                        color: $color-white-100;
                    }
                }
            }

            &:focus {
                ~ label {
                    color: $color-white-100;
                }
            }
        }

        ~ label {
            color: $color-black-50;
        }
    }
}

textarea {
    height: 160px;
    padding: 12px 16px;
    resize: none;

    ~ .input--border {
        top: 159px;
    }
}

/*
    Inputs

    Text Inputs
*/

input[type='text'],
input[type='tel'],
input[type='number'],
input[type='email'],
input[type='search'],
input[type='password'] {
    line-height: 48px;
    padding: 0 16px;
}

/*
    Inputs

    Hide arrow for type number
*/

input[type='number'] {
    -moz-appearance: textfield;
}

/*
    Inputs

    Clears the ‘X’ from Chrome
*/

input[type='search'] {
    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
        display: none;
    }
}

/*
    Inputs

    Removes the auto-fill background color from Chrome
*/

input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}

input[data-autocompleted] {
    background-color: transparent !important;
}

/*
    Inputs

    Labels
*/

label {
    a {
        text-decoration: underline;
    }
}