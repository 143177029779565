@import '../variables';

.progress-bar {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 4px;
    margin-top: 12px;
    border-radius: 3px;
    background: $color-black-30;

    span {
        position: absolute;
        top: 0;
        left: 0;
        height: 4px;
        border-radius: 3px;
        background-color: $color-black-100;
    }

    .theme--dark & {
        background-color: $color-white-100;

        span {
            background-color: $color-black-50;
        }
    }
}

progress {
    @apply block border-none;
    width: 100%;
    height: 6px;
    border-radius: 3px;
    background: $color-black-30;

    //For Firefox and IE
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &::-moz-progress-bar {
        border-radius: 3px;
        background: $color-black-100;
    }

    .theme--dark & {
        &[value] {
            &::-webkit-progress-value {
                border-radius: 3px;
                background: $color-black-30;
            }
        }
    }

    //For Chrome
    &[value] {
        &::-webkit-progress-bar {
            border-radius: 3px;
            background: $color-black-30;
        }

        &::-webkit-progress-value {
            border-radius: 3px;
            background: $color-black-100;
        }
    }
}

// Comparison Bar
.comparison-bar {
    &__bg {
        @apply relative;
    }

    &__intersections {
        @apply flex overflow-hidden;
        height: 6px;
        margin: 11px 0;
        border-radius: 3px;
        background-color: $color-black-30;
    }

    &__indicator {
        @apply absolute;
        top: -3px;
        width: 24px;
        height: 12px;
        transform: translateX(-50%);
        background: $color-black-100;
    }

    &__title {
        @apply text-sm;
    }
}

// End Comparison Bar
