.sort-algolia {
    display: flex;
    position: relative;
    justify-content: space-between;
    margin-bottom: 20px;

    .sort-by-algolia {
        position: absolute;
        right: 0;
        border: 1px solid $color-black-20;
        background-color: $color-black-10;
        min-width: 200px;
        max-width: 230px;

        &-button {
            position: relative;
            padding: 0 25px 0 16px;
            width: 100%;
            text-align: left;
            line-height: 48px;

            &-icon {
                position: absolute;
                top: 50%;
                right: 15px;
                margin-top: -6px;
            }
        }

        &-wrap {
            position: absolute;
            z-index: 1;
            outline: 1px solid $color-black-20;
            background-color: $color-black-10;
            width: 100%;
            line-height: 48px;

            .option {
                cursor: pointer;
                padding: 0 25px 0 16px;
            }
        }
    }

    &--count {
        display: flex;
        align-items: center;
        height: 40px;
        color: $color-black-40;
        font-size: 16px;

        span {
            margin-left: 2px;
        }
    }
}
