// Cluster Style for map

.gm-bundled-control {
    top: 80px !important;
    bottom: inherit !important;

    @screen tablet {
        top: 100px !important;
    }

    @screen laptop {
        top: 179px !important;
    }
}

.cluster-marker {
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 10px;
    cursor: pointer;
    color: $color-white-100;
    @apply font-OpenSans;
    border: 1px solid $color-white-100;
    border-radius: 50%;
    background-color: $color-black-70;
}

.crime-marker {
    position: absolute;
    top: -30px;
    left: -10.5px;
    width: 21px;
    height: 30px;
    border: none;
    background-image: url("../../icons/sport2000-icons/google-location.svg");
    background-repeat: no-repeat;
    background-size: contain;

    &.active {
        top: -44px;
        left: -15.5px;
        width: 31px;
        height: 44px;
        background-image: url("../../icons/sport2000-icons/google-location-active.svg");

        &.teamsport_experts,
        &.absolute_teamsport,
        &.outdoorprofi {
            background-image: url("../../icons/sport2000-icons/google-location-green-active.svg");
        }

        &.absolute_run,
        &.running_experts,
        &.ski_expert {
            background-image: url("../../icons/sport2000-icons/google-location-blue-active.svg");
        }

        &.outdoor_expert {
            background-image: url("../../icons/sport2000-icons/google-location-orange-active.svg");
        }
    }
}

// Search Form

.store-finder {
    &--wrapper {
        min-height: calc(100vh - 200px);
    }

    &--btn-toggle-slider {
        display: none !important;
        margin-left: 16px;

        &.btn-default {
            font-size: 14px;
            font-weight: 400;
            position: absolute;
            z-index: 1;
            top: 40px;
            right: 70px;
            min-width: 142px;
            padding: 10px 12px;
            letter-spacing: normal;
            text-transform: none;
            border-radius: 4px;

            svg {
                width: 36px;
                height: 26px;
                margin-right: 10px;
            }
        }

        @media (min-width: $screen-from-laptop) {
            display: flex !important;
        }

        @screen desktop {
            margin-left: 40px;
        }
    }

    &--search-form-container {
        width: 100%;
        max-width: 2640px;
        padding-right: 0;
        padding-left: 0;

        @screen laptop {
            position: absolute;
            z-index: 19;
            top: 40px;
            left: 0;
            width: 50%;
            padding-right: 16px;
            padding-left: 16px;
        }

        @screen desktop {
            width: 45%;
            padding-left: 40px;
            padding-right: 40px;
        }

        @screen desktopx {
            width: 40%;
        }
    }

    &--search-form-wrapper {
        width: 100%;
        background-color: $color-black-10;

        @screen laptop {
            background-color: $color-white-100;
            box-shadow:
                0 1px 10px 0 rgba(0, 0, 0, 0.12),
                0 2px 4px -1px rgba(0, 0, 0, 0.2);
        }

        > h3 {
            padding: 16px 16px 0;
        }
    }

    // Start Store Form

    &--search-form {
        display: flex;
        padding-right: 16px;
        padding-bottom: 12px;
        padding-left: 16px;
        gap: 8px;

        .btn-filter-store {
            min-width: 132px;

            svg {
                margin-left: 10px;
            }
        }

        .btn-search-store {
            min-width: 92px;
        }

        .search-finder--input {
            position: relative;
            flex-grow: 1;

            input {
                padding-right: 48px;
                background-color: $color-white-100;

                &::placeholder {
                    opacity: 1;
                }

                @screen laptop {
                    background-color: $color-black-10;
                }
            }

            button {
                position: absolute;
                top: 0;
                right: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 48px;
                height: 48px;
                cursor: pointer;
            }

            .search-finder--prediction-list {
                position: absolute;
                z-index: 3;
                top: 100%;
                left: 0;
                display: flex;
                overflow-y: scroll;
                flex-direction: column;
                width: 100%;
                max-height: 430px;

                .place-prediction-item {
                    width: 100%;
                    padding: 16px;
                    cursor: pointer;
                    border-bottom: 1px solid $color-black-10;
                    @include transitionPrefixMultiple(calc(#{$transition-duration} * 3), background-color);
                    background-color: $color-white-100;

                    &:hover {
                        background-color: $color-black-10;
                    }
                }
            }
        }
    }

    // Start Store Slider

    &--slider {
        position: absolute;
        z-index: 18;
        bottom: 30px;
        left: 0;
        width: 100%;

        .store-finder--slider-wrapper {
            display: block;
            overflow: scroll;
            width: 100%;
            height: 0;
            white-space: nowrap;
            pointer-events: none;
            opacity: 0;
            @include transitionPrefixMultiple(calc(#{$transition-duration} * 3), opacity, height);

            @screen laptop {
                overflow: hidden;
            }

            &.active {
                height: auto;
                pointer-events: auto;
                opacity: 1;
            }
        }

        .store-slider-item {
            font-size: 14px;
            position: relative;
            display: inline-block;
            width: 85%;
            margin-right: 16px;
            padding: 20px;
            cursor: pointer;
            background-color: $color-white-100;
            box-shadow:
                0 1px 10px 0 rgba(0, 0, 0, 0.12),
                0 2px 4px -1px rgba(0, 0, 0, 0.2);

            @screen tablet {
                font-size: 16px;
            }

            &.active {
                &:before {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    content: "";
                    pointer-events: none;
                    border: 3px solid $color-red-60;
                    background-color: transparent;
                }

                &.teamsport_experts,
                &.absolute_teamsport,
                &.outdoorprofi {
                    &:before {
                        border-color: $color-absolute-green-60;
                    }
                }

                &.absolute_run,
                &.running_experts,
                &.ski_expert {
                    &:before {
                        border-color: $color-absolute-blue-60;
                    }
                }

                &.outdoor_expert {
                    &:before {
                        border-color: $color-brand-outdoor-expert;
                    }
                }
            }

            @screen tablet {
                width: 45%;
            }

            @screen laptop {
                margin-right: 0;
                display: block;
                width: auto;
            }

            svg {
                margin-right: 8px;
            }

            &--logo {
                width: 100%;
                margin-top: 12px;
                padding: 15px 0;
                text-align: center;
                background-color: $color-black-10;
                overflow-y: scroll;
                @include hide-scrollbars();

                svg {
                    display: inline-block;
                    margin-right: 0;
                }
            }

            .store-finder-row {
                display: flex;
                align-content: center;
                align-items: center;
                padding: 12px 0;
                border-bottom: 1px solid $color-black-30;

                &:last-child {
                    padding-top: 16px;
                    padding-bottom: 0;
                    border-bottom: none;
                }

                &:first-child {
                    padding-top: 0;
                }

                button {
                    display: flex;
                    align-content: center;
                    align-items: center;
                    text-align: left;

                    svg {
                        margin-right: 8px;
                    }
                }
            }

            &--determine {
                font-size: 14px;

                .select-store-circle {
                    display: flex;
                    width: 20px;
                    height: 20px;
                    border: 1px solid $color-black-30;
                    border-radius: 50%;
                    background-color: rgba(0, 0, 0, 0.1);
                    justify-items: flex-end;

                    &.active {
                        position: relative;

                        &:before {
                            position: absolute;
                            top: 4px;
                            left: 4px;
                            width: 10px;
                            height: 10px;
                            content: "";
                            border-radius: 50%;
                            background-color: $color-black-90;
                        }
                    }
                }
            }

            &--actions {
                a {
                    &:first-child {
                        width: calc(60% - 12px);
                        margin-right: 12px;
                    }

                    &:last-child {
                        width: 40%;
                    }
                }
            }

            &--address {
                &.store-finder-row {
                    align-items: flex-start;
                    flex-direction: column;
                }

                .store-slider-ico-location {
                    position: relative;
                    top: 2px;
                }

                .store-slider-item-address-detail {
                    display: flex;
                    flex-grow: 1;

                    p {
                        flex-grow: 1;
                        margin-bottom: 0;
                        white-space: initial;
                    }
                }

                .store-slider-distance {
                    font-size: 12px;
                    font-weight: 600;
                    @apply font-OpenSans;
                    line-height: 22px;
                    min-width: 46px;
                    height: 22px;
                    padding: 0 6px;
                    text-align: center;
                    border-radius: 4px;
                    background-color: $color-black-20;
                }
            }

            &--time,
            &--service {
                font-size: 14px;

                p {
                    margin-bottom: 4px;
                    text-align: left;
                    text-transform: capitalize;
                }

                button {
                    svg {
                        &:last-child {
                            margin-right: 0;
                            margin-left: 8px;
                        }
                    }
                }

                &.store-finder-row {
                    flex-direction: column;
                }

                .services-container {
                    padding-top: 10px;
                    padding-left: 28px;
                }

                .opening-time-container,
                .services-container {
                    overflow: hidden;
                    width: 100%;
                    max-height: 0;
                    @include transitionPrefixMultiple(calc(#{$transition-duration} * 5), max-height);

                    .openning-hours {
                        padding-top: 10px;
                        padding-left: 28px;
                    }
                }

                &.active {
                    .opening-time-container,
                    .services-container {
                        max-height: 500px;
                    }

                    button {
                        svg {
                            &:last-child {
                                transform: rotate(180deg);
                                @include transitionPrefixMultiple(calc(#{$transition-duration} * 5), transform);
                            }
                        }
                    }
                }
            }
        }

        .slider {
            padding: 0 0 0 16px;

            @screen desktop {
                padding-left: 40px;
            }

            .swiper-container {
                padding-top: 10px;
                padding-bottom: 10px;
            }

            .swiper-wrapper {
                align-items: flex-end;
            }

            .swiper-button-prev,
            .swiper-button-next {
                z-index: 2;
                background-color: $color-black-90;

                svg {
                    path {
                        fill: $color-white-100;
                    }
                }
            }

            .swiper-button-prev {
                left: 0;
            }

            .swiper-button-next {
                right: 0;
            }
        }
    }

    // Start Filter
    &--filter {
        position: fixed;
        z-index: 99999;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        transform: translate(100%, 0);
        pointer-events: none;
        background-color: $color-white-100;
        @include transitionPrefixMultiple(calc(#{$transition-duration} * 5), transform);

        @screen laptop {
            display: block;
            transform: translate(0, 0);
            position: static;
            pointer-events: auto;
            @include transitionPrefixMultiple(calc(#{$transition-duration} * 3), opacity);
        }

        &.active {
            transform: translate(0, 0);
            pointer-events: auto;

            @screen laptop {
                .store-finder--filter-properties,
                .store-finder--filter-actions {
                    height: auto;
                    opacity: 1;
                    pointer-events: auto;
                }

                .store-finder--filter-actions {
                    padding: 25px 16px 16px !important;
                }
            }
        }

        .store-finder--filter-properties,
        .store-finder--filter-actions {
            @screen laptop {
                height: 0;
                opacity: 0;
                padding: 0 !important;
                pointer-events: none;
            }
        }

        .store-finder--filter-properties {
            display: flex;
            flex-direction: column;
            flex-grow: 1;

            @screen tablet {
                display: block;
            }
        }

        .store-finder--filter-header-mobile {
            display: flex;
            padding: 20px 16px;

            h4 {
                display: flex;
                flex-grow: 1;
                justify-content: center;
            }
        }

        .filter-algolia--panel-wrapper {
            display: block;
        }

        .filter-algolia--panel-values,
        .filter-algolia--panel-select-list {
            position: static;
        }

        .filter-panel-select {
            .filter-buttons-toggle {
                font-weight: bold;

                @screen tablet {
                    border-bottom: 1px solid $color-black-30;
                }
            }
        }

        .store-finder--filter-actions {
            padding: 25px 16px 16px;
            border-top: 1px solid $color-black-40;

            @screen tablet {
                border-top: none;
            }

            button {
                &.btn-secondary {
                    width: calc(50% - 6px);
                    max-width: 100%;
                    margin-right: 6px;
                }

                &.btn-default {
                    width: calc(50% - 6px);
                    max-width: 100%;
                    margin-left: 6px;
                }
            }
        }

        .filter-algolia--panel-values {
            width: 100%;
        }

        .filter-algolia--panel-select-list {
            .form-check {
                label {
                    width: calc(100% + 7px);

                    &.is--logo {
                        display: flex;
                        align-items: center;

                        span {
                            display: flex;
                            flex-grow: 1;
                            justify-content: flex-end;
                        }
                    }

                    span {
                        display: block;
                        color: $color-black-50;
                    }
                }
            }
        }
    }

    //start current refinements
    &--current-refinment {
        display: flex;
        flex-wrap: wrap;
        padding: 0 16px;

        .store-finder--clear-refinement {
            font-size: 12px;
            display: flex;
            align-items: center;
            flex-direction: row;
            height: 28px;
            margin-right: 12px;
            @apply font-OpenSans;
            margin-bottom: 12px;
            padding: 0 8px;
            border-radius: 6px;
            background-color: $color-black-20;

            &.is-reset-all {
                border: 1px solid $color-black-100;
                background-color: transparent;
            }

            //@screen laptop {
            //    margin-right: 12px;
            //}

            button {
                svg {
                    margin-left: 12px;
                }
            }
        }
    }

    // Button show all filter on mobile

    &--btn-filter-mobile {
        position: fixed;
        z-index: 19;
        bottom: 64px;
        left: 0;
        width: 100%;
        color: $color-black-100;
        border: none;
        border-top: 1px solid $color-black-20;
        background-color: $color-white-100;

        span {
            margin-right: 8px;
        }
    }
}

// reset page-body

.page-store-finder {
    .c-page-body {
        padding-bottom: 0 !important;
    }
}
