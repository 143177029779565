// This is just what's left of the folder 'backstage'
// still needs to evaluated and cleaned up, together
// with the catwalk styles (which often are dupes),
// but for now that's everything that is still in use.
@import 'shame';

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import 'grid';
@import 'animation';
@import './elements/markdown';

@import '../js/patterns/atoms/badge/index.scss';
@import '../js/patterns/atoms/button/index.scss';
@import '../js/patterns/atoms/input/index.scss';
@import '../js/patterns/atoms/select/index.scss';

@responsive {
    .relative-width {
        width: 100%;
        left: 0;
        right: 0;
        margin-left: 0px;
        margin-right: 0px;
    }

    .fixed-screen-width {
        width: 100vw;
        position: relative;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
    }
}

// hiding broken loader for now
.u-fade {
    // Hiding loader from Catwalk, as we're using the custom one
    @apply hidden;
}

.s-node,
.s-preview {
    @apply grid grid-rows-auto-1fr-auto min-h-full;
}

.c-page-foot {
    @apply row-start-3 row-end-4;
}

// Ensure even small images span their box
//
// This can happen if we deicde to deliver small images during server side
// rendering without known the device size.
img {
    width: 100%;
}

.e-tastic__frontastic\/boost\/product-details\/title,
.e-tastic__frontastic\/boost\/product-details\/description-panel,
.e-tastic__frontastic\/boost\/product-details\/markdown-panel,
.e-tastic__frontastic\/boost\/product-details\/price,
.e-tastic__frontastic\/boost\/product-details\/rating,
.e-tastic__frontastic\/boost\/product-details\/variant-selector,
.e-tastic__frontastic\/boost\/product-details\/add-to-cart-button,
.e-tastic__frontastic\/boost\/content\/feature-service,
.e-tastic__frontastic\/boost\/content\/icon-list {
    width: 100%;
    @media screen and (min-width: 768px) {
        margin-left: 32px;
    }
    @media screen and (min-width: 960px) {
        max-width: 406px;
        margin-left: auto;
    }
    @media screen and (min-width: 1024px) {
        max-width: 426px;
        margin: 0 auto;
    }
}

.adyen-checkout__button {
    background-color: #667EEA
}

.c-markdown a{
    color: #3B82F6;
}
