// zoomIn image effect
.hover-zoomin-image {
    @screen desktop {
        img {
            height: auto;
            @include scale(1, 1);
            @include transitionPrefixMultiple(calc(#{$transition-duration} * 5), transform);
        }

        &:hover {
            img {
                transform: scale(1.1, 1.1);
            }
        }
    }
}