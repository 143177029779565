.video-teaser {
    overflow: hidden;

    .title {
        margin-top: 20px;

        @screen tablet {
            padding: 0 20px;
        }
    }

    &__main {
        @screen tablet {
            flex-wrap: wrap;
            gap: 1px;
        }
    }


}

.teaser-item-wrap {
    position: relative;

    @media (max-width: 767px) {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &.video-show {
        .play-button {
            display: none;
            opacity: 0;
        }
    }

    & .teaser-video-bg {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}


.play-button {
    position: absolute;
    z-index: 2;
    bottom: 50%;
    left: 50%;
    width: 130px;
    height: 130px;
    transition: all 0.3s ease;
    transform: translate(-50%, 50%);
    background-clip: padding-box;

    &:hover {
        .circle {
            background-color: var(--color-current-theme);
        }

        svg {
            cursor: pointer;
            stroke: $color-white-100;
            fill: var(--color-current-theme);
        }
    }

    .circle {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 130px;
        height: 130px;
        margin: auto;
        transition: all 0.3s ease;
        border: 16px solid rgba(255, 255, 255, .2);
        border-radius: 50%;
        background-color: $color-white-100;
        background-clip: padding-box;
    }

    svg {
        transition: all 0.3s ease;
        fill: $color-white-100;
        stroke: var(--color-current-theme);
        stroke-width: 3;
    }
}

.cms-element-youtube-video {
    position: relative;
    align-items: center;
    justify-content: center;

    .cmplazypreviewiframe {
        position: absolute !important;
        z-index: auto !important;
        top: 0 !important;
        left: 0 !important;
        width: 100% !important;
        height: 100% !important;
    }

    &::before {
        display: block;
        width: 100%;
        padding-top: calc((9 / 16) * 100%);
        content: '';
    }

    &__video {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0 none;
    }
}
