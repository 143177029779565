.slick-list, .slick-slider, .slick-track {
    position: relative;
    display: block;
}

.slick-loading {
    .slick-slide, .slick-track {
        visibility: hidden;
    }
}

.slick-slider {
    box-sizing: border-box;
    margin: 0 -16px;
    user-select: none;
    -webkit-touch-callout: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;

    @screen laptop {
        margin: 0;
    }

    .slick-list, .slick-track {
        transform: translate3d(0, 0, 0);
    }
}

.slick-list {
    overflow: hidden;
    margin: 0;
    padding: 0;

    &:focus {
        outline: 0;
    }

    &.dragging {
        cursor: pointer;
    }
}

.slick-track {
    top: 0;
    left: 0;

    &::after, &:before {
        display: table;
        content: '';
    }

    &::after {
        clear: both;
    }
}

.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
}

[dir=rtl] .slick-slide {
    float: right;
}

.slick-slide {
    img {
        display: block;
    }

    &.slick-loading img {
        display: none;
    }

    &.dragging img {
        pointer-events: none;
    }
}

.slick-initialized .slick-slide {
    display: block;
}

.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
    display: none;
}

/*# sourceMappingURL=slick.min.css.map */
