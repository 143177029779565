.image-container-component {
    .image-container-wrapper {
        display: flex;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50%;
        position: relative;
    }

    .image-container-content {
        margin: 24px 16px;
        height: 100%;

        .image-content {
            width: 100%;

            @screen tablet {
                width: auto;
            }
        }

        &.image-background-padding {
            .image-content {
                padding: 16px;

                @screen tablet {
                    padding: 32px;
                }
            }
        }

        &.image-content-bottom {
            display: flex;
            align-items: flex-end;
        }

        @screen tablet {
            display: flex;
            align-items: center;

            &.image-content-right {
                justify-content: flex-end;
            }
        }

        @screen laptop {
            margin: 100px 56px;
        }

        .image-content {

            @screen tablet {
                max-width: 344px;
            }

            @screen laptop {
                max-width: 560px;
            }

            h3 {
                margin-left: 0;
                margin-right: 0;
            }

            p {
                font-size: 14px;
                line-height: 20px;
                font-weight: 400;

                @screen tablet {
                    font-size: 16px;
                    line-height: 24px;
                }
            }

            button {
                width: 100%;

                @screen tablet {
                    width: auto;
                }
            }
        }
    }
}
