@charset "UTF-8";

.slick-dots, .slick-next, .slick-prev {
    position: absolute;
    display: block;
    padding: 0;
}

.slick-dots li button:before, .slick-next:before, .slick-prev:before {
    font-family: slick;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-next, .slick-prev {
    font-size: 0;
    line-height: 0;
    top: 50%;
    width: 40px;
    height: 40px;
    cursor: pointer;
    -ms-transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    color: transparent;
    border: none;
    outline: 0;
    background: 0 0;
    @include transitionPrefixMultiple(calc(#{$transition-duration} * 5), transform);

    &:focus {
        outline: none;
    }
}

.slick-next {
    &:focus:before, &:hover:before {
        opacity: 1;
    }

    &:hover {
        @include translate(5px, -50%);
    }
}

.slick-prev {
    &:focus:before, &:hover:before {
        opacity: 1;
    }

    &:hover {
        @include translate(-5px, -50%);
    }
}

.slick-next.slick-disabled:before, .slick-prev.slick-disabled:before {
    opacity: .25;
}

.slick-next:before {
    font-size: 20px;
    line-height: 1;
    opacity: 1;
    color: $color-white-100;
}

.slick-prev {
    left: -44px;

    &:before {
        line-height: 1;
        position: absolute;
        top: 16px;
        left: 12px;
        width: 24px;
        height: 24px;
        background: url('../../../icons/sport2000-icons/arrow-thick-left.svg') no-repeat;
        background-position: center;
        background-size: contain;
    }
}

[dir=rtl] .slick-prev {
    right: -25px;
    left: auto;
}

.slick-prev:before {
    content: '';
}

.slick-next:before, [dir=rtl] .slick-prev:before {
    position: absolute;
    top: 16px;
    left: 12px;
    width: 16px;
    height: 10px;
    content: '';
    background-position: center;
    background-size: contain;
}

.slick-next {
    right: -44px;

    &:before {
        position: absolute;
        top: 16px;
        left: 12px;
        width: 24px;
        height: 24px;
        content: '';
        background: url('../../../icons/sport2000-icons/arrow-thick-right.svg') no-repeat;
        background-position: center;
        background-size: contain;
    }
}

[dir=rtl] .slick-next {
    right: auto;
    left: -25px;
}

.slick-dotted.slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
    bottom: -25px;
    width: 100%;
    margin: 0;
    list-style: none;
    text-align: center;

    li {
        position: relative;
        display: inline-block;
        width: 8px;
        height: 8px;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;

        button {
            font-size: 0;
            line-height: 0;
            display: block;
            width: 100%;
            height: 100%;
            cursor: pointer;
            color: transparent;
            border: 0;
            outline: 0;
            background: 0 0;

            &:focus, &:hover {
                outline: 0;
            }

            &:focus:before, &:hover:before {
                opacity: 1;
            }

            &:before {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                content: '';
                text-align: center;
                opacity: 0.25;
                border-radius: 50%;
                background-color: $color-black-100;
            }
        }

        &.slick-active button:before {
            opacity: 1;
            color: $color-black-100;
        }
    }
}

// Style for darkmode slider
.icon-white {
    .slick-prev {
        &:before {
            background-image: url('../../../icons/sport2000-icons/arrow-thick-left-w.svg');
        }
    }

    .slick-next {
        &:before {
            background-image: url('../../../icons/sport2000-icons/arrow-thick-right-w.svg');
        }
    }

    .slick-prev,
    .slick-next {
    }
}

/*# sourceMappingURL=slick-theme.min.css.map */
