/*
    Scrollbars

    Mozilla Firefox
*/

* {
    scrollbar-color: $color-black-20 $color-black-100;
}

/*
    Scrollbars

    Webkit Browsers (Chrome, Safari, Opera, Edge, etc)
*/

@screen tablet {
    * {
        &::-webkit-scrollbar {
            width: 12px;

            &-track {
                border-width: 0;
                background: $color-black-100;
            }

            &-thumb {
                &:vertical,
                &:horizontal {
                    border: 2px solid $color-black-100;
                    border-radius: 7px;
                    background: $color-black-20;

                    &:hover {
                        background-color: $color-black-20;
                    }
                }
            }
        }
    }
}