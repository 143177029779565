.error-message {
    margin-bottom: 20px;
    background: $color-alert-info-bg;

    &-product {
        display: flex;
        padding: 24px 16px;

        @screen tablet {
            padding: 24px;
        }

        &-icon {
            flex: 0 0 auto;
        }

        &-detail {
            flex: auto;
            margin: 0 15px;

            @screen tablet {
                margin: 0 30px;
            }

            &-title {
                margin-bottom: 16px;
                color: $color-alert-info;
                font-size: 16px;
                font-weight: 700;
            }

            &-list {
                &-product {
                    padding: 0 16px;
                    list-style: disc;

                    &-item {
                        margin-bottom: 16px;
                    }
                }

                &-title {
                    margin-bottom: 4px;
                    line-height: 20px;
                    font-size: 14px;
                    font-weight: 700;
                }

                &-variant {
                    display: flex;
                    color: $color-black-60;
                    font-size: 14px;

                    &-size {
                        margin-left: 3px;
                    }
                }
            }
        }

        &-close {
            flex: 0 0 auto;
            text-align: right;

            &-button {
                padding: 0;
                height: auto;
            }
        }
    }
}

.mini-cart {
    .error-message {
        margin-bottom: 10px;

        &-product {
            padding: 10px 16px;

            &-detail {
                margin: 0 15px;
                
                &-title {
                    margin-bottom: 10px;
                    font-size: 14px;
                }

                &-list {
                    &-product {
                        &-item {
                            margin-bottom: 10px;
                        }
                    }

                    &-title {
                        margin-bottom: 2px;
                        font-size: 14px;
                    }

                    &-variant {
                        font-size: 12px;
                    }
                }
            }

            &-icon {
                svg {
                    margin-top: 5px;
                    width: 15px;
                    height: 15px;
                }
            }

            &-close {
                &-button {
                    svg {
                        width: 15px;
                        height: 15px;
                    }
                }
            }
        }
    }
}
