@mixin transform($transforms) {
    transform: $transforms;
}

@mixin translate($x, $y) {
    @include transform(translate($x, $y));
}

@mixin rotate($deg) {
    @include transform(rotate(#{$deg}deg));
}

@mixin scale($x, $y) {
    @include transform(scale($x, $y));
}
