.tile-component {
    &-background {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 350px;
    }

    &-content {
        display: flex;
        flex-direction: column;
        height: 100%;

        &.tile--content-middle {
            justify-content: center;
        }

        &.tile--content-top {
            justify-content: start;
        }

        &.tile--content-bottom {
            justify-content: end;
        }

        &.tile--content-left {
            align-items: start;
        }

        &.tile--content-right {
            align-items: end;
        }

        &.tile--content-center {
            align-items: center;
        }
    }

    &--topic {
        margin-bottom: 10px;
        line-height: 24px;
        font-family: $ff-openSans;
        font-size: 16px;
    }

    &--title {
        margin-bottom: 20px;
        line-height: 32px;
        font-family: $ff-sport2000;
        font-size: 32px;
    }
}
