@mixin line-clamp($lineNumber) {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    @supports (-webkit-line-clamp: $lineNumber) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: $lineNumber;
        -webkit-box-orient: vertical;
    }
}
