.product-list {
    @screen tablet {
        padding-bottom: 64px;
    }

    &--component {
        .product-list--wrapper {
            margin: 0 -16px;

            @screen tablet {
                margin: 0;
            }
        }
    }

    &--load-more {
        @apply text-sm;
        padding-right: 16px;
        padding-left: 16px;

        @screen tablet {
            max-width: 376px;
            margin: 0 auto;
        }

        .product-status {
            @apply font-OpenSans text-sm text-center;

            .theme--dark & {
                color: $color-white-100;
            }

            strong {
                @apply font-bold;
            }
        }

        progress {
            height: 4px;
            margin-top: 16px;
            border-radius: 0;

            &::-moz-progress-bar {
                border-radius: 0;
            }

            //For Chrome
            &[value] {
                &::-webkit-progress-bar {
                    border-radius: 0;
                }

                &::-webkit-progress-value {
                    border-radius: 0;
                }
            }
        }

        .btn-load-more {
            font-size: 9px;
            width: 100%;
            margin-top: 32px;

            &:first-child {
                margin-right: 6px;
            }

            &:last-child {
                margin-left: 6px;
            }

            @screen tablet {
                width: auto;
                min-width: 265px;
                font-size: inherit;
                letter-spacing: 1px;

                &:first-child {
                    margin-right: 10px;
                }

                &:last-child {
                    margin-left: 10px;
                }
            }
        }
    }

    &-cms-content {
        margin-top: 64px;

        .cms-2-columns {
            @screen tablet {
                column-count: 2;
                column-gap: 24px;
            }
        }

        a {
            transition: calc(#{$transition-duration} * 2) color $transition-ease;
            @apply underline;

            @media (hover: hover) {
                &:hover {
                    color: var(--color-current-theme);
                }

                &:focus,
                &:active {
                    color: var(--color-current-theme-100);
                }
            }
        }

        ul,
        ol {
            list-style: unset !important;
            @apply ml-4;

            li {
                &,
                + li {
                    @apply m-0 mb-2;

                    &::marker {
                        color: var(--color-current-theme);
                    }
                }
            }
        }
    }
}

.container-previous-load {
    display: flex;
    width: 100%;
    height: fit-content;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
}
