.team-gallery-component {
    .headline {
        height: 100%;
        padding: 23px;

        h2 {
            margin-right: 0;
            margin-left: 0;
        }
    }

    .gallery-img {
        max-width: 100%;
        height: 320px;
        object-fit: cover;
    }
}
