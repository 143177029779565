.e-badge {
    @apply font-openSans font-bold text-white text-xs;
    line-height: 25px;
    width: 60px;
    height: 25px;
    text-align: center;
}

.is-new {
    @apply text-white bg-black;
}
