.custom-scrollbar-horizoltal {
    .ScrollbarsCustom-Wrapper {
        inset: 0 !important;
    }

    .ScrollbarsCustom-Content {
        white-space: nowrap;
    }

    .ScrollbarsCustom-Track {
        &.ScrollbarsCustom-TrackX {
            left: 0 !important;
            display: none !important;
            overflow: inherit !important;
            width: calc(100% - 16px) !important;
            height: 1px !important;
            background: $color-black-70 !important;
        }
    }

    .ScrollbarsCustom-Thumb {
        &.ScrollbarsCustom-ThumbX {
            height: 5px !important;
            margin-top: -2.5px !important;
            background: var(--color-white-100) !important;
        }
    }
}

.dark-mode {
    .custom-scrollbar-horizoltal {
        .ScrollbarsCustom-Track {
            &.ScrollbarsCustom-TrackX {
                display: block !important;
            }
        }
    }
}
