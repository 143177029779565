@import '../variables';
@import '../mixin/common';

/* ==========================================================================
   #PRODUCT REVIEW
========================================================================== */

.review-product {
    // Common Group
    @apply font-OpenSans;

    @screen tablet {
        @apply grid grid-cols-3 gap-5;
    }

    @screen desktopx {
        @apply gap-10;
    }

    span {
        display: inline-block;
        vertical-align: middle;
    }

    &__stars {
        .star {
            line-height: 19px;
            display: inline-block;
            height: 19px;
            margin: 0 2px;
            vertical-align: middle;
        }
    }

    .o-link--underline {
        &:hover {
            color: var(--color-current-theme);

            &::after {
                background: var(--color-current-theme);
            }
        }
    }

    .btn-review {
        font-size: 14px;

        @media (max-width: 767.98px) {
            width: 100%;
            margin-top: 32px;
        }
    }

    // End Common Group


    // Summary Group
    &__summary {

        @screen tablet {
            @apply col-span-1;
        }

        &-wrapper {
            padding: 32px 16px;
            background-color: $color-black-10;

            @screen laptop {
                padding: 16px 20px;
            }

            @screen desktopx {
                padding: 32px 40px;
            }
        }

        &-header {
            @include hr;
            @apply text-center;
            padding-bottom: 32px;

            @screen tablet {
                @apply text-left;
            }

            &__value {
                @apply font-Sport2000 inline-block;
                margin: 0 16px;
            }

            &__value-review {
                @apply font-OpenSans;
            }
        }


        &-detail {
            @apply text-base;
            padding: 32px 0;

            &-row {
                @apply content-center;
                height: 24px;
                margin-bottom: 2px;

                &:last-child {
                    margin-bottom: 0;
                }

                .value-star {
                    display: inline-block;
                    padding-left: 4px;
                }

                .flex-shrink {
                    &:first-child {
                        width: 45px;
                        padding-right: 16px;
                    }

                    &:last-child {
                        width: 35px;
                        padding-left: 16px;
                    }
                }

                progress {
                    margin: 9px 0;
                }
            }
        }

        &-comparison {
            &__title {
                @apply text-sm;
            }

            .comparison-bar__labels {
                @apply text-xs;
            }
        }

        &-note {
            margin-top: 32px;
            @apply text-sm;

            &__title {
                margin-bottom: 8px;
            }

            &__list {
                strong {
                    margin-right: 8px;
                }

                li {
                    margin-bottom: 8px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    // End Summary Group


    // Content Group
    &__content {
        @apply text-sm hidden;

        @screen tablet {
            @apply col-span-2 block;
        }

        &-header {
            @include hr;

            @screen tablet {
                @apply grid grid-cols-10;
                padding-bottom: 32px;

                &__add {
                    @apply col-span-6;
                }

                &__sort-by {
                    @apply col-span-4 text-right;
                }
            }
        }
    }

    // End Content Group


    &__item {
        @apply font-OpenSans;
        padding-top: 20px;
        @include hr;
        padding-bottom: 20px;

        @screen desktop {
            padding-top: 32px;
            padding-bottom: 32px;
        }

        p {
            &:last-child {
                margin-bottom: 0;
            }
        }

        &-header {
            margin-bottom: 20px;
            @apply content-center;
        }

        &-name {
            @apply font-bold ml-3;
        }

        &-date {
            @apply text-sm ml-3;
            color: $color-black-50;
        }

        &-headline {
            @apply text-sm;
            margin: 20px 0 8px 0;

            @screen desktop {
                margin-bottom: 20px;
            }
        }
    }

    &__collapse-items {
        @apply text-center;
        padding-top: 20px;

        @screen desktop {
            padding-top: 32px;
        }
    }
}

// Modal Review Product
.review-product {
    &__modal {
        .modal__body {
            .review-product {
                &__summary-header {
                    @apply text-center;
                    padding: 20px 16px;
                    border-bottom: none;
                    background-color: $color-black-10;
                }
            }
        }

        &__content-wrapper {
            padding-right: 16px;
            padding-left: 16px;

            .review-product {
                &__content-header {
                    &__sort-by {
                        @apply flex content-center;

                        .simple-select-box {
                            @apply inline-block;
                            margin: 0 auto;
                            padding: 7px 0;
                        }

                        select {
                            @apply inline-block text-center;
                        }
                    }
                }

                &__content {
                    @apply block;
                }
            }
        }

        .review-product {
            &__item {
                &-header {
                    @apply relative grid grid-rows-1;
                }

                &-name {
                    @apply order-first ml-0 mb-2 pr-20;
                }

                &-date {
                    @apply absolute right-0 top-0;
                }
            }
        }
    }
}
