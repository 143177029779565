.blog-element {
    margin: 30px auto 0 auto;
    max-width: 1000px;

    @screen laptop {
        margin: 35px auto 0 auto;
    }

    &-tag {
        margin-right: 12px;
        margin-bottom: 15px;

        &-text {
            transition: calc(#{$transition-duration} * 3) all $transition-ease;
            border-radius: 4px;
            background: $color-black-20;
            padding: 3px 12px;
            line-height: 16px;
            font-size: 12px;
            font-weight: 600;
        }

        @screen laptop {
            margin-bottom: 20px;
        }
    }

    &-tags {
        margin-top: 40px;
        padding-bottom: 20px;

        .blog-element-tag {
            display: flex;
            flex-wrap: wrap;

            &-text {
                margin-right: 12px;
                margin-bottom: 12px;
            }
        }

        @screen laptop {
            padding-bottom: 35px;
        }
    }

    &-created {
        display: flex;
        align-items: center;
        margin-bottom: 32px;
        line-height: 24px;
        color: $color-black-60;
        font-size: 14px;

        @screen laptop {
            margin-bottom: 40px;
        }

        &-author {
            margin-right: 20px;
            width: 40px;
            height: 40px;

            img {
                border-radius: 50%;
            }

            &-name {
                margin-right: 10px;

                &:hover,
                &:active {
                    color: $color-red-60;
                }
            }
        }

        &-middle {
            margin-right: 10px;
        }
    }

    &-content {
        border-bottom: 1px solid $color-gray;
        padding-bottom: 40px;

        &-preview-image {
            margin-bottom: 32px;
        }

        &-description {
            pre {
                background: $color-black-20;
                padding: 10px;
            }
        }
    }

    ul {
        list-style: unset !important;

        li {
            &,
            + li {
                @apply m-0 mb-2 pl-4;

                &::marker {
                    color: var(--color-current-theme);
                }
            }
        }
    }

    ol {
        list-style: auto !important;

        li {
            &,
            + li {
                @apply m-0 mb-2 pl-4;

                &::marker {
                    color: $color-black-100;
                }
            }
        }
    }
}
