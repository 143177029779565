/* ==========================================================================
   #PAGE-FOOT
========================================================================== */

.c-page-foot {
    padding: 0 0 65px;

    @screen laptop {
        padding-bottom: 0;
    }

    &.has--filter {
        @media (max-width: $screen-under-tablet) {
            .s-node--category &, .s-node--landingpage & {
                padding-bottom: 113px;
            }
        }

    }
    
    .theme--dark & {
        border-top: 1px solid $color-black-80;
    }

    &.o-wrapper {
        z-index: 10;
        overflow: hidden;
        width: 100%;
        margin: 0 auto;

        &,
        .o-wrapper,
        .o-grid {
            max-width: unset;
        }
    }
}

.page-foot {
    font-size: 14px;
    min-height: 350px;
    margin: 0 auto;
    color: $color-white-100;
    background-color: $color-black-100;

    @screen laptop {
        padding: 0 16px;
    }

    @screen desktop {
        padding: 0 40px;
    }

    a {
        transition: calc(#{$transition-duration} * 3) all $transition-ease;
    }

    .group {
        &:first-of-type {
            .title {
                padding-bottom: 16px;
            }
        }

        @media (max-width: 1023px) {
            position: relative;
            border-bottom: 1px solid $color-black-80;

            @screen laptop {
                padding-right: 10px;
            }

            @screen desktop {
                padding-right: 20px;
            }

            &:last-child {
                border-bottom: 0;
            }

            .content {
                max-height: 0;
                padding-right: 16px;
                padding-left: 16px;
                transition: calc(#{$transition-duration} * 2.5) opacity $transition-ease, calc(#{$transition-duration} * 2.5) transform $transition-ease, calc(#{$transition-duration} * 2.5) max-height $transition-ease;
                transform: scale3d(1, 0, 1);
                transform-origin: 50% 0;
                pointer-events: none;
                opacity: 0;

                li {
                    &:last-child {
                        padding-bottom: 20px;
                    }
                }
            }

            &.is--open {
                .content {
                    max-height: 1000px;
                    transform: scale3d(1, 1, 1);
                    pointer-events: auto;
                    opacity: 1;
                }

                .title {
                    svg {
                        transform: rotate(-90deg);
                    }
                }
            }
        }

        .content {
            @apply font-openSans;
            line-height: 2;

            a {
                @apply text-sm mb-1;
                @include link-hover--underline;
            }
        }

        .title {
            font-weight: 700;
            line-height: 1;
            text-transform: uppercase;

            @media (max-width: 1023px) {
                padding: 19px 16px;

                svg {
                    position: absolute;
                    top: 17px;
                    right: 16px;
                    transition: calc(#{$transition-duration} * 2.5) transform $transition-ease;

                    &,
                    * {
                        fill: $color-white-100;
                    }
                }
            }

            @screen laptop {
                padding-bottom: 12px;
            }

            @media (hover: hover) {
                &:hover {
                    svg {
                        &,
                        * {
                            fill: var(--color-current-theme);
                        }
                    }
                }
            }
        }
    }

    .contact-info {
        @media (max-width: 1023px) {
            padding: 0 16px 20px;
        }
    }

    .social-group {
        @media (max-width: 1023px) {
            padding: 20px 16px;
        }

        @screen laptop {
            padding-right: 0;
        }
    }
}

.main-foot {
    max-width: 2560px;
    margin: 0 auto;
    padding-top: 2px;

    @screen laptop {
        padding: 48px 0;
    }
}

.bottom-foot {
    position: relative;
    @apply font-openSans;
    max-width: 2560px;
    margin: 0 auto;
    padding: 20px 0;
    text-align: center;

    @screen tablet {
        padding: 30px 16px;
    }

    @screen laptop {
        padding: 25px 0;
    }

    @screen desktop {
        height: 100px;
        padding: 38px 0;
    }

    &::before {
        position: absolute;
        top: 0;
        right: -40px;
        left: -40px;
        height: 1px;
        content: '';
        background: $color-black-80;
    }
}

.copyright {
    font-size: 12px;
    margin-top: 10px;

    @screen tablet {
        margin-top: 0;
    }
}

.country-modal {
    font-weight: 700;
    text-transform: uppercase;

    span {
        display: inline-block;
        padding-right: 8px;
        vertical-align: middle;
    }

    @screen laptop {
        text-align: right;
    }
}

.icon-link-list {
    line-height: 1;
    display: block;
    margin: 0 -8px;
    text-align: center;

    @screen laptop {
        text-align: left;
    }

    &.payment-list {
        margin-top: -4px;
        margin-bottom: -4px;
    }

    .social-group & {
        margin: 0 -5px;
    }

    img,
    svg {
        width: auto;
        height: 20px;
        margin: 0;

        .social-group & {
            z-index: 1;
            width: 20px;
        }
    }

    svg {
        &,
        * {
            fill: $color-white-100;
        }
    }

    a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: auto;
        margin: 4px 8px;
        text-align: center;

        .bottom-foot & {
            img,
            svg {
                transition: calc(#{$transition-duration} * 2.5) opacity $transition-ease;
            }

            @media (hover: hover) {
                &:hover {
                    img,
                    svg {
                        opacity: .75;
                    }
                }
            }
        }

        .social-group & {
            width: 48px;
            height: 32px;
            margin: 0 5px;
            transition: calc(#{$transition-duration} * 2.5) background $transition-ease;
            border-radius: 2px;
            background: $color-black-80;

            &[href*='instagram.com'] {
                position: relative;

                &::before {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    content: '';
                    transition: calc(#{$transition-duration} * 2.5) opacity $transition-ease;
                    opacity: 0;
                    border-radius: 2px;
                    background: $color-brand-instagram;
                }
            }

            @media (hover: hover) {
                &:hover {
                    &[href*='facebook.com'] {
                        background: $color-brand-facebook;
                    }

                    &[href*='instagram.com'] {
                        &::before {
                            opacity: 1;
                        }
                    }

                    &[href*='youtube.com'] {
                        background: $color-brand-youtube;
                    }

                    &[href*='pinterest.com'] {
                        background: $color-brand-pinterest;
                    }

                    &[href*='tiktok.com'] {
                        background: $color-brand-tiktok;
                    }
                }
            }
        }
    }

    span {
        display: block;
    }
}
