img {
    @apply border-0 object-contain select-none text-xs text-blacksport-50 font-normal;
    -webkit-user-drag: none;

    &[src$='.gif'] {
        image-rendering: crisp-edges;
    }
}

svg {
    &:not(:root) {
        @apply overflow-hidden;
    }
}