//General Spacing for all Components
.py-comp {
    padding-top: 20px;
    padding-bottom: 20px;

    @screen tablet {
        padding-top: 30px;
        padding-bottom: 30px;
    }
    @screen desktop {
        padding-top: 40px;
        padding-bottom: 40px;
    }
    @screen desktopx {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    @screen desktopxx {
        padding-top: 60px;
        padding-bottom: 60px;
    }
}

.my-comp {
    margin-top: 20px;
    margin-bottom: 20px;

    @screen tablet {
        margin-top: 30px;
        margin-bottom: 30px;
    }
    @screen desktop {
        margin-top: 40px;
        margin-bottom: 40px;
    }
    @screen desktopx {
        margin-top: 50px;
        margin-bottom: 50px;
    }
    @screen desktopxx {
        margin-top: 60px;
        margin-bottom: 60px;
    }
}

.width--fullsize {
    @include full-width-wrapper();
}

.Toastify {
    p {
        margin-bottom: 0;
    }
}

// Fade Animation for CSSTransition
.fade {
    &-enter {
        opacity: 0;
    }

    &-exit {
        opacity: 1;
    }

    &-enter-active {
        opacity: 1;
    }

    &-exit-active {
        opacity: 0;
    }

    &-enter-active,
    &-exit-active {
        @include transitionPrefixMultiple(calc(#{$transition-duration} * 5), opacity);
    }
}

/*
    Style For trustbadge icon
 */
[id*='trustbadge-container-'] {
    z-index: 17 !important;
}

/*
    Style For ConsentManager Preview
 */

.custom-cmp {
    position: relative;
}

.cmplazypreviewiframe {
    position: fixed !important;
    z-index: 99999;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    background-color: rgba(0, 0, 0, .7) !important;

    .custom-cmp & {
        position: absolute !important;
    }

    .cmplazypreviewmsg {
        padding: 40px 0 !important;

        @screen laptop {
            width: 640px !important;
            left: calc(50% - 320px) !important;

            .custom-cmp & {
                width: 100% !important;
                left: inherit !important;
            }
        }
    }
}

img[src*='media.graphassets.com'] {
    width: auto;
    margin: auto;
}
