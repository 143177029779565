.mailjet-newsletter-wrapper {
    min-height: 120px;
    padding: 32px 0;

    @screen laptop {
        display: flex;
    }

    &-content {
        &-headline {
            margin-bottom: 8px;
        }

        &-description {
            font-family: $ff-sport2000;
            line-height: 24px;

            @screen laptop {
                margin-bottom: 0;
            }
        }

        @screen laptop {
            flex: 1;
            margin-right: 15px;
        }
    }

    &-form {
        .mailjet-newsletter--form {
            input[type='email'] {
                &:focus {
                    ~ label {
                        transform: translate(0, 0);
                        opacity: 0;
                    }
                }

                &:not(:placeholder-shown) {
                    ~ label {
                        transform: translate(0, 0);
                        opacity: 0;
                    }
                }
            }

            &-input {
                @screen laptop {
                    flex: 1;
                }
            }

            &-button {
                @screen laptop {
                    margin-left: 20px;
                }
            }

            @screen laptop {
                display: flex;
                height: 70px;
            }
        }

        @screen laptop {
            flex: 1;
            margin: auto;
        }
    }
}

.mailjet-popup-mail {
    .ReactModal__Content {
        height: fit-content;
        margin: auto 10px auto 10px;
        padding: 20px 10px !important;
        background-color: $color-white-100;
        box-shadow: 0 1px 18px rgba(0, 0, 0, 0.12);
        inset: inherit !important;

        @screen tablet {
            width: 700px;
            margin: auto;
            padding: 0 !important;
        }
    }
}

.mailjet-popup-wrapper {
    color: $color-black-100;

    .mailjet-popup {
        position: relative;

        .loading-full-screen {
            margin: auto;
        }

        &-headline {
            margin-top: 10px;

            @screen tablet {
                margin: 0 0 25px 0;
            }
        }

        &-close {
            position: absolute;
            top: 0;
            right: 0;
        }

        &-topics {
            .newsletter-contact {
                justify-content: end !important;

                &-headline {
                    display: none;
                }
            }
           
            &-content {
                margin-bottom: 20px;

                &-header {
                    display: flex;
                    justify-content: space-between;

                    .mailjet-popup-topics-content-alert {
                        font-size: 14px;
                        color: $color-red-60;
                    }
                }

                &-headline {
                    font-weight: 700;
                    width: 100%;
                }

                &-input {
                    display: grid;
                    margin-top: 15px;
                    grid-template-columns: repeat(2, 1fr);
                    grid-gap: 20px;

                    .mailjet-popup--input {
                        margin: 0 10px 10px 0;

                        input {
                            ~ label {
                                font-size: 14px;
                                line-height: 20px;
                            }

                            &.checkbox-type {
                                border-radius: 0;

                                &:checked {
                                    &:not(:disabled) {
                                        &::before,
                                        &::after {
                                            transition: $transition-duration transform $transition-ease-in-out, $transition-duration opacity $transition-ease;
                                            background: $color-black-100;
                                        }
                                    }

                                    &:disabled {
                                        &::before,
                                        &::after {
                                            background: $color-black-40;
                                        }
                                    }

                                    &::before,
                                    &::after {
                                        opacity: 1;
                                    }

                                    &::before {
                                        transform: scale3d(1, 1, 1) rotate(-60deg);
                                    }

                                    &::after {
                                        transform: scale3d(1, 1, 1) rotate(35deg);
                                    }
                                }

                                &:not(:checked) {
                                    &:not(:disabled) {
                                        &::before,
                                        &::after {
                                            background: $color-black-60;
                                        }
                                    }
                                }

                                &::before,
                                &::after {
                                    position: absolute;
                                    content: '';
                                    transition: $transition-duration transform $transition-ease, $transition-duration opacity $transition-ease;
                                    transform-origin: 50% 50%;
                                    pointer-events: none;
                                    opacity: 0;
                                    border-radius: 1px;
                                }

                                &::before {
                                    top: 9px;
                                    left: 6px;
                                    width: 16px;
                                    height: .09rem;
                                    transform: scale3d(0, 0, 0) rotate(-60deg);
                                }

                                &::after {
                                    top: 13px;
                                    left: 2px;
                                    width: 9px;
                                    height: .09rem;
                                    transform: scale3d(0, 0, 0) rotate(35deg);
                                }
                            }
                        }
                    }

                    @screen tablet {
                        grid-template-columns: repeat(3, 1fr);
                        grid-gap: 25px;
                    }
                }
            }

            &-check-all {
                input {
                    ~ label {
                        font-size: 14px;
                        line-height: 20px;
                    }
                }
            }

            &-privacy {
                border-top: 1px solid $color-black-60;

                input {
                    ~ label {
                        font-size: 14px;
                        line-height: 20px;
                    }
                }
            }
        }

        &-action {
            display: flex;
            flex-wrap: wrap;
            margin: 25px 0 20px 0;

            &-cancel {
                flex: 1;
                margin-right: 10px;
            }

            &-submit {
                flex: 1;
            }

            @screen tablet {
                margin: 17px 0 0;
            }
        }

        .mailjet--loading {
            min-height: 300px;

            .default--loader {
                background: $color-white-100 !important;
            }
        }
    }

    @screen tablet {
        z-index: 2;
        padding: 20px;
    }
}

.popup-mailjet-confirm {
    padding: 32px;

    &-icon {
        margin: 0 0 20px 0;

        svg {
            &,
            * {
                margin: auto;
                fill: $color-success;
            }
        }

        @screen tablet {
            padding-top: 40px;
        }
    }

    &-content {
        text-align: center;

        &-headline {
            margin-bottom: 20px;
        }

        &-description {
            margin-bottom: 24px;
        }
    }
}
