.menu-algolia {
    &--dropdown-title {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 64px;

        @screen laptop {
            display: none;
        }

        button {
            position: absolute;
            z-index: 1;
            top: 0;
            right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 64px;
            height: 64px;
        }
    }

    &--dropdown {
        display: block;
        margin-bottom: 16px;

        @screen laptop {
            display: none;
        }

        button {
            font-size: 16px;
            display: flex;
            align-items: center;
            width: 100%;
            height: 48px;
            padding: 0 16px;
            background-color: $color-black-10;

            span {
                flex-grow: 1;
                text-align: left;
            }
        }
    }

    &--item-wrapper {
        .menu-algolia--item {
            display: flex;
            align-items: center;

            @media (max-width: $screen-under-tablet) {
                min-height: 56px;
                padding-left: 16px;
                border-bottom: 1px solid $color-black-30;
            }

            &.active {
                border-bottom: none;
            }
        }
    }

    &--container {
        @media (max-width: $screen-under-tablet) {
            position: fixed;
            z-index: 1001;
            top: 0;
            left: 0;
            display: flex;
            flex-direction: column;
            width: 100%;
            @include translate(0, -130%);
            height: 100%;
            padding-top: 64px;
            opacity: 0;
            @include transitionPrefixMultiple(calc(#{$transition-duration} * 6), transform, opacity);
            background-color: $color-white-100;

            &.active {
                opacity: 1;
                @include translate(0, 0);
            }
        }
    }

    &--wrapper {
        overflow-y: scroll;

        @screen laptop {
            width: 210px;
            margin-right: 32px;
            overflow-y: inherit;
        }

        @screen desktop {
            width: 260px;
        }

        > li .menu-algolia--item-children {
            .menu-algolia--item-wrapper {
                a {
                    padding: 0;

                    @screen laptop {
                        padding: 0 16px;
                    }
                }

                .menu-algolia--item-wrapper {
                    a {
                        padding-left: 16px;

                        @screen laptop {
                            padding: 0 32px;
                        }
                    }

                    .menu-algolia--item-wrapper {
                        a {
                            padding-left: 32px;

                            @screen laptop {
                                padding: 0 48px;
                            }
                        }

                        .menu-algolia--item-wrapper {
                            a {
                                padding: 0 48px;

                                @screen laptop {
                                    padding-left: 64px;
                                }
                            }

                            .menu-algolia--item-wrapper {
                                a {
                                    padding-left: 64px;

                                    @screen laptop {
                                        padding-left: 80px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }


        li {
            .menu-algolia--item-wrapper {
                > .menu-algolia--item {
                    &.active {
                        background-color: $color-black-10;

                        a {
                            label {
                                &:before {
                                    opacity: 1;
                                }
                            }
                        }

                        .is--arrow {
                            svg {
                                path {
                                    fill: $color-red-60;
                                }
                            }
                        }

                        @media (max-width: $screen-under-tablet) {
                            a.active {
                                label:before {
                                    opacity: 0;
                                }
                            }
                        }
                    }
                }
            }

            .menu-algolia--item-children {
                .menu-algolia--item-wrapper {
                    > .menu-algolia--item {
                        min-height: 44px;
                        border-bottom: none;

                        &.active {
                            background-color: $color-black-10;

                            + .menu-algolia--item-children {
                                background-color: $color-black-10;

                                @screen laptop {
                                    background-color: transparent;
                                }
                            }

                            @media (max-width: $screen-under-tablet) {
                                a.active {
                                    label:before {
                                        opacity: 1;
                                    }
                                }
                            }
                        }
                    }
                }

                a {
                    font-weight: normal;

                    span {
                        display: flex;
                        cursor: pointer;
                    }

                    &.active {
                        .is--arrow {
                            padding-right: 0;
                        }

                        .theme--dark & {
                            color: $color-black-100;

                            span {
                                color: $color-black-100;
                            }
                        }
                    }
                }
            }

            .is--arrow {
                display: flex;
                flex-grow: 1;
                justify-content: flex-end;
                padding-right: 16px;

                svg {
                    cursor: pointer;
                }
            }

            a {
                font-size: 16px;
                font-weight: bold;
                position: relative;
                display: flex;
                align-items: center;
                min-height: 44px;
                cursor: pointer;

                .theme--dark & {
                    color: $color-white-100;
                }

                label {
                    position: relative;
                    cursor: pointer;
                    @include transitionPrefixMultiple(calc(#{$transition-duration} * 2), color);

                    &:before {
                        position: absolute;
                        bottom: 0;
                        width: 100%;
                        height: 1px;
                        content: '';
                        opacity: 0;
                        background-color: $color-black-100;
                        @include transitionPrefixMultiple(calc(#{$transition-duration} * 2), opacity);
                    }
                }

                &.active,
                &:hover {
                    label {
                        &:before {
                            opacity: 1;
                        }
                    }
                }

                &:hover {
                    label {
                        color: $color-red-60;

                        &:before {
                            background-color: $color-red-60;
                        }
                    }
                }
            }

            span {
                font-weight: normal;
                flex-grow: 1;
                padding-right: 16px;
                text-align: right;
                color: $color-black-40;

                .theme--dark & {
                    color: $color-white-100;
                }
            }
        }
    }
}


.slider-wrapper {
    @apply flex overflow-x-auto gap-1 mb-4;

    &::-webkit-scrollbar {
      height: 2px;
      background-color: #EAEAEB;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #000000;
      border-radius: 10px;
    }
  }