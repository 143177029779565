.banner-text-element {
    min-height: 200px;

    @screen desktop {
        min-height: 300px;
    }

    .slick-slider {
        margin-right: -12px;
        margin-left: -12px;
    }

    .unactive-slide {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-row-gap: 24px;
        grid-column-gap: 24px;

        .banner-content {
            margin-right: 0;
            margin-left: 0;
        }
    }

    .regular.slider,
    .unactive-slide {
        margin: 0 -16px;

        @screen tablet {
            margin: 0;
        }

        @media (max-width: $screen-under-tablet) {
            display: flex;
            overflow-x: auto;
            align-items: center;
            flex-wrap: nowrap;
            justify-content: left;
            gap: 12px;

            .banner-content {
                flex: 0 0 80%;
                max-width: 80%;
                margin: 0;
                cursor: pointer;

                &:before {
                    opacity: 1;
                }

                &:first-child {
                    margin-left: 16px;
                }

                &:last-child {
                    margin-right: 16px;

                    @screen tablet {
                        margin-right: 0;
                    }
                }

                h3 {
                    margin-right: 0;
                    margin-left: 0;
                }

                @screen tablet {
                    flex: 0 0 45%;
                    margin-right: 0;
                    margin-left: 0 !important;
                    max-width: 45%;

                    h3 {
                        font-size: 28px;
                    }
                }
            }
        }
    }

    .banner-content {
        @apply mx-3 h-full;
        position: relative;
        display: block;
        overflow: hidden;
        aspect-ratio: 3/2;

        @screen laptop {
            margin: 0;
        }

        @screen desktop {
            &:hover {
                cursor: pointer;

                .banner-text {
                    transition: 0.3s ease-in;
                    max-height: 250px;

                    .c-markdown {
                        max-height: 100px;
                        transition: max-height 0.3s ease-in;
                    }
                }

                &:before {
                    opacity: 1;
                }
            }
        }

        .banner-text {
            @apply text-white;
            position: absolute;
            z-index: 2;
            bottom: 0;
            left: 0;
            transition: 0.3s ease-out;
            width: 100%;
            max-width: 272px;
            padding: 0 24px;
            transform: translateY(0px);

            @screen desktop {
                max-height: 80px;
            }

            @screen tablet {
                max-width: 440px;
            }

            .c-markdown {
                max-height: 0;
                overflow: hidden;
                transition: max-height 0.3s ease-out;
            }

            p {
                font-size: 16px;
                line-height: 24px;
                margin-bottom: 16px;
                @include line-clamp(3);
            }

            h3 {
                @include line-clamp(2);
                margin-bottom: 10px;
            }
        }

        &::before {
            @apply bg-gradient-to-t bg-no-repeat from-blacksport-90 to-transparent absolute w-full left-0 bottom-0 pointer-events-none;
            z-index: 1;
            height: 80%;
            content: '';
            opacity: 1;

            @screen desktop {
                opacity: .7;
            }
        }
    }

    // Dotted bar
    &--dotted-bar {
        .dotted-bar {
            &.dotted-bar__top {
                &.dotted-bar__left {
                    display: none;
                    @screen laptop {
                        display: block;
                        svg {
                            left: -25%;
                        }
                    }
                    @screen desktop {
                        svg {
                            left: -5px;
                        }
                    }
                }

                &.dotted-bar__right {
                    @screen laptop {
                        svg {
                            right: -15%;
                        }
                    }
                    @screen desktop {
                        svg {
                            right: 0;
                        }
                    }
                }
            }

            &.dotted-bar__bottom {
                &.dotted-bar__right {
                    display: none;
                    @screen laptop {
                        display: block;
                        svg {
                            right: -25%;
                        }
                    }
                    @screen desktop {
                        svg {
                            right: -5px;
                        }
                    }
                }

                &.dotted-bar__left {
                    @screen laptop {
                        svg {
                            left: -15%;
                        }
                    }
                    @screen desktop {
                        svg {
                            left: 0;
                        }
                    }
                }
            }
        }
    }
}
