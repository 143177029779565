.filter-panel-select {
    width: 100%;

    .filter-buttons-toggle {
        font-weight: bold;
        display: flex;
        align-items: center;
        flex-direction: row;
        width: 100%;
        height: 48px;
        padding: 0 16px;
        background-color: $color-white-100;

        @screen laptop {
            font-weight: normal;
        }

        .filter-buttons-toggle-arrow {
            display: flex;
            flex-grow: 1;
            justify-content: flex-end;
        }
    }
}

.filter-algolia {
    @include full-width-wrapper;

    .s-node--category,
    .s-node--search,
    .s-node--sale-category & {
        margin-top: -55px;
        padding-top: 60px;
    }

    @screen laptop {
        .s-node--search & {
            margin-top: -20px;
            padding-top: 30px;
        }

        .s-node--category & {
            margin-top: -20px;
            padding-top: 30px;
        }

        .s-node--sale-category & {
            margin-top: -20px;
            padding-top: 30px;
        }

        .fixed .s-node--category & {
            right: 0;
            margin-right: 0;
            margin-left: 0;
            margin-top: 0;
            padding-top: 0;
            left: 0;
            position: fixed;
            top: 70px;
            z-index: 13;
        }

        .fixed .s-node--sale-category & {
            right: 0;
            margin-right: 0;
            margin-left: 0;
            margin-top: 0;
            padding-top: 0;
            left: 0;
            position: fixed;
            top: 70px;
            z-index: 13;
        }

        .fixed .s-node--search & {
            right: 0;
            margin-right: 0;
            margin-left: 0;
            margin-top: 0;
            padding-top: 0;
            left: 0;
            position: fixed;
            top: 70px;
            z-index: 13;
        }
    }

    &--container-all-panels {
        @include full-width-wrapper;

        @screen laptop {
            &.is--fixed {
                position: fixed;
                width: 100%;
                top: 120px;
                left: 0;
                z-index: 10;
                right: 0;
                margin-left: 0;
                margin-right: 0;
                margin-bottom: 0;
            }
        }

        .filter-algolia--wrapper-all-panels {
            width: 100%;
            max-width: 1624px;
            height: 100%;
            margin: 0 auto;
            padding: 0 0 16px;
            position: fixed;
            z-index: 9999;
            top: 0;
            right: 0;
            display: flex;
            flex-direction: column;
            @include transitionPrefixMultiple(calc(#{$transition-duration} * 5), all);
            transform: translate(100%, 0);

            &.is--open {
                transform: translate(0, 0);
                background-color: $color-white-100 !important;
            }

            @screen laptop {
                position: relative;
                z-index: 10;
                transform: translate(0, 0);
                padding: 10px 16px;
            }

            @screen desktop {
                padding: 20px 56px 10px;
            }
        }
    }

    &--header-mobile {
        position: absolute;
        z-index: 10;
        top: 0;
        left: 0;
        width: 100%;
        height: 76px;
        padding: 28px 16px 12px;
        text-align: center;
        background-color: $color-white-100;

        h6 {
            font-size: 24px;
            font-weight: 700;
        }

        svg {
            position: absolute;
            top: 32px;
            right: 20px;
            width: 20px;
            height: 20px;
            cursor: pointer;
        }
    }

    &--wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 1624px;
        height: 100%;
        margin: 0 auto;
        padding: 0 0 16px;

        &.is-open {
            transform: translate(0, 0);
            background-color: $color-white-100 !important;
        }

        &.is--title-mobile {
            position: static;
            margin: 0 auto 25px;
            padding: 0;
            transform: translate(0, 0);
        }

        @screen laptop {
            padding: 30px 16px 0;
        }

        @screen desktop {
            padding: 40px 56px 10px;

            .s-node--landingpage & {
                padding-top: 70px;
            }
        }

        .fixed .s-node--category & {
            margin-bottom: 0;
        }

        .fixed .s-node--search & {
            margin-bottom: 0;
        }
    }

    .filter-category-headline {
        display: none;

        @screen laptop {
            display: block;
        }
    }

    h1 {
        margin-bottom: 0;
    }

    &--footer-mobile {
        position: absolute;
        z-index: 2;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 25px 14px;
        border-top: 1px solid $color-black-40;
        background-color: $color-white-100;

        button.btn {
            font-size: 13px;
            width: calc(100% - 16px);
            margin: 0 10px;
            @apply font-OpenSans;
        }
    }

    &--content-mobile {
        position: absolute;
        @include hide-scrollbars();
        overflow-y: auto;
        width: 100%;
        height: 100%;
    }

    &--panel-wrapper {
        position: relative;
        @apply font-openSans;
        border-bottom: 1px solid $color-black-40;
        background-color: $color-white-100;

        &:first-child {
            border-top: 1px solid $color-black-40;
        }

        @screen laptop {
            display: flex;
            flex-grow: 1;
            border-bottom: none;

            &:first-child {
                border-top: none;

                .filter-panel-select {
                    .filter-buttons-toggle {
                        font-weight: bold;
                    }
                }
            }
        }
    }

    &--panel {
        display: flex;
        overflow-y: scroll;
        flex-direction: column;
        flex-grow: 1;
        height: 100vh;
        margin-top: 76px;
        margin-bottom: 120px;
        background-color: $color-white-100;

        @screen laptop {
            overflow: inherit;
            height: auto;
            margin: 0;
            position: static;
            flex-direction: row;
            display: grid;
            @apply grid-cols-6;
            gap: 24px;
            background-color: transparent;
        }
    }

    &--btn-more-panel {
        width: 100%;

        &.btn {
            padding-right: 1rem;
            padding-left: 1rem;
            letter-spacing: 0;
        }
    }

    button {
        &.filter-algolia--btn-open-filter {
            font-size: 14px;
            line-height: 18px;
            position: fixed;
            z-index: 11;
            bottom: 64px;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            @apply font-OpenSans font-bold;
            width: 100%;
            height: 48px;
            text-transform: uppercase;
            border-top: 1px solid $color-black-20;
            background-color: $color-white-100;

            span {
                &:last-child {
                    margin-left: 10px;
                }
            }
        }
    }

    &--panel-values {
        width: 100%;
        background-color: $color-white-100;

        @screen laptop {
            position: absolute;
            z-index: 1;
            top: calc(100% + 1px);
            width: 320px;
        }
    }

    &--panel-select-list {
        position: static;
        overflow-y: auto;
        width: 100%;
        height: 300px;
        padding: 5px 0;
        background-color: $color-white-100;

        @screen laptop {
            position: absolute;
            z-index: 1;
            top: calc(100% + 1px);
            left: 0;
            height: calc(100vh - 550px);
            max-height: 300px;
        }

        .form-check {
            display: flex;
            align-items: center;
            height: 48px;
            padding: 0 16px;

            label {
                span {
                    font-size: 13px;
                    position: relative;
                    top: 2px;
                    display: inline-block;
                    float: right;

                    @screen laptop {
                        display: none;
                    }
                }
            }
        }

        .filter-search-button {
            position: absolute;
            top: 50%;
            right: 16px;
            cursor: pointer;
            transform: translateY(-50%);
        }

        .filter-close-button {
            position: absolute;
            top: 50%;
            right: 32px;
            margin-right: 15px;
            cursor: pointer;
            transform: translateY(-50%);
        }
    }

    &--refinemets {
        display: flex;
        flex-wrap: wrap;
        padding: 0 16px;

        @screen laptop {
            margin-top: 12px;
            padding: 0;
        }

        .btn-clear-refinements {
            font-size: 12px;
            display: flex;
            align-items: center;
            flex-direction: row;
            height: 28px;
            margin-right: 12px;
            @apply font-openSans;
            margin-bottom: 12px;
            padding: 0 8px;
            border-radius: 6px;
            background-color: $color-black-20;

            svg {
                margin-left: 12px;
            }

            &.is--all {
                border: 1px solid $color-black-70;
                background-color: transparent;
            }
        }
    }

    &--price {
        padding: 16px 20px 20px;

        .product-filter-price--range {
            padding: 0 14px 10px;

            .rc-slider-handle {
                width: 20px !important;
                height: 20px !important;
                margin-top: -9px !important;
            }

            .rc-slider-rail {
                background-color: $color-black-20;
            }

            .rc-slider-track {
                background-color: $color-black-70;
            }
        }

        .product-filter-price {
            &--input {
                display: flex;
                margin-bottom: 20px;

                input {
                    width: 100%;
                    height: 48px;
                    padding: 16px 40px 16px 16px;
                    outline: none;
                    background-color: $color-black-10;
                }

                > span {
                    position: relative;
                    display: inline-block;
                    width: 48px;
                    height: 48px;

                    &:before {
                        position: absolute;
                        top: calc(50% - 1.5px);
                        left: calc(50% - 8px);
                        width: 16px;
                        height: 2px;
                        content: '';
                        background-color: $color-black-100;
                    }
                }
            }

            &--input-wrapper {
                position: relative;
                width: calc(50% - 24px);

                span {
                    font-size: 16px;
                    line-height: 20px;
                    @apply font-openSans;
                    position: absolute;
                    top: 14px;
                    right: 16px;
                }
            }
        }
    }
}

.theme--dark {
    .filter-algolia {
        button {
            &.filter-algolia--btn-open-filter {
                color: $color-black-100;
                border-color: $color-black-100;

                svg {
                    &,
                    * {
                        color: $color-black-100;
                        fill: $color-black-100;
                    }
                }
            }
        }
    }

    .filter-algolia--footer-mobile {
        &-reset {
            color: $color-black-100 !important;
            border-color: $color-black-100 !important;
        }

        &-button {
            color: $color-white-100 !important;
            background-color: $color-black-100 !important;
        }
    }
}
