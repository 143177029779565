.badge-wrapper {
    @apply flex relative;
    width: max-content;
    min-height: 52px;

    &:hover {
        cursor: pointer;
    }

    .badge-count {
        position: absolute;
        top: 2px;
        right: 4px;
        align-items: center;
        width: 18px;
        height: 18px;
        pointer-events: none;
        background-color: var(--color-current-theme);
    }
}
