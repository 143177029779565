.select-container {
    @apply font-OpenSans;

    .select__control {
        height: 48px;
        cursor: pointer;
        border: 1px solid $color-black-20 !important;
        border-radius: 0 !important;
        background-color: $color-black-10;
        box-shadow: none !important;

        .select__indicator-separator {
            display: none;
        }

        .select__indicators {
            width: 32px;
            height: 48px;

            .select__indicator {
                position: relative;
                width: 100%;
                height: 100%;

                &::before {
                    position: absolute;
                    top: 18px;
                    right: 12px;
                    width: 10px;
                    height: 10px;
                    content: '';
                    background: url('../../icons/sport2000-icons/arrow-down.svg') no-repeat;
                    background-size: cover;
                }

                svg {
                    display: none;
                }
            }
        }

        .select__value-container {
            padding-right: 0;
            padding-left: 12px;
        }
    }

    .select__menu {
        margin: 0;
        padding: 0;
        border-top: 1px solid $color-black-30;
        border-bottom: 1px solid $color-black-100;
        border-radius: 0 !important;
        box-shadow: none !important;

        .select__menu-list {
            max-height: 144px;
            padding: 0;
            background-color: $color-black-10;

            .select__option {
                display: flex;
                align-items: center;
                height: 48px;
                padding-right: 16px;
                padding-left: 16px;
                color: $color-black-100;
                white-space: nowrap;

                &:hover {
                    background-color: $color-black-20;
                }

                &--is-focused {
                    background-color: $color-black-20;
                }

                &--is-selected {
                    background-color: $color-black-20;
                }
            }
        }
    }
}
