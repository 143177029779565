.graphcms-image {
    &.disable-image-cropping {
        img {
            top: 50% !important;
            left: 50% !important;
            transform: translate3d(-50%, -50%, 0) !important;
            object-fit: cover !important;
        }
    }
}

.blur-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    transition: opacity 0.5s ease 0.25s;
    object-fit: cover;
    object-position: center center;
}

.main-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition-timing-function: ease;
    transition-duration: 0.5s;
    transition-property: opacity;
    opacity: 1;
    object-fit: contain;
    object-position: center center;

    &.disable-image-cropping {
        top: 50% !important;
        left: 50% !important;
        transform: translate3d(-50%, -50%, 0) !important;
        -o-object-fit: cover !important;
        object-fit: cover !important;
    }
}

/*
  Style for zooming in while hovering and object-fit is cover
*/
.hover-zoomin-image {
    &:hover .main-image.disable-image-cropping {
        transform: translate3d(-50%, -50%, 0) scale(1.1) !important;
    }
}
