.store-detail-header-component {
    .text-subh2 {
        margin-bottom: 10px;
    }

    .headline {
        @apply ml-0 mr-0;
        margin-bottom: 20px;

        @screen tablet {
            margin-bottom: 40px;
        }

        @screen desktop {
            margin-bottom: 60px;
        }
    }

    .store-detail-header {
        @apply grid col-end-2;
        grid-gap: 40px;
        gap: 40px;

        @screen desktop {
            grid-gap: 145px;
            gap: 145px;

            .img-map {
                height: 440px;
            }
        }
    }

    strong {
        display: block;
    }


    .store-contact-info {
        padding-left: 56px;

        .btn {
            margin-top: 24px;
        }


        @screen desktop {
            padding-left: 0;
            .btn {
                margin-top: 48px;
            }
        }
    }


    .store-opening-info {
        padding-left: 56px;

        .btn {
            margin-top: 24px;
        }

        &-list {
            .c-markdown {
                max-width: 300px;
            }

            li {
                @apply clear-right;
            }

            em {
                font-style: normal;
            }

            strong {
                display: inline-block;
                float: right;
            }
        }
    }

    .title-elm {
        @apply relative;

        h4 {
            margin: 0 0 20px;
        }

        .icon {
            @apply absolute;
            top: -7px;
            left: -56px;
        }
    }

    .store-contact-links {
        div {
            margin-bottom: 2px;
        }

        a {
            @apply no-underline;
        }
    }
}
