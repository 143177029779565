div[class*='e-tastic__sm-general/hero-banner'] {
    .o-wrapper {
        max-width: unset;
    }
}

.hero-banner {
    position: relative;
    margin-right: -16px;
    margin-left: -16px;
    padding-right: 16px;
    padding-left: 16px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    .headline {
        margin-bottom: 8px;
    }

    @screen desktop {
        margin-left: -56px;
        margin-right: -56px;
        padding-left: 56px;
        padding-right: 56px;
    }

    &--video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &::before {
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 67.77%;
        content: '';
        @apply bg-gradient-to-t from-blacksport-100 to-transparent;
    }

    &--container {
        display: flex;
        align-items: flex-end;
        height: 664px;

        @screen laptop {
            margin-left: 0;
            margin-right: 0;
        }
    }

    &--main {
        position: relative;
        z-index: 2;
        width: 100%;
        max-width: 1512px;
        margin: 0 auto;
        padding-bottom: 56px;
        color: $color-white-100;

        @screen laptop {
            display: flex;
            width: 100%;
            justify-content: space-between;
        }
    }

    &--content {
        max-width: 600px;
        margin-bottom: 10px;
        padding-right: 20px;

        .sub-h1 {
            margin-bottom: 10px;

            @screen laptop {
                margin-bottom: -10px;
            }
        }
    }

    &--category-list {
        display: flex;
        flex-wrap: wrap;
        max-width: 950px;

        margin: 0 -8px;

        a {
            flex: 1 1 0;
            margin: 8px;

            button.btn {
                min-width: 100%;

            }
        }

        button.btn:empty {
            display: none;
        }

        @screen laptop {
            align-items: flex-end;
            min-width: 104px;
            width: auto;
            margin: 0 -10px;

            a {
                margin: 10px;
            }
        }

        @screen desktop {
            padding-top: 20px;
        }
    }

    // dotted bar
    &--dotted-bar {
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
        width: 100%;
        height: 100%;

        .dotted-bar {
            &.dotted-bar__top {
                &.dotted-bar__left,
                &.dotted-bar__right {
                    display: none;

                    @screen laptop {
                        display: block;
                    }
                }

                &.dotted-bar__left {
                    svg {
                        left: -30%;

                        @screen desktop {
                            left: 0;
                        }
                    }
                }

                &.dotted-bar__right {
                    right: 0;

                    svg {
                        right: -10%;

                        @screen desktop {
                            right: 0;
                        }
                    }
                }
            }
        }
    }
}
