.breadcrumb-wrapper {
    z-index: 10;
    padding-top: 28px;

    @screen laptop {
        padding-top: 6px;

        .s-node--category & {
            top: 15px;
            position: relative;
        }

        .s-node--sale-category & {
            top: 15px;
            position: relative;
        }

        .product-list--wrapper & {
            top: 30px;
            position: relative;
        }
    }
}

.breadcrumb {
    @apply flex flex-wrap list-none relative;
    z-index: 9;

    li {
        @apply font-openSans text-sm;

        //&:first-of-type {
        //    margin-right: 27px;
        //}

        a {
            span {
                color: $color-black-100;
            }
        }

        &.btn-back {
            margin-right: 27px;

            .icon-arrow-l {
                top: 4px;

                @screen tablet {
                    top: 0;
                }
            }
        }
    }

    .o-link-hover--underline {
        &::after {
            background: $color-black-100;
        }
    }

    .breadcrumb-item {
        + .breadcrumb-item::before {
            display: inline-block;
            padding-right: 10px;
            padding-left: 10px;
            content: '|';
            color: $color-black-100;
        }

        &.active {
            @apply font-bold;
        }
    }

    &.is--darkmode {
        .breadcrumb-item {
            color: $color-white-100;

            &:before {
                color: $color-white-100;
            }
        }

        a {
            svg {
                &,
                * {
                    fill: $color-white-100;
                }
            }

            span {
                color: $color-white-100;
            }
        }
    }
}

.brand-detail--breadcrumb {
    background: $color-black-10;

    .breadcrumb-wrapper {
        padding-top: 18px;
        padding-bottom: 18px;

        @screen laptop {
            padding-top: 24px;
            padding-bottom: 24px;
        }
    }
}
