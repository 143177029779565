.accordion-box {
    .accordion-item {

        &.accordion-item--opened {
            .accordion-item__icon {
                transform: rotate(0);
            }

            .accordion-item__title {
                font-weight: 700;
            }
        }


        &__line {
            position: relative;
            z-index: 2;
            padding: 12px 0;
            cursor: pointer;
            background-color: $color-white-100;
        }

        &__title {
            font-size: 16px;
            font-weight: 400;
            line-height: 1.5;
            margin-left: 28px;

            &:hover {
                color: var(--color-current-theme);
            }

            &::selection {
                color: inherit;
                background: transparent;
            }
        }

        &__icon {
            position: absolute;
            top: 18px;
            left: 0;
            width: 14px;
            height: 14px;
            transition: transform 0.5s $transition-ease;
            transform: rotate(-90deg);
        }

        .accordion-item__inner {
            position: relative;
            z-index: 1;
            overflow: hidden;
            height: 0;
            transition: height calc(#{$transition-duration} * 3) $transition-ease;
            transition-duration: calc(#{$transition-duration} * 3);
        }

        .accordion-item__content {
            padding: 16px 26px;
            background-color: $color-black-10;
            @apply font-OpenSans;
        }

        &__paragraph {
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            margin: 0;
        }

        .btn {
            margin-top: 16px;
        }
    }

    &.bg-mode {
        .accordion-item--opened {
            .accordion-item__title {
                color: var(--color-current-theme);

                &:hover {
                    text-decoration: underline;
                }
            }

            .accordion-item__icon {
                transform: rotate(-180deg);
            }
        }

        .accordion-item__line {
            background-color: $color-black-20;
        }

        .accordion-item__title {
            font-weight: bold;
            margin-left: 16px;
        }

        .accordion-item__icon {
            right: 20px;
            left: auto;
            transform: rotate(0);
        }

        .accordion-item__content {
            padding: 16px;
        }

        &.dark-mode {
            .accordion-item--opened {
                .accordion-item__line,
                .accordion-item__content {
                    background-color: $color-black-80;
                }
            }

            .accordion-item__line {
                background-color: $color-black-90;
            }

            .accordion-item__icon {
                margin-left: 0;

                svg {
                    &,
                    * {
                        fill: $color-white-100;
                    }
                }
            }
        }
    }

    &.dark-mode {
        .accordions-headline {
            color: $color-white-100;
        }

        .accordion-item__line {
            background-color: $color-black-100;
        }

        .accordion-item__title {
            color: $color-white-100;

            &:hover {
                color: var(--color-current-theme);
            }
        }

        .accordion-item__content {
            background-color: $color-black-90;
        }

        .accordion-item__paragraph {
            color: $color-white-100;
        }

        .accordion-item__icon {
            svg {
                &,
                * {
                    fill: $color-white-100;
                }
            }
        }
    }
}
