/* ==========================================================================
   #PAGE-BODY
========================================================================== */

.c-page-body {
    z-index: unset;
    padding-bottom: 77px;

    @screen laptop {
        padding-top: 160px;
        padding-bottom: 80px;
    }

    .o-wrapper {
        width: 100%;
        max-width: 1624px;
        margin: 0 auto;
        padding: 0 16px;

        @screen desktop {
            padding: 0 56px;
        }
    }

    .o-grid {
        @media (min-width: map-get($breakpoints, desk)) {
            max-width: 1624px;
            margin: 0 auto;
        }

        .o-grid {
            width: 100%;
            margin: 0 auto;
        }
    }
}

#app {
    &,
    > .s-node {
        min-height: calc(#{$viewport-height});
    }
}

.s-node {
    background: $color-white-100;

    &.theme--dark {
        background: $color-black-100;
    }

    &--account,
    &--accountProfile,
    &--accountOrders,
    &--accountAddresses,
    &--cart,
    &--checkout,
    &--checkoutFinished,
    &--accountForgotPassword {
        background: $color-black-10;
    }

    &--error {
        > .c-markdown.s-text {
            position: fixed;
            z-index: 1000;
            right: 0;
            bottom: 0;
            left: 0;
            padding: 16px 16px 0;
            background: $color-red-60;

            &,
            * {
                font-family: monospace;
                color: $color-white-100;
            }
        }
    }
}


/**
 * Anchor target block
 */

div:target {

    @screen laptop {
        padding-top: 168px;
        margin-top:  -168px;
    }

    padding-top: 1em;
    margin-top:  -1em;
}
