[class*='/markdown'] {
    a {
        transition: calc(#{$transition-duration} * 2) color $transition-ease;
        @apply underline;

        @media (hover: hover) {
            &:hover {
                color: var(--color-current-theme);
            }

            &:focus,
            &:active {
                color: var(--color-current-theme-100);
            }
        }
    }

    h1, h2, h3, h4, h5, h6, p, ul, ol, dl, blockquote, code, pre, table, hr, address, fieldset, form, figure {
        @apply mb-5;
    }

    ul,
    ol {
        list-style: unset !important;
        @apply ml-4;

        li {
            &,
            + li {
                @apply m-0 mb-2;

                &::marker {
                    color: var(--color-current-theme);
                }
            }
        }
    }

    code {
        white-space: break-spaces;
    }

    .markdown--align {
        &__center {
            @apply text-center;
        }

        &__left {
            @apply text-left;
        }

        &__right {
            @apply text-right;
        }

        &__justify {
            @apply text-justify;
        }
    }

    .markdown--padding {
        &__nopadding {
            @apply p-0;
        }

        &__small {
            @apply py-4;
        }

        &__middle {
            @apply py-6;
        }

        &__large {
            @apply py-12;
        }
    }
}
