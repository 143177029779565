.sweepstakes-form {
    .dotted-bar {
        @media (max-width: $screen-mobile-only) {
            &:nth-of-type(odd) {
                @apply hidden;
            }
        }

        @screen tablet {
            &:nth-last-of-type(1) {
                right: -15%;
            }

            &:nth-last-of-type(2) {
                left: -10%;
            }
        }
    }

    &--left {
        background: $color-black-10;

        .checkbox-radio--wrapper {
            transition: calc(#{$transition-duration} * 2) box-shadow $transition-ease;
            background: $color-black-20;

            @media (hover: hover) {
                &:hover,
                &:focus {
                    box-shadow: inset 0 0 0 1px $color-black-40;
                }
            }

            &:active,
            &.is--checked {
                box-shadow: inset 0 0 0 1px $color-black-100;
            }

            &.is--invalid {
                box-shadow: inset 0 0 0 1px $color-red-60;
            }

            input[type='radio'] {
                background: $color-black-10;
                box-shadow: inset 0 0 0 1px $color-black-30;
            }
        }

        .dark-mode & {
            background: $color-black-90;

            .checkbox-radio--wrapper {
                background: $color-black-80;

                @media (hover: hover) {
                    &:hover,
                    &:focus {
                        box-shadow: inset 0 0 0 1px $color-black-60;
                    }
                }

                &:active,
                &.is--checked {
                    box-shadow: inset 0 0 0 1px $color-white-100;
                }

                input[type='radio'] {
                    background: $color-black-90;
                    box-shadow: inset 0 0 0 1px $color-black-70;
                }
            }
        }
    }
}