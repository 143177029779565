.checkout-page {
    .checkout-no-product {
        padding: 50px 0;

        @screen laptop {
            padding: 100px 0;
        }

        @screen desktop {
            max-width: 1001px;
            margin: 0 auto;
        }

        .btn-no-item {
            display: flex;
            flex-direction: column;
            margin: auto;
            font-weight: 600;

            @screen laptop {
                font-size: 26px;
            }

            svg {
                margin: 0 0 15px 0;

                @screen laptop {
                    width: 40px;
                    height: 40px;
                }
            }
        }
    }

    .account--panel-desktop-wrap {
        padding: 0 !important;
    }

    .btn-cart-submit {
        padding: 0 40px;
    }

    .checkout-steppers {
        margin: 0 auto;

        @screen desktop {
            max-width: 1001px;
        }

        .dots-description {
            position: relative;
        }

        .steps {
            background-color: $color-black-100;
        }

        .steps-text {

            &:nth-of-type(2) {
                position: absolute;
                left: calc(50% - 25px);
            }

            &:nth-of-type(3) {
                position: absolute;
                right: calc(0px - 20px);
            }
        }

        .step-disabled {
            background-color: $color-black-20;
        }

        .line-default {
            background: $color-black-20;
            width: 100%;
            height: 4px;

            &.line-active {
                background: $color-black-100;
            }
        }
    }

    .checkout-panels-slider {
        margin: 0 auto;

        @screen desktop {
            max-width: 1000px;
        }
    }

    .checkout-panels-title {
        margin-top: 42px;
        margin-bottom: 24px;

        @media (max-width: 767.98px) {
            margin: 28px 16px 20px;
            line-height: 1;
            font-size: 28px;
        }

        @screen laptop {
            max-width: 1001px;
            margin: 42px auto;
        }
    }

    .confirm-checkout-wrapper {
        padding: 0 16px;

        &.shipping-checkout-wrapper {
            margin-bottom: 16px;
            padding: 0 16px 22px;

            @screen laptop {
                padding: 24px 32px;
            }

            .shipping-content {
                font-family: $ff-openSans;
            }

            .summary-checkout-wrapper {
                .summary-checkout {
                    display: none !important;
                }
            }
        }

        &.payment-checkout-wrapper {
            margin-bottom: 16px;
            padding: 0 16px 24px;

            @screen laptop {
                padding: 24px 32px;
            }

            .adyen-checkout__button {
                margin-bottom: 32px !important;
                border-radius: 0 !important;
                background: $color-black-100 !important;

                @screen laptop {
                    margin-bottom: 0 !important;
                }
            }

            .delivery-content {
                .delivery-address,
                .delivery-billing {
                    .shipping-content {
                        p {
                            margin-bottom: 3px;
                        }
                    }
                }

                @screen laptop {
                    margin-bottom: 40px !important;
                }
            }

            .payment-selected {
                padding-bottom: 24px;
                font-family: $ff-openSans;
                font-size: 16px;

                input {
                    margin-top: 3px;
                }
            }

            .payment-content {
                padding: 24px 0px;

                @screen laptop {
                    padding: 32px 0;
                }

                .payment-type {
                    border-bottom: 1px solid $color-black-20;
                    padding: 24px 0;
                    font-family: $ff-openSans;

                    input {
                        margin-top: 3px;
                    }

                    &:first-of-type {
                        padding: 0 0 24px;
                    }

                    &:last-of-type {
                        border-bottom: none;
                        padding: 24px 0 0;
                    }

                    .payment-content-detail {
                        .payment-content-logo {
                            border: 1px solid $color-black-20;
                            padding: 5px 15px;
                            height: max-content;

                            img, svg {
                                width: 50px;
                                min-width: 50px;
                                height: 32px;
                                max-height: 32px;

                                @screen laptop {
                                    min-width: 65px;
                                    width: 65px;
                                    height: 42px;
                                    max-height: 42px;
                                }
                            }
                        }

                        .payment-content-desc {
                            margin-right: .75rem;
                        }
                    }
                }
            }

            .payment-text-changed {
                margin-left: 45px;
            }

            .shipping-checkout {
                .shipping--wrapper {
                    margin-bottom: 12px;
                    border: 1px solid $color-black-30;
                    padding: 16px 60px;
                    text-align: center;

                    input {
                        display: none;
                    }

                    &.select-class {
                        border: 1px solid $color-black-100;
                    }

                    @screen laptop {
                        padding: 24px 60px 24px;
                        margin-bottom: 0;
                        margin-right: 24px;

                        &:last-of-type {
                            margin-right: 0;
                        }
                    }

                    .shipping-method-title,
                    .shipping-method-price {
                        line-height: 1.5;
                        font-family: $ff-openSans;
                        font-size: 16px;
                    }
                }
            }
        }

        &.overview-checkout-wrapper {
            padding: 0 16px 24px;

            @screen laptop {
                padding: 24px 32px;
            }

            .product-checkout-overview {
                .product-checkout-item {
                    padding: 16px 0;

                    @screen laptop {
                        padding: 10px 0 16px;
                    }

                    .product-item {
                        border-bottom: 1px solid $color-black-30;

                        &:first-of-type {
                            padding-bottom: 16px;
                        }
                    }

                    .product-name {
                        font-family: $ff-sport2000;

                        @screen laptop {
                            font-size: 18px;
                            line-height: 18px;
                        }
                    }

                    .product-price {
                        line-height: 24px;
                        font-size: 16px;
                    }

                    .product-text {
                        color: $color-black-80;

                        &.product-price {
                            line-height: 24px;
                            color: $color-black-100;
                            font-size: 20px;

                            @screen laptop {
                                font-size: 16px;
                            }
                        }
                    }

                    .product--image {
                        display: flex;
                        flex: 0 0 30%;
                        justify-content: center;
                        border: 1px solid $color-black-20;
                        max-width: 30%;
                        height: 125px;

                        img {
                            margin: auto;
                            padding: 10px;
                        }

                        @screen laptop {
                            flex: 0 0 15%;
                            max-width: 15%;
                            height: auto;
                        }
                    }

                    .product--information {
                        margin-left: 20px;

                        .information--detail {
                            th,
                            td {
                                vertical-align: baseline;
                                color: $color-black-60;
                            }
                        }

                        @screen laptop {
                            flex: 0 0 82%;
                            max-width: 82%;
                        }
                    }
                }

                .checkout-overview-checkboxes {
                    border-bottom: 1px solid $color-black-30;
                    padding-bottom: 18px;
                    line-height: 20px;
                    font-family: $ff-openSans;
                    font-size: 14px;
                }
            }

            .summary-checkout-wrapper {
                font-family: $ff-openSans;

                @screen laptop {
                    max-width: 50%;
                    width: 50%;
                    margin-left: auto;
                }

                .line-summary {
                    display: none !important;
                }

                .sum-text, .sum-price {
                    margin-top: 16px;

                    @screen laptop {
                        margin-top: 24px;
                    }
                }

                .summary-checkout {
                    margin-top: 24px;
                }

                p {
                    margin-bottom: 5px;
                }

                .text-summary.col-start-auto {
                    color: $color-black-70;
                    font-size: 14px;
                }

                .btn-checkout {
                    padding-bottom: 24px;

                    .btn {
                        &:not(:disabled) {
                            background-color: $color-absolute-green-60;
                        }

                        @screen laptop {
                            width: 100%;
                        }
                    }
                }
            }
        }

        &.is--active {
            .checkout-title {
                svg {
                    transform: rotate(-90deg);
                }
            }
        }
    }

    .checkout-title {
        font: 700 32px/1 $ff-openSans;

        @media (max-width: $screen-under-tablet) {
            margin: 0;
            padding: 22px 0 0;
            letter-spacing: 0;
            font-size: 20px;
        }

        &.address-title {
            @media (max-width: $screen-under-tablet) {
                padding: 8px 0 15px;
            }
        }

        svg {
            transition: calc(#{$transition-duration} * 5) all $transition-ease !important;
        }
    }

    .shipping-title {
        margin: 0 0 32px;

        @media (max-width: $screen-under-tablet) {
            line-height: 18px;
            font-size: 18px;
        }
    }

    .summary-checkout-wrapper {
        .btn-checkout {
            .btn {
                width: 100%;

                @screen laptop {
                    width: auto;
                }
            }
        }

        .newsletter-contact-headline {
            font-size: 20px !important;

            @screen tablet {
                font-size: 22px !important;
            }
        }
    }

    .checkout-customer-wrapper {
        .headline-checkout,
        .account--panel-title {
            display: none;
        }

        .content-right-side {
            display: block !important;
        }

        .checkout-save-btn,
        .checkout-cancel-btn {
            padding: 0 40px;
        }

        .account-addresses {
            display: flex;
            flex-direction: column;

            .button-add-address {
                order: 2;
                margin: 0 !important;
                border-bottom: 1px solid $color-black-30;
                padding: 16px 0;

                .btn-address {
                    padding: 0;
                    text-transform: none;
                    text-decoration: underline;
                    line-height: 24px;
                    letter-spacing: 0;
                    font-size: 16px;
                    font-weight: 400;

                    svg {
                        margin: 0 10px 0 0;

                        &,
                        * {
                            fill: $color-black-100;
                        }
                    }
                }
            }

            .checkout-address-content {
                order: 1;

                .address-type {
                    border-bottom: 1px solid $color-black-30;
                    padding: 16px 0;
                }

                .shipping-address-content {
                    line-height: 24px;
                    font-size: 16px;
                    font-weight: 400;
                }

                .shipping-address-edit {
                    .delete-address {
                        &:disabled {
                            cursor: not-allowed !important;
                            color: $color-black-50;
                        }
                    }
                }
            }
        }
    }

    .account--panel-mobile {
        display: none;
    }

    .account--panel-desktop-right {
        display: block !important;
    }

    .c-page-body {
        padding-top: 20px;

        @screen laptop {
            padding-top: 40px;
        }
    }
}
