.branch-map {
    @apply bg-black;

    &--wrapper {
        max-width: 1624px;
        padding: 0 16px;

        @screen desktop {
            padding: 0 56px;
        }
    }

    &--background {
        z-index: 0;
        top: 50%;
        width: 100%;
        max-width: none !important;
        height: 100%;
        max-height: none !important;
        transform: translate3d(0, -50%, 0);
        background: $color-black-100;
        object-fit: cover;
    }

    &--information {
        transform: scale3d(0, 0, 0);
        transform-origin: 100% 50%;
        animation: calc(#{$transition-duration} * 2) $transition-ease forwards fadeAndScaleTo-1;
        opacity: 0;

        &,
        a {
            color: $color-white-100;
        }

        .character {
            display: inline-block;
            transform: translate3d(0, -100px, 0) rotate(360deg) scale3d(0, 0, 0);
            opacity: 0;
        }
    }

    &--map {
        @media (max-width: 767px) {
            margin: -64px 0;
        }

        &-wrapper {
            z-index: 0;

            @media (max-width: 1109px) {
                transform: scale3d(.75, .75, .75);
            }
        }

        img {
            width: 485px;
            height: 652px;
        }

        &-europe {
            width: 552px !important;
        }

        &-pin {
            z-index: 10;
            @apply outline-none;
            transform-origin: 50% 100%;
            opacity: 0;

            @media (hover: hover) {
                &:hover {
                    svg {
                        fill: var(--color-current-theme);
                    }
                }
            }

            svg {
                transition: calc(#{$transition-duration} * 2) fill $transition-ease;
                transform-origin: 0 100%;
                fill: $color-black-100;
                clip-path: circle(50% at 50% 50%);
            }

            &.is--current {
                z-index: 20;

                svg {
                    animation: calc(#{$transition-duration} * 2) $transition-ease-in-out forwards scaleBranchMapPin;

                    &,
                    * {
                        fill: var(--color-current-theme);
                    }
                }
            }
        }
    }

    &.has--shown {
        &:not(.has--animated) {
            .branch-map--map-pin {
                animation: calc(#{$transition-duration} * 10) $transition-ease forwards bounceInDown;
            }
        }

        &.has--animated {
            .branch-map--map-pin {
                animation: unset;
                opacity: 1;
            }
        }

        .character {
            animation: calc(#{$transition-duration} * 4) $transition-ease forwards animateCharactersFromTopLeft;

            @screen tablet {
                animation-name: animateCharactersFromRight;
            }
        }
    }
}
