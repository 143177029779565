/*
    CSS Custom Properties

    Reduce motion for users who may be sensitive to animated content
*/

:root {
    --transition-duration: .1s;
    --scroll-behavior: smooth;

    @media (prefers-reduced-motion: reduce) {
        --transition-duration: 0s;
        --scroll-behavior: auto;
    }

    --color-current-theme: #{$color-red-60};
    --color-current-theme-100: #{$color-red-100};
    --color-current-theme-90: #{$color-red-90};
    --color-current-theme-80: #{$color-red-80};
    --color-current-theme-70: #{$color-red-70};
    --color-current-theme-60: #{$color-red-60};
    --color-current-theme-50: #{$color-red-50};
    --color-current-theme-40: #{$color-red-40};
    --color-current-theme-30: #{$color-red-30};
    --color-current-theme-20: #{$color-red-20};
    --color-current-theme-10: #{$color-red-10};

    .theme--red {
        --color-current-theme: #{$color-absolute-red-60};
        --color-current-theme-100: #{$color-absolute-red-100};
        --color-current-theme-90: #{$color-absolute-red-90};
        --color-current-theme-80: #{$color-absolute-red-80};
        --color-current-theme-70: #{$color-absolute-red-70};
        --color-current-theme-60: #{$color-absolute-red-60};
        --color-current-theme-50: #{$color-absolute-red-50};
        --color-current-theme-40: #{$color-absolute-red-40};
        --color-current-theme-30: #{$color-absolute-red-30};
        --color-current-theme-20: #{$color-absolute-red-20};
        --color-current-theme-10: #{$color-absolute-red-10};
    }

    .theme--blue {
        --color-current-theme: #{$color-absolute-blue-60};
        --color-current-theme-100: #{$color-absolute-blue-100};
        --color-current-theme-90: #{$color-absolute-blue-90};
        --color-current-theme-80: #{$color-absolute-blue-80};
        --color-current-theme-70: #{$color-absolute-blue-70};
        --color-current-theme-60: #{$color-absolute-blue-60};
        --color-current-theme-50: #{$color-absolute-blue-50};
        --color-current-theme-40: #{$color-absolute-blue-40};
        --color-current-theme-30: #{$color-absolute-blue-30};
        --color-current-theme-20: #{$color-absolute-blue-20};
        --color-current-theme-10: #{$color-absolute-blue-10};
    }

    .theme--green {
        --color-current-theme: #{$color-absolute-green-60};
        --color-current-theme-100: #{$color-absolute-green-100};
        --color-current-theme-90: #{$color-absolute-green-90};
        --color-current-theme-80: #{$color-absolute-green-80};
        --color-current-theme-70: #{$color-absolute-green-70};
        --color-current-theme-60: #{$color-absolute-green-60};
        --color-current-theme-50: #{$color-absolute-green-50};
        --color-current-theme-40: #{$color-absolute-green-40};
        --color-current-theme-30: #{$color-absolute-green-30};
        --color-current-theme-20: #{$color-absolute-green-20};
        --color-current-theme-10: #{$color-absolute-green-10};
    }

    --color-white-100: #{$color-white-100};

    --color-black-100: #{$color-black-100};
    --color-black-90: #{$color-black-90};
    --color-black-80: #{$color-black-80};
    --color-black-70: #{$color-black-70};
    --color-black-60: #{$color-black-60};
    --color-black-50: #{$color-black-50};
    --color-black-40: #{$color-black-40};
    --color-black-30: #{$color-black-30};
    --color-black-20: #{$color-black-20};
    --color-black-10: #{$color-black-10};

    --color-absolute-red-100: #{$color-absolute-red-100};
    --color-absolute-red-90: #{$color-absolute-red-90};
    --color-absolute-red-80: #{$color-absolute-red-80};
    --color-absolute-red-70: #{$color-absolute-red-70};
    --color-absolute-red-60: #{$color-absolute-red-60};
    --color-absolute-red-50: #{$color-absolute-red-50};
    --color-absolute-red-40: #{$color-absolute-red-40};
    --color-absolute-red-30: #{$color-absolute-red-30};
    --color-absolute-red-20: #{$color-absolute-red-20};
    --color-absolute-red-10: #{$color-absolute-red-10};

    --color-absolute-blue-100: #{$color-absolute-blue-100};
    --color-absolute-blue-90: #{$color-absolute-blue-90};
    --color-absolute-blue-80: #{$color-absolute-blue-80};
    --color-absolute-blue-70: #{$color-absolute-blue-70};
    --color-absolute-blue-60: #{$color-absolute-blue-60};
    --color-absolute-blue-50: #{$color-absolute-blue-50};
    --color-absolute-blue-40: #{$color-absolute-blue-40};
    --color-absolute-blue-30: #{$color-absolute-blue-30};
    --color-absolute-blue-20: #{$color-absolute-blue-20};
    --color-absolute-blue-10: #{$color-absolute-blue-10};

    --color-absolute-green-100: #{$color-absolute-green-100};
    --color-absolute-green-90: #{$color-absolute-green-90};
    --color-absolute-green-80: #{$color-absolute-green-80};
    --color-absolute-green-70: #{$color-absolute-green-70};
    --color-absolute-green-60: #{$color-absolute-green-60};
    --color-absolute-green-50: #{$color-absolute-green-50};
    --color-absolute-green-40: #{$color-absolute-green-40};
    --color-absolute-green-30: #{$color-absolute-green-30};
    --color-absolute-green-20: #{$color-absolute-green-20};
    --color-absolute-green-10: #{$color-absolute-green-10};
}
