.dotted-bar {
    position: absolute;
    width: 100%;
    overflow: hidden;
    &.dotted-bar__left {
        left: 0;
        svg {
            position: absolute;
            left: 0;
        }
    }
    &.dotted-bar__right {
        right: 0;
        svg {
            position: absolute;
            right: 0;
        }
    }
    &.dotted-bar__rotate {
        svg {
            @include rotate(180)
        }
    }
}
