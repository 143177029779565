/* ==========================================================================
   #Mailjet Newsletter
========================================================================== */

.c-page-foot.o-wrapper {
    .mailjet-newsletter {
        .o-wrapper {
            width: 100%;
            max-width: 1624px;
            margin: 0 auto;
            padding: 0 16px;

            @screen desktop {
                padding: 0 56px;
            }
        }
    }
}

.mailjet-newsletter {
    @include full-width-wrapper();
    color: $color-white-100;

    iframe {
        overflow: hidden;
        min-height: 400px;

        @screen tablet {
            min-height: 200px;
            height: 280px;
        }

        @screen laptop {
            height: 215px;
            max-height: 215px;
            min-height: 170px;
        }

        @screen desktop {
            max-height: 150px;
            min-height: 150px;
        }
    }

    .dotted-bar__bottom {
        background-color: $color-black-100;
    }
}
