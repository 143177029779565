.store-detail-component {
    .headline {
        @apply ml-0 mr-0;
        margin-bottom: 20px;

        @screen tablet {
            margin-bottom: 40px;
        }

        @screen desktop {
            margin-bottom: 60px;
        }
    }

    .store-item {
        @apply relative;
        padding-left: 46px;

        @screen tablet {
            padding-left: 56px;
        }
    }

    .store-icon {
        @apply absolute left-0 top-0 h-auto;
        width: 32px;
        max-width: 32px;
        max-height: 32px;

        &.is--svg {
            .theme--red & {
                filter: $filter-color-to-absolute-red;
            }

            .theme--blue & {
                filter: $filter-color-to-absolute-blue;
            }

            .theme--green & {
                filter: $filter-color-to-absolute-green;
            }
        }
    }
}

.store-detail-page {
    margin-top: 20px;

    &-wrap {
        &-background {
            background-color: $color-black-10;
        }

        &-content {
            display: flex;
            flex-direction: column;
            padding: 0 16px;

            @screen laptop {
                flex-direction: row;
                width: 100%;
                max-width: 1624px;
                margin: auto;
                padding: 0px 56px 40px 56px;
                gap: 20px;
            }
        }

        &-headline {
            margin-bottom: 0;
            padding: 20px 16px;
            background-color: $color-black-10;

            @screen laptop {
                width: 100%;
                max-width: 1624px;
                margin: auto;
                padding: 22px 56px 40px 56px;
            }
        }

        &-map {
            width: 100%;
            margin-top: 10px;
            margin-bottom: 16px;

            @screen laptop {
                order: 1;
                flex: 1;
            }

            @screen desktop {
                margin: 0 64px 0 0;
            }
        }

        &-information {
            @screen laptop {
                order: 2;
                flex: 1;
            }
        }

        &-open-hours {
            margin: 18px 0 16px 0;

            .store-detail-page-open-hours {
                display: flex;

                &-content {
                    line-height: 20px;

                    &-status {
                        font-weight: 700;
                        color: $color-success;
                    }
                }
            }

            @screen laptop {
                order: 3;
                flex: 1;
                margin: 0;
            }
        }

        &-route {
            order: 4;
            margin-bottom: 24px;

            @screen laptop {
                flex: 1;
            }
        }
    }
}

//Route and Logos
.store-detail-route-and-logo {
    .store-detail-route {
        display: flex;
        overflow-x: scroll;

        .store-detail-route-button {
            font-size: 14px;
            font-weight: 700;
            line-height: 18px;
            display: flex;
            margin-right: 8px;
            padding: 11px 16px;
            letter-spacing: 1px;
            text-transform: uppercase;
            border: 1px solid $color-black-100;
            border-radius: 2px;

            &-icon {
                margin-right: 10px;
            }
        }
    }
}

//Sport
.store-detail-sports {
    margin-bottom: 40px;

    &-wrap {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;

        .store-detail-sport {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            min-height: 92px;
            padding: 12px 0;
            background: $color-black-10;

            svg {
                margin: auto auto 8px auto;
            }

            &-info {
                font-size: 14px;
                line-height: 20px;
                margin-top: 8px;
                text-align: center;
            }

            @screen laptop {
                padding: 16px 0;
            }
        }

        @screen laptop {
            grid-template-columns: repeat(6, 1fr);
            grid-gap: 24px;
        }
    }

    .store-detail-btn-icon {
        margin: 24px auto 0 auto;
    }

    @screen laptop {
        margin-bottom: 80px;
    }
}

//Service
.store-detail-services {
    margin-bottom: 40px;

    .store-detail-services-wrap {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;

        .store-detail-service {
            &-img-wrap {
                display: flex;
                justify-content: center;
                width: 32px;
                flex-shrink: 0;

                @screen laptop {
                    margin-right: 24px;
                }
            }

            &-img {
                width: 32px;
                height: 32px;
            }

            &-info {
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
            }

            @screen laptop {
                display: flex;
            }
        }

        @screen laptop {
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 40px;
        }
    }

    &-button {
        .btn {
            font-weight: 400;
            margin-top: 16px;
            letter-spacing: 0;
            text-transform: none;

            .service-button-icon {
                margin-right: 8px;
            }
        }
    }

    @screen laptop {
        margin-bottom: 80px;
    }
}

//Payments
.store-detail-payments {
    margin-bottom: 40px;

    .store-detail-payments-wrap {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 16px;

        .store-detail-payment {
            padding: 5px 0;
            border: 1px solid $color-black-20;

            &-img {
                height: 49px;

                @screen laptop {
                    height: 80px;
                }
            }

            @screen laptop {
                padding: 10px 0;
            }
        }

        @screen laptop {
            grid-template-columns: repeat(6, 1fr);
            grid-gap: 24px;
        }
    }

    @screen laptop {
        margin-bottom: 80px;
    }
}

//Brands
.store-detail-brands {
    margin-bottom: 40px;

    .store-detail-brands-wrap {
        .slider {
            padding: 0;
        }

        @media (max-width: $screen-under-tablet) {

            .swiper-button-next,
            .swiper-button-prev {
                display: none;
            }
        }

        .store-detail-brand {
            &-img {
                flex-shrink: 0;
                max-width: 100%;
                height: 61px;
                margin: auto;
            }
        }
    }

    @screen laptop {
        margin-bottom: 80px;
    }
}

//Teams
.store-detail-teams {
    &-wrap {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;

        @screen laptop {
            grid-template-columns: repeat(5, 1fr);
            grid-gap: 30px;
        }
    }
}

//Open Hours
.store-detail-open-hour {
    &-button {
        display: flex;
        align-items: center;
    }

    .store-slider-item--time {
        .opening-time-container {
            overflow: hidden;
            width: 100%;
            max-height: 0;
            @include transitionPrefixMultiple(calc(#{$transition-duration} * 5), max-height);

            .openning-hours {
                padding: 10px 28px 0;

                @screen laptop {
                    padding: 0;
                }
            }

            @screen laptop {
                overflow: auto;
                max-height: fit-content;
            }
        }

        &.active {
            .opening-time-container {
                max-height: 500px;
            }

            button {
                svg {
                    &:last-child {
                        transform: rotate(180deg);
                        @include transitionPrefixMultiple(calc(#{$transition-duration} * 5), transform);
                    }
                }
            }
        }
    }
}

//Tabs
.store-detail-tabs {
    .store-detail-tab {
        border-top: 1px solid $color-black-30;
        background: $color-black-10;

        &-nav {
            display: flex;
            justify-content: start;
            margin-bottom: 40px;
            padding: 25px 27px;
            overflow-x: scroll;
            @include hide-scrollbars();


            &-item {
                margin-right: 24px;

                @media (max-width: 767.98px) {
                    display: inline-block;
                    white-space: nowrap;
                }

                &-button {
                    font-weight: 700;
                    position: relative;
                    padding-bottom: 6px;

                    &.active {
                        &:before {
                            position: absolute;
                            bottom: 0;
                            width: 100%;
                            height: 2px;
                            content: '';
                            background: $color-black-100;
                        }
                    }
                }

                @screen tablet {
                    margin-right: 64px;
                }
            }

            @screen tablet {
                justify-content: center;
                max-width: 800px;
                margin: 0 auto 40px auto;
            }
        }

        &-content {
            padding: 0 16px;

            @screen laptop {
                padding: 0 56px;
                max-width: 1624px;
                margin: auto;
            }
        }
    }
}

//Brand Tab
.store-detail-brand-page {
    &-wrap {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 24px;

        .store-detail-brand-item {
            @media (max-width: $screen-mobile-only) {
                .main-image {
                    transform: translate(50%);
                }
            }
        }

        @screen tablet {
            grid-template-columns: repeat(5, 1fr);
        }

        @screen laptop {
            grid-template-columns: repeat(8, 1fr);
        }
    }
}

//Product Tab
.store-detail-tab-content-product {

    .filter-algolia,
    .filter-algolia--wrapper,
    .category-layer-wrapper {
        background: transparent;
    }

    .filter-algolia--wrapper {
        &.is--title-mobile {
            display: none !important;
        }

        h1 {
            display: none !important;
        }
    }

    .teaser--dotted-bar {
        display: none !important;
    }
}

// Store header
.store-details-header {
    display: flex;
    border-right: 1px solid $color-black-30;

    &-icon {
        margin: auto 11px auto 0;
    }

    &-content {
        font-size: 12px;
        line-height: 16px;
        margin: auto;

        &-headline {
            font-weight: 700;
        }

        @screen laptop {
            .store-details-header-name {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    .store-details-header-flyout {
        position: absolute;
        z-index: 1010;
        top: 100%;
        right: 0;
        width: 400px;
        cursor: auto;
        pointer-events: none;
        opacity: 0;
        color: $color-black-100;
        background-color: $color-white-100;
        box-shadow: $shadow-mini-cart;
        @include transitionPrefixMultiple(calc(#{$transition-duration} * 5), opacity);

        &-wrap {
            padding: 20px 24px;

            &-icon {
                position: absolute;
                top: 50%;
                right: 0;
                margin-top: -10px;
            }
        }

        .store-details-header-flyout-wrap {
            a.btn-default {
                color: $color-white-100;
                background-color: $color-black-80;
            }
        }
    }

    @screen laptop {
        max-width: 140px;

        &:hover {
            .store-details-header-flyout {
                opacity: 1;
                pointer-events: auto;
            }
        }
    }

    @screen desktop {
        max-width: 200px;
    }
}

.header-mobile--navigation {
    &.active {
        .store-details-header {
            &-flyout {
                top: auto;
                bottom: 0;
                left: 0;
                width: 100%;
                pointer-events: auto;
                opacity: 1;

                &-wrap {
                    padding: 16px;

                    &-icon {
                        position: absolute;
                        top: 50%;
                        right: 0;
                        margin-top: -10px;
                    }
                }
            }
        }
    }
}

.store-detail-page-select-store {
    font-size: 14px;

    &-button {
        border: 1px solid $color-black-50 !important;
        background-color: rgba(0, 0, 0, 0.1) !important;

        &:not(:disabled):hover {
            border: 1px solid $color-black-100 !important;
            box-shadow: none !important;
        }

        ~label {
            width: auto !important;
            margin-right: 8px !important;
        }
    }

    .tooltip-content {
        .info-icon {
            margin-right: 20px;
        }

        &:hover {
            cursor: pointer;

            .tooltip-message {
                display: block;
            }
        }
    }

    .tooltip-message {
        font-size: 14px;
        position: absolute;
        z-index: 10;
        top: 50%;
        left: 100%;
        display: none;
        min-width: 300px;
        max-width: 350px;
        margin-top: -20px;
        padding: 2px;
        text-align: center;
        color: $color-white-100;
        border-radius: 3px;
        background: $color-black-40;

        .tooltip-left {
            position: relative;

            &::before {
                position: absolute;
                top: 50%;
                left: -10px;
                content: '';
                transform: translateY(-50%);
                border-top: 9px solid transparent;
                border-right: 9px solid $color-black-40;
                border-bottom: 9px solid transparent;
            }
        }

        &-mobile {
            left: 0;
            min-width: 250px;
            margin-top: 15px;
            margin-left: -150px;

            .tooltip-left {
                &::before {
                    display: none;
                }
            }
        }
    }
}

.store-detail-special-logo {
    &-link {
        font-size: 14px;
    }
}

.store-detail-information-social-link {
    &-item {
        margin-right: 7px;
        padding: 10px;
        border: 1px solid $color-gray;
        background: $color-black-20;
    }
}