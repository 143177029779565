/*
    Variables

    Colors
*/

// Source Colors
// Do not use these variables, they are only here for calculations.
// Instead, stick to the variables of the Sport2000 or Absolute Colors below.

$color-sport-red: #ec0000;
$color-sport-black: #000;
$color-sport-white: #fff;

$color-absolute-red: #da291c;
$color-absolute-green: #78be20;
$color-absolute-blue: #00aec7;
$color-absolute-blue-background: #00ABBE1A;
$color-gray: #d8d8d8;
$color-success: #52b000;
$color-error: #bd0000;
$color-not-stock: #ecae00;

$color-bar-line: #dadada;
$color-detail-white: #f1f1f1;

// Sport2000 Colors

$color-red-100: darken($color-sport-red, 20.98);
$color-red-90: darken($color-sport-red, 16.08);
$color-red-80: darken($color-sport-red, 9.22);
$color-red-70: darken($color-sport-red, 4.90);
$color-red-60: $color-sport-red;
$color-red-50: lighten(desaturate($color-sport-red, 14.61), 18.82);
$color-red-40: lighten(desaturate($color-sport-red, 14.71), 27.06);
$color-red-30: lighten($color-sport-red, 37.25);
$color-red-20: lighten(desaturate($color-sport-red, 13.64), 45.10);
$color-red-10: lighten(desaturate($color-sport-red, 13.33), 50.78);

$color-white-100: $color-sport-white;

$color-black-100: $color-sport-black;
$color-black-90: lighten($color-sport-black, 9.80);
$color-black-80: lighten($color-sport-black, 16.47);
$color-black-70: lighten($color-sport-black, 29.80);
$color-black-60: lighten($color-sport-black, 45.10);
$color-black-50: lighten($color-sport-black, 54.90);
$color-black-40: lighten($color-sport-black, 65.10);
$color-black-30: lighten($color-sport-black, 74.90);
$color-black-20: lighten($color-sport-black, 91.76);
$color-black-10: lighten($color-sport-black, 96.86);

// Absolute Colors

$color-absolute-red-100: darken(desaturate($color-absolute-red, 0.42), 21.18);
$color-absolute-red-90: darken(saturate($color-absolute-red, 2.07), 14.12);
$color-absolute-red-80: darken(saturate($color-absolute-red, 1.55), 9.41);
$color-absolute-red-70: darken(saturate($color-absolute-red, 0.34), 4.51);
$color-absolute-red-60: $color-absolute-red;
$color-absolute-red-50: lighten(desaturate($color-absolute-red, 5.54), 10.20);
$color-absolute-red-40: lighten(desaturate($color-absolute-red, 5.08), 20.78);
$color-absolute-red-30: lighten(desaturate($color-absolute-red, 5.54), 30.98);
$color-absolute-red-20: lighten(desaturate(adjust-hue($color-absolute-red, -1), 4.16), 41.57);
$color-absolute-red-10: lighten(desaturate(adjust-hue($color-absolute-red, -1), 6.87), 46.47);

$color-absolute-green-100: darken(saturate($color-absolute-green, 1.80), 21.76);
$color-absolute-green-90: darken(saturate($color-absolute-green, 6.61), 15.29);
$color-absolute-green-80: darken(saturate($color-absolute-green, 3.40), 9.61);
$color-absolute-green-70: darken(saturate($color-absolute-green, 1.96), 4.12);
$color-absolute-green-60: $color-absolute-green;
$color-absolute-green-50: lighten(desaturate($color-absolute-green, 16.39), 11.37);
$color-absolute-green-40: lighten(desaturate($color-absolute-green, 16.26), 22.55);
$color-absolute-green-30: lighten(desaturate($color-absolute-green, 16.39), 33.92);
$color-absolute-green-20: lighten(desaturate($color-absolute-green, 16.00), 45.10);
$color-absolute-green-10: lighten(desaturate($color-absolute-green, 17.84), 50.59);

$color-absolute-blue-100: darken($color-absolute-blue, 18.82);
$color-absolute-blue-90: darken($color-absolute-blue, 12.75);
$color-absolute-blue-80: darken($color-absolute-blue, 8.43);
$color-absolute-blue-70: darken($color-absolute-blue, 4.31);
$color-absolute-blue-60: $color-absolute-blue;
$color-absolute-blue-50: lighten(desaturate($color-absolute-blue, 36.14), 12.16);
$color-absolute-blue-40: lighten(desaturate($color-absolute-blue, 36.26), 27.45);
$color-absolute-blue-30: lighten(desaturate($color-absolute-blue, 35.19), 39.80);
$color-absolute-blue-20: lighten(desaturate($color-absolute-blue, 35.48), 48.82);
$color-absolute-blue-10: lighten(desaturate(adjust-hue($color-absolute-blue, 1), 37.50), 54.71);

// Other Colors

$color-brand-facebook: #4267b2;
$color-brand-instagram: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285aeb 90%);
$color-brand-tiktok: linear-gradient(45deg, rgba(238, 29, 82, 1) 25%, rgba(105, 201, 208, 1) 75%);
$color-brand-youtube: #f00;
$color-brand-pinterest: #e60023;
$color-brand-outdoor-expert: #f7a157;
$color-alert-info: #da7e1c;
$color-alert-info-bg: rgba(218, 126, 28, 0.07);
$filter-color-to-absolute-red: brightness(0) saturate(100%) invert(20%) sepia(84%) saturate(2347%) hue-rotate(347deg) brightness(105%) contrast(101%);
$filter-color-to-absolute-blue: brightness(0) saturate(100%) invert(53%) sepia(51%) saturate(3366%) hue-rotate(151deg) brightness(94%) contrast(101%);
$filter-color-to-absolute-green: brightness(0) saturate(100%) invert(62%) sepia(58%) saturate(581%) hue-rotate(44deg) brightness(94%) contrast(89%);

/*
    Variables

    Font Families
*/

$ff-sport2000: 'Sport2000', sans-serif !default;
$ff-sport2000display: 'Sport2000 Display', sans-serif !default;
$ff-openSans: 'Open Sans', sans-serif;
$ff-notoSans: 'Noto Sans', sans-serif;

/*
    Variables

    Transitions
*/

$transition-duration: var(--transition-duration);
$transition-ease: cubic-bezier(.25, .61, .355, 1);
$transition-ease-in-out: cubic-bezier(.22, .68, 0, 1.71);

/*
    Variables
    Shadows
*/
$shadow-mini-cart: 1px 10px 10px 0 rgba(0, 0, 0, 0.12);
$shadow-header-mobile: 0 1px 10px transparentize($color-black-100, .88);

/*
    Variables

    Paths
*/

$path-fonts: '/assets/fonts';

/*
    Variables

    Provides the real Viewport Width minus the Scrollbar Width, if any
*/

$scrollbar-width: 'var(--scrollbar-width, 14px)';
$viewport-width-without-scrollbar: '(100vw - #{$scrollbar-width})';
$viewport-width-with-scrollbar: '(100vw + #{$scrollbar-width})';
$viewport-height: '(var(--viewport-height, 1vh) * 100)';

/*
    Variables

    Media screen
*/

$screen-under-tablet: 1023px;
$screen-mobile-only: 767px;
$screen-from-laptop: 1025px;
