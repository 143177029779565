.banner-tiles-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100px;

    @screen tablet {
        flex-direction: row;
    }

    .banner-tiles {
        position: relative;
        overflow: hidden;
        flex: 1;
        margin-bottom: 16px;

        @screen tablet {
            margin: 0 12px;

            &:first-of-type {
                margin-left: 0;
            }

            &:last-of-type {
                margin-right: 0;
            }
        }

        @screen desktop {
            &:hover {
                cursor: pointer;

                .banner-tiles-image {
                    img {
                        transform: scale(1.1);
                    }
                }

                &:before {
                    opacity: 1;
                }
            }
        }

        .banner-tiles-image {

            img {
                height: 100%;

                @screen desktop {
                    transform: scale(1);
                    transition: calc(#{$transition-duration} * 5) transform $transition-ease;
                }
            }
        }

        .banner-tiles-content {
            @apply text-white;
            position: absolute;
            z-index: 2;
            bottom: 0;
            width: 100%;
            max-width: 680px;
            padding: 0 16px 16px 16px;
            transition: calc(#{$transition-duration} * 5) transform $transition-ease;

            @screen laptop {
                padding: 0 32px 32px 32px;
            }

            h3 {
                font-size: 24px;
                line-height: 1;
                margin: 0 0 16px 0;

                @screen laptop {
                    font-size: 32px;
                    line-height: 1;
                }
            }

            p {
                font-size: 16px;
                line-height: 1.5;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                @supports (-webkit-line-clamp: 2) {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: initial;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
            }

            .btn {
                width: 100%;
                transition: all calc(#{$transition-duration} * 5) ease;

                .teaser-block--button-text {
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                @screen tablet {
                    width: auto;
                    max-width: 250px;
                }
            }
        }

        &::before {
            z-index: 1;
            @apply bg-gradient-to-t from-blacksport-90 to-transparent absolute w-full left-0 bottom-0 pointer-events-none;
            height: 80%;
            content: '';
            transition: calc(#{$transition-duration} * 5) opacity $transition-ease;
            opacity: .7;
        }
    }

    @screen tablet {
        &.banner-two-item {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 24px;

            .banner-tiles {
                margin: 0;
            }
        }

        &.banner-tiles-block {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 24px;

            .banner-tiles {
                margin: 0;

                &:first-of-type {
                    grid-column: 1;
                    grid-row: 1 / span 2;

                    .banner-tiles-image {
                        img {
                            position: absolute;
                            object-fit: cover;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                        }
                    }
                }

                @screen laptop {
                    &:not(:first-of-type) {
                        .banner-tiles-content {
                            transform: translateY(80px);

                            .btn {
                                opacity: 0;
                            }
                        }

                        &:hover {
                            .banner-tiles-content {
                                transform: translateY(0px);

                                .btn {
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
