.form-input {
    @apply appearance-none rounded-none;
    height: 48px;
    padding: 14px 16px;
    font-size: 16px;
    line-height: 1.25;
    color: #8C8C8C;

    &:focus {
        @apply outline-none shadow-none;
    }
}
