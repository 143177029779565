.c-page-head {
    &.o-wrapper {
        z-index: 20;

        &,
        .o-wrapper {
            width: 100%;
            max-width: 2640px;
            margin: 0 auto;
            padding-right: 16px;
            padding-left: 16px;

            @screen desktop {
                padding-right: 40px;
                padding-left: 40px;
            }
        }

        .o-grid {
            max-width: unset;
        }
    }
}

.icon-header,
.sport-absolute {

    display: block;

    svg {
        @apply text-xl;

        &,
        * {
            @apply fill-current text-xl;
            height: 100%;
        }
    }
}

.ctrl-nav {
    > button,
    > a > button,
    > div > button,
    > div > div > button {
        @include transitionPrefixMultiple(calc(#{$transition-duration} * 5), background-color);

        &:hover,
        &.active {
            @apply bg-blacksport-80;
        }

        &.is--storefinder {
            .s-node--standorte & {
                @apply bg-blacksport-80;
            }
        }

        &.is--account {
            .s-node--accountProfile &,
            .s-node--account & {
                @apply bg-blacksport-80;
            }
        }

        &.is--cart {
            .s-node--cart & {
                @apply bg-blacksport-80;
            }
        }

        &.is--wishlist {
            .s-node--accountWishlists & {
                @apply bg-blacksport-80;
            }
        }
    }

    .badge-wrapper {
        min-height: unset;
    }
}

.flyout-overlay {
    z-index: 50;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $color-black-100;

    &-enter {
        opacity: 0;
    }

    &-exit {
        opacity: .5;
    }

    &-enter-active,
    &-enter-done {
        opacity: .5;
    }

    &-exit-active {
        opacity: 0;
    }

    &-enter-active,
    &-exit-active {
        @include transitionPrefixMultiple(calc(#{$transition-duration} * 5), opacity);
    }
}

.main-header {
    @screen laptop {
        position: fixed;
        z-index: 100;
        top: 0;
        height: 160px;
        transition: calc(#{$transition-duration} * 4) height $transition-ease, calc(#{$transition-duration} * 4) transform $transition-ease;
        transform-origin: 50% 0;

        .is--scrolled & {
            height: 120px;
            transform: translate3d(0, -40px, 0);
        }

        .product-detail-page & {
            border-bottom: 1px solid $color-black-30;
        }
    }

    /* Top bar */
    .top-bar {
        @apply text-sm flex items-center text-white font-sport2000;

        &--expert-area {
            display: flex;
            align-items: center;
            margin-right: 22px;
            padding: 10px 16px 10px 50px;
            @apply font-sport2000 text-white text-sm relative;

            &.theme--green {
                &:before {
                    background-color: $color-absolute-green-60;
                }
            }

            &.theme--blue {
                &:before {
                    background-color: $color-absolute-blue-60;
                }
            }

            &:before {
                position: absolute;
                left: -16px;
                width: 16px;
                height: 100%;
                content: '';

                @screen desktop {
                    width: 40px;
                    left: -40px;
                }

                .theme--green & {
                    background-color: $color-absolute-green-60;
                }

                .theme--blue & {
                    background-color: $color-absolute-blue-60;
                }
            }

            svg {
                margin-right: 12px;

                path, * {
                    fill: $color-white-100;
                }
            }

            .theme--green &,
            &.theme--green {
                background-color: $color-absolute-green-60;
            }

            .theme--blue &,
            &.theme--blue {
                background-color: $color-absolute-blue-60;
            }
        }

        .top-nav {
            line-height: 20px;
            height: 20px;

            a {
                padding-top: 2px;
                @include link-hover--underline;
            }
        }
    }

    /* End Top bar */

    /* Start Main navigation */
    .main-start-nav {
        @apply font-openSans font-bold;
        height: 56px;

        @screen laptop {
            transition: calc(#{$transition-duration} * 4) height $transition-ease;
            will-change: height;

            .is--scrolled & {
                height: 48px;
            }
        }

        li {
            &.is--absolute-teamsport,
            &.is--running-experts {

                a,
                .btn {
                    padding-right: 20px;
                    padding-left: 20px;
                    opacity: .6;

                    @screen laptop {
                        padding-right: 0px;
                        padding-left: 0px;
                    }

                    @screen desktop {
                        padding-right: 20px;
                        padding-left: 20px;
                    }
                }
            }

            &.is--absolute-teamsport {
                a,
                .btn {
                    span > span {
                        color: $color-absolute-green-60;
                    }
                }
            }

            &.is--running-experts {
                width: 227px;

                a,
                .btn {
                    span > span {
                        color: $color-absolute-blue-60;
                    }
                }
            }

            a,
            .btn {
                @apply text-sm font-OpenSans font-bold h-full uppercase;
                padding-right: 23px;
                @include transitionPrefixMultiple(calc(#{$transition-duration} * 5), opacity);
                padding-left: 23px;
                letter-spacing: 1px;

                @screen laptop {
                    padding-right: 10px;
                    padding-left: 10px;
                }

                @screen desktop {
                    padding-right: 23px;
                    padding-left: 23px;
                }

                svg {
                    position: relative;
                    z-index: 1;
                    top: 13px;

                    .is--scrolled & {
                        height: 72px;
                        transition: calc(#{$transition-duration} * 4) height $transition-ease;
                        will-change: height;
                    }
                }

                &:hover {
                    opacity: 1;
                }

                &.main-start-nav__selected,
                &.is--sport-category {
                    opacity: 1;
                }
            }
        }

        select {
            max-height: 56px;
            padding-top: 18px;
            padding-bottom: 18px;

            @apply font-Sport2000 text-white shadow-none outline-none bg-blacksport-100;

            option {
                @apply text-blacksport-100;
            }
        }
    }

    /* End Main navigation */
    .nav-level-1 {
        > li {
            list-style: none;

            > a,
            > .btn {
                @apply font-openSans font-bold text-sm uppercase relative pointer-events-auto text-black;
                @include link-hover--underline;

                letter-spacing: 1px;

                &::after {
                    background: $color-black-100;
                }
            }

            > a {
                padding: 13px 20px 12px;

                @screen laptop {
                    padding-left: 10px;
                    padding-right: 10px;
                }

                @screen desktopx {
                    padding-left: 16px;
                    padding-right: 16px;
                }
            }

            > .btn {
                min-width: unset;
                padding: 0 12px;

                @screen desktop {
                    padding: 0 20px;
                }
            }
        }

        .nav-sale {
            a {
                color: $color-sport-red;
            }
        }
    }

    .top-menu-wrapper {
        height: 48px;

        @screen laptop {
            transition: calc(#{$transition-duration} * 4) height $transition-ease;
            will-change: height;

            .is--scrolled & {
                height: 48px;
            }
        }
    }

    .main-menu-wrapper {
        @screen tablet {
            background-color: $color-white-100;
        }

        @screen tablet {
            margin-top: 9px;
        }
    }

    .header-navigation {
        margin-top: 9px;
        @apply relative flex justify-start;

        &--list {
            overflow: hidden;
            @include transitionPrefixMultiple(calc(#{$transition-duration} * 5), opacity);
            flex-grow: 1;
            width: 100%;
            height: 48px;
            padding: 0 64px;
            text-align: center;
            opacity: 1;

            @screen desktopx {
                padding: 0 280px;
            }

            &.focused {
                opacity: 0;

                a {
                    pointer-events: none;
                }
            }
        }

        .menu-animation {
            -webkit-animation-name: cssAnimation;
            -webkit-animation-duration: calc(#{$transition-duration} * 3);
            -webkit-animation-timing-function: ease;
            -webkit-animation-iteration-count: 1;
            -webkit-animation-fill-mode: forwards;
        }

        @-webkit-keyframes cssAnimation {
            from {
                -webkit-transform: rotate(0deg) scale(0.9) skew(0deg) translate(5px);
            }
            to {
                -webkit-transform: rotate(0deg) scale(0.95) skew(0deg) translate(5px);
            }
        }

        &--logo {
            position: absolute;
            z-index: 101;
            bottom: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            max-width: 190px;
            height: 120px;

            img {
                max-width: 100%;
                height: 88px;
                max-height: 100%;
                transition: calc(#{$transition-duration} * 4) height $transition-ease;
                will-change: height;

                .is--scrolled & {
                    height: 78px;
                }
            }

            svg {
                height: 55px;
                transition: calc(#{$transition-duration} * 4) height $transition-ease;
                will-change: height;

                .is--scrolled & {
                    height: 45px;

                    @screen desktop {
                        height: 55px;
                    }

                }

                @screen desktop {
                    height: 62px;
                }
            }

            &-custom {
                a {
                    @screen laptop {
                        width: auto;
                        overflow: hidden;
                    }

                    @screen desktopx {
                        overflow: initial;
                    }
                }

                .logo-absolute-teamsport {
                    @media (min-width: 1023px) and (max-width: 1199.98px) {
                        width: 245px !important;
                    }
                }
            }

            &__category {
                img {
                    &,
                    .is--scrolled & {
                        height: 18px;
                    }
                }
            }
        }
    }

    /* Start Flyout */
    .flyout {
        position: fixed;
        z-index: -1;
        top: 0;
        right: 0;
        left: 0;
        height: auto;
        user-select: none;
        transition: calc(#{$transition-duration} * 3) opacity $transition-ease;
        pointer-events: none;
        opacity: 0;

        /*
            style for brand category in special category aria
        */
        &.flyout-brand-category {
            .flyout-left,
            .flyout-right {
                display: block;
                background-color: $color-white-100;

                > p {
                    font-weight: bold;
                    line-height: 18px;
                    margin-bottom: 20px;
                    text-align: left;
                    @apply font-OpenSans;
                }
            }

            .brand-list--wrapper {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                justify-content: flex-start;
                max-width: 464px;
                max-height: 320px;

                a {
                    @apply font-OpenSans;
                    line-height: 32px;
                    max-width: 232px;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

        &.flyout__open {
            pointer-events: auto;
            opacity: 1;

            .flyout-left,
            .flyout-right {
                transform: translate3d(0, 160px, 0);
                opacity: 1;

                @screen laptop {
                    .is--scrolled & {
                        transform: translate3d(0, 152px, 0);
                    }
                }
            }

            .flyout-left {
                overflow-y: auto;
            }
        }
    }

    .flyout-left,
    .flyout-right {
        min-height: 384px;
        max-height: calc(#{$viewport-height} - 160px);
        opacity: 0;

        @screen laptop {
            .is--scrolled & {
                max-height: calc(#{$viewport-height} - 112px);
            }
        }
    }

    .flyout-brand {
        position: relative;
        display: flex;
        flex-grow: 1;
        padding: 30px;
        transform: translate3d(0, 160px, 0);
        border-top: 1px solid $color-black-30;
        background-color: $color-white-100;

        a {
            padding: 0 35px;

            img {
                height: 75px;
            }
        }

        .is--scrolled & {
            transform: translate3d(0, 152px, 0);
        }
    }

    .flyout-left {
        @apply flex bg-white;
        width: 65%;
        padding: 24px 0 0 70px;
        transform: translate3d(0, 160px, 0);
        @include hide-scrollbars();
        transform-origin: 0 50%;

        @screen laptop {
            .is--scrolled & {
                transform: translate3d(0, 152px, 0);
            }
        }

        @screen desktopx {
            padding: 32px 32px 0 140px;
        }

        .headline-nav {
            font-size: 16px;
            font-weight: 700;
            line-height: 18/16;
            padding: 0 12px;
        }

        &.is--sport-category {
            display: flex;
            justify-content: center;
            width: 100%;
            padding-left: 70px;

            @screen desktopx {
                padding-left: 140px;
            }

            .flyout-left--list {
                > li {
                    flex: 0 1 16%;
                }
            }
        }

        &.is--normal-category {
            .flyout-left--list {
                > li {
                    &.is--only-top {
                        overflow: hidden;
                    }
                }
            }
        }

        &--list {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            justify-items: center;

            > li {
                display: flex;
                flex: 0 1 25%;
                flex-direction: column;
                margin-bottom: 32px;
                padding-left: 5px;
                text-align: left;

                &:last-of-type {
                    margin-bottom: 24px;

                    @screen desktopx {
                        margin-bottom: 32px;
                    }
                }

                &.is--only-top {

                    a {
                        font-size: 14px;
                        line-height: 18px;
                        @apply font-OpenSans font-bold uppercase;
                        position: relative;
                        display: block;
                        margin-bottom: 12px;
                        padding-right: 10px;
                        background-color: transparent;

                        @screen laptop {

                        }

                        &:hover {
                            text-decoration: underline;
                        }

                        &.sport-category {
                            position: relative;
                            padding-right: 30px;

                            .is--arrow-sport {
                                position: absolute;
                                right: 5px;
                                bottom: -2px;
                                left: auto;
                                width: 25px !important;
                                margin-bottom: 0 !important;
                            }
                        }
                    }
                }

                > ul > li {
                    a {
                        @apply font-OpenSans;
                        font-size: 14px;
                        line-height: 28px;

                        &:hover {
                            text-decoration: underline;
                        }

                        &.is--more {
                            span {
                                text-decoration: underline;
                            }

                            &:hover {
                                span {
                                    color: $color-red-60;
                                }
                            }
                        }
                    }
                }

                > a {
                    @apply font-OpenSans font-bold uppercase;
                    font-size: 14px;
                    line-height: 18px;
                    position: relative;
                    display: block;
                    margin-bottom: 6px;
                    background-color: transparent;

                    &::after {
                        display: none;
                    }

                    &:hover {
                        text-decoration: underline;

                        span {
                            @include translate(0, 0);
                            opacity: 1;
                        }
                    }

                    //span {
                    //    position: absolute;
                    //    top: 0;
                    //    right: 18px;
                    //    display: flex;
                    //    align-items: center;
                    //    width: 12px;
                    //    height: 100%;
                    //    opacity: 0;
                    //    @include translate(-10px, 0);
                    //    @include transitionPrefixMultiple(calc(#{$transition-duration} * 5), transform, opacity);
                    //
                    //    svg {
                    //        width: 12px;
                    //    }
                    //}
                }
            }
        }
    }

    .headline-nav {
        @apply font-OpenSans font-bold uppercase text-base;
        margin-bottom: 6px;

        &.is-smaller {
            @apply text-sm;
        }
    }

    .flyout-right {
        z-index: 100;
        width: 35%;
        padding: 24px 30px;
        transform: translate3d(0, 160px, 0);
        text-align: left;
        background-color: $color-black-10;

        &.is--sport-category {
            padding: 0;
        }

        @screen desktopx {
            padding: 32px 40px;
        }

        @screen laptop {
            .is--scrolled & {
                transform: translate3d(0, 152px, 0);
            }
        }

        &--headline {
            @apply font-OpenSans font-bold uppercase;
            font-size: 16px;
            line-height: 18px;
            margin-bottom: 12px;
        }

        &--campaigns {
            margin-top: 20px;

            .campaign-item {
                display: flex;
                margin-bottom: 12px;

                &:last-child {
                    margin-bottom: 0;
                }

                img {
                    width: 100px;
                    height: 100px;
                    margin-right: 12px;
                }

                p {
                    @apply font-OpenSans font-bold;
                    font-size: 14px;
                    line-height: 24px;
                    margin-bottom: 4px;
                }

                a {
                    font-size: 14px;
                    line-height: 20px;
                    text-decoration: underline;
                }
            }
        }

        &--blogs {
            display: flex;
            flex-direction: column;

            a {
                font-size: 14px;
                line-height: 30px;
                height: 30px;
                @apply font-OpenSans;
                border-bottom: 1px solid $color-black-20;

                &:last-child {
                    border-bottom: none;
                }

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        &--close {
            @apply absolute right-0 top-0;
            z-index: 9;
            width: 20px;
            padding: 5px 20px;
            cursor: pointer;

            svg {
                width: 20px;
                height: 20px;

                &,
                * {
                    fill: $color-white-100;
                }
            }
        }

        &--list {
            @apply text-white relative;
            z-index: 1;
            max-width: 780px;

            @screen laptop {
                display: flex;
                flex-direction: column;
                height: 100%;
            }
        }

        &--sport {
            display: flex;
            flex-direction: column;

            .sport-category-detail {
                display: flex;
                height: 50%;

                &:first-child {
                    padding: 38px 40px 20px;
                    border-bottom: 1px solid $color-black-30;
                }

                &:last-child {
                    padding: 20px 40px 38px;
                }

                &--left {
                    max-width: 40%;
                }

                a {
                    font-family: $ff-openSans;
                    font-size: 14px;
                    font-weight: bold;
                    line-height: 18px;
                    display: block;
                    margin-bottom: 6px;
                    text-transform: uppercase;

                    &:hover {
                        text-decoration: underline;
                    }

                    &.is--running-experts {
                        color: $color-absolute-blue-60;
                    }

                    &.is--teamsport {
                        color: $color-absolute-green-60;
                    }
                }

                ul {
                    min-width: 230px;

                    li {
                        a {
                            font-weight: 400;
                            line-height: 28px;
                            margin-bottom: 0;
                            text-transform: capitalize;

                            &.is--more {
                                text-decoration: underline;
                                text-transform: lowercase;

                                &.is--running-experts,
                                &.is--teamsport {
                                    color: inherit;
                                }
                            }

                            &:hover {
                                &.is--teamsport {
                                    color: $color-absolute-green-60;
                                }

                                &.is--running-experts {
                                    color: $color-absolute-blue-60;
                                }
                            }
                        }
                    }
                }
            }
        }

        &--list-wrapper {
            @apply flex overflow-hidden;
            align-content: flex-start;
            flex-direction: column;
            flex-wrap: wrap;
            width: 100%;
            max-width: 780px;

            .nav-item {
                width: calc(100% / 2);
                margin: 5px 0;
                padding-right: 32px;

                &:last-child {
                    a {
                        &::after {
                            transform: scaleX(1);
                        }
                    }
                }
            }

            a {
                @apply font-OpenSans text-base block;
                @include transitionPrefixMultiple(calc(#{$transition-duration} * 9), all);
                @include link-hover--underline;
            }

            @screen laptop {
                flex: 1;
            }
        }

        &-marketing-content {
            display: flex;
            overflow-x: scroll;
            flex-direction: row;
            flex-wrap: nowrap;
            @include hide-scrollbars();
            margin: 16px 0px 20px 16px;
            gap: 20px;

            .flyout-marketing-item {
                position: relative;
                overflow: hidden;
                flex: 0 0 75%;

                &-img {
                    height: 200px;
                    object-fit: cover;
                    @include scale(1, 1);
                    @include transitionPrefixMultiple(calc(#{$transition-duration} * 5), transform);

                    @screen laptop {
                        height: 250px;
                    }
                }

                .flyout-marketing-content {
                    position: absolute;
                    z-index: 1;
                    right: 12px;
                    bottom: 12px;
                    left: 12px;
                    transition: calc(#{$transition-duration} * 5) all $transition-ease;

                    @screen laptop {
                        left: 24px;
                        right: 24px;
                        bottom: -24px;
                    }

                    &-headline {
                        font-family: $ff-sport2000;
                        font-size: 24px;
                        font-weight: 700;
                        line-height: 28px;
                        margin-bottom: 10px;
                        color: $color-white-100;

                        @screen laptop {
                            font-size: 28px;
                            line-height: 32px;
                        }
                    }

                    &-button {
                        font-family: $ff-openSans;
                        line-height: 24px;
                        position: relative;
                        display: inline-block;
                        text-decoration: underline;
                        color: $color-white-100;

                        &:after {
                            position: absolute;
                            top: 50%;
                            width: 20px;
                            height: 16px;
                            margin-top: -6px;
                            margin-left: 12px;
                            content: '';
                            transform: rotate(180deg);
                            background-image: url('../../icons/sport2000-icons/arrowleft_white.svg');
                            background-repeat: no-repeat;
                            background-size: cover;
                        }
                    }
                }

                &:hover {
                    .flyout-marketing-content {
                        bottom: 24px;
                    }

                    .flyout-marketing-item-img {
                        cursor: pointer;
                        transform: scale(1.1, 1.1);
                    }
                }

                &::before {
                    @apply bg-gradient-to-t bg-no-repeat from-blacksport-90 to-transparent absolute w-full left-0 bottom-0 pointer-events-none;
                    z-index: 1;
                    height: 80%;
                    content: '';
                    opacity: 1;

                    @screen desktop {
                        opacity: .7;
                    }
                }

                @screen laptop {
                    flex: 0 0 50%;
                }

                &:last-of-type {
                    margin-right: 16px;

                    @screen laptop {
                        margin-right: 0;
                    }
                }
            }

            @screen laptop {
                gap: 24px;
                margin: 41px 0px 0px 0px;
                overflow-x: hidden;
                flex: none;
            }
        }
    }

    .wrapper-sport {
        @screen desktop {
            padding-right: 80px !important;
            padding-left: 80px !important;
        }
    }

    .brand-logo--wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: minmax(98px, auto);
        gap: 16px;

        @screen laptop {
            grid-template-columns: repeat(4, 1fr);
            grid-auto-rows: minmax(108px, auto);
        }

        @screen desktopxx {
            grid-template-columns: repeat(5, 1fr);
        }

        a {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 20px 25px;
            background-color: $color-black-20;

            &:before {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                content: '';
                opacity: 0;
                border: 1px solid $color-black-100;
                background-color: transparent;
                @include transitionPrefixMultiple(calc(#{$transition-duration} * 3), all);
            }

            &:hover {
                &:before {
                    opacity: 1;
                }
            }
        }
    }

    /* End Flyout */
}

/* Open Flyout The page is not scrollable */
.nav-banner {
    margin-top: 16px;
    margin-bottom: 16px;
    padding-left: 16px;

    @screen laptop {
        position: absolute;
        bottom: 22px;
        z-index: 9;
        left: 0;
        width: 100%;
        margin-bottom: 0;
        padding-left: 40px;
    }

    @screen desktopx {
        bottom: 32px;
    }

    &--wrapper {
        @include hide-scrollbars();
        overflow-x: scroll;
        white-space: nowrap;
    }

    &--item {
        position: relative;
        display: inline-block;
        overflow: hidden;
    }

    .o-block--content {
        .title {
            @apply font-Sport2000 font-bold overflow-hidden ml-0 mr-0 whitespace-no-wrap;
            font-size: 24px;
            line-height: 1.167;
            margin-bottom: 6px;
            text-overflow: ellipsis;
            @include line-clamp(2);

            @screen desktopx {
                font-size: 28px;
                line-height: 1.143;
            }
        }
    }
}


.o-block--content {
    margin-right: 20px;

    @screen laptop {
        margin-right: 24px;
    }

    a {
        display: block;
        width: 320px;

        @screen desktop {
            &:hover {
                .banner-text {
                    @include translate(0, 0);
                    @include transitionPrefixMultiple(calc(#{$transition-duration} * 5), transform);
                }

                &:before {
                    opacity: 1;
                }
            }
        }

        &::before {
            z-index: 1;
            @apply bg-gradient-to-t from-blacksport-90 to-transparent absolute w-full left-0 bottom-0 pointer-events-none;
            height: 80%;
            content: '';
            opacity: .7;
            @include transitionPrefixMultiple(calc(#{$transition-duration} * 5), opacity);
        }

        .banner-text {
            position: absolute;
            z-index: 2;
            top: 0;
            left: 0;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            width: 100%;
            height: 100%;
            padding: 0 12px;
            cursor: pointer;
            color: $color-white-100;

            @screen laptop {
                padding: 0 24px 24px;
            }

            @screen desktop {
                @include translate(0, 45px);
                @include transitionPrefixMultiple(calc(#{$transition-duration} * 5), transform);
            }

            .read-more-link {
                display: flex;

                .icon-arrow-r {
                    position: static;
                    margin-left: 12px;
                    fill: $color-white-100;
                }

                .text {
                    &:after {
                        background-color: $color-white-100;
                    }
                }

                &:hover {
                    .text {
                        &:after {
                            background-color: var(--color-current-theme-60);
                        }
                    }

                    .icon-arrow-r {
                        fill: var(--color-current-theme-60);
                    }
                }
            }
        }
    }
}
