.brand-slider {
    position: relative;
    margin-right: auto;
    margin-left: auto;

    @screen tablet {
        .slick-list {
            margin: 0 -8px 0 -3px;
        }
    }

    .slider {
        padding: 0;
    }

    .brand-slider-mobile {
        @include hide-scrollbars();
        display: flex;
        overflow-x: scroll;
        white-space: nowrap;
    }

    .brand-wrapper {
        position: relative;
        overflow: hidden;
        flex-shrink: 0;
        width: 35%;
        height: 100%;
        min-height: 80px;
        padding: 20px 6px;

        @screen tablet {
            width: inherit;
        }

        @screen laptop {
            padding: 20px 0;
        }

        @screen desktop {
            padding: 35px 12px;
        }

        .brand-image {
            img {
                flex-shrink: 0;
                max-width: 100%;
                height: 61px;
                margin: auto;

                @screen laptop {
                    height: 71px;
                }
            }

            @screen desktop {
                &:hover {
                    cursor: pointer;

                    img {
                        transform: scale(1.1);
                        @include transitionPrefixMultiple(calc(#{$transition-duration} * 5), transform);
                    }
                }
            }
        }
    }
}
