.product-categories-tiles {
    margin-top: 56px;
    margin-bottom: 56px;

    &-headline {
        margin-bottom: 25px;
    }

    &-list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 20px;
        row-gap: 20px;

        @screen laptop {
            grid-template-columns: repeat(6, 1fr);
            column-gap: 24px;
            row-gap: 24px;
        }

        &-item {
            border: 1px solid $color-black-20;
            background: $color-black-10;
            padding: 15px 0;
            text-align: center;

            &-link {
                font-weight: 700;

            }

            &:active,
            &:hover {
                border: 1px solid $color-black-100;
                cursor: pointer;
            }
        }
    }
}
