.confirmation-register {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .24);

    &--wrapper {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        min-width: 488px;
        max-width: 100%;
        min-height: 288px;
        padding: 40px;
        background-color: $color-white-100;
    }

    &--title {
        font-family: $ff-sport2000;
        font-size: 32px;
        font-weight: bold;
        line-height: 1;
        margin: 0;
    }

    &--desc {
        font-size: 16px;
        margin: 20px 0;
        text-align: center;
    }

    &--icon {
        margin-bottom: 8px;

        svg {
            g, path {
                fill: $color-success;
            }
        }
    }

    &--btn {
        min-width: 215px;
    }
}
