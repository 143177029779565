.teaser {
    @apply text-blacksport-100;

    .unactive-slide {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-row-gap: 24px;
        grid-column-gap: 24px;

        .teaser-block {
            margin-right: 0;
            margin-left: 0;
        }

        @screen laptop {
            grid-template-columns: repeat(4, 1fr);
        }
    }

    &.dark-mode {
        @apply text-white;
        .teaser-block {
            padding-bottom: 20px;
            @screen tablet {
                padding-bottom: 0;
            }
        }
    }

    .teaser-mobile {
        margin: 0 -16px;
        overflow: hidden;
        overflow-x: auto;
        white-space: nowrap;

        .teaser-block {
            display: inline-block;
            margin-right: 0;
            margin-left: -12px;
            padding: 0 16px;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }

            h5 {
                margin-right: 0;
                margin-left: 0;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            a {
                button {
                    width: 100%;
                }
            }
        }
    }

    h2 {
        margin-bottom: 32px;
        text-align: center;
        line-height: 1;
        font-size: 32px;
        @screen laptop {
            font-size: 40px;
            line-height: 1.1;
            text-align: left;
        }
    }

    .teaser-block {
        display: inline-block;
        max-width: 80vw;
        overflow: hidden;
        min-height: 400px;

        @screen tablet {
            min-height: 350px;
        }

        @screen desktop {
            padding-bottom: 0;
            max-width: inherit;
            min-height: 500px;

            &:hover {
                cursor: pointer;

                .teaser-block--button {
                    opacity: 1;
                    pointer-events: visible;
                }

                img {
                    transform: scale(1.1);
                }
            }
        }
        &--image {
            height: auto;
            overflow: hidden;
            aspect-ratio: 1;

            img {
                @screen desktop {
                    transform: scale(1);
                    @include transitionPrefixMultiple(calc(#{$transition-duration} * 5), transform);
                }
            }
        }

        &--name {
            margin-top: 20px;
            margin-bottom: 8px;
            line-height: 24px;
            white-space: initial;
            font-weight: bold;
            @include line-clamp(1);

            @screen desktop {
                font-weight: bold;
                line-height: 24px;
                margin-top: 20px;
                margin-bottom: 8px;
            }
        }

        &--desc {
            margin-bottom: 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 20px;
            white-space: initial;
            white-space: nowrap;
            font-size: 14px;
            @include line-clamp(2);

            @screen desktop {
                font-size: 14px;
                line-height: 20px;
                margin-bottom: 16px;
            }
        }

        &--button {
            @screen desktop {
                opacity: 0;
                pointer-events: none;
                @include transitionPrefixMultiple(calc(#{$transition-duration} * 5), opacity);
            }
        }
    }

    &--dotted-bar {
        .dotted-bar {
            &.dotted-bar__top {
                &.dotted-bar__left {
                    display: none;
                    @screen laptop {
                        display: block;
                        svg {
                            left: -25%;
                        }
                    }
                    @screen desktop {
                        svg {
                            left: -5px;
                        }
                    }
                }

                &.dotted-bar__right {
                    @screen laptop {
                        svg {
                            right: -15%;
                        }
                    }
                    @screen desktop {
                        svg {
                            right: 0;
                        }
                    }
                }
            }

            &.dotted-bar__bottom {
                &.dotted-bar__right {
                    display: none;
                    @screen laptop {
                        display: block;
                        svg {
                            right: -25%;
                        }
                    }
                    @screen desktop {
                        svg {
                            right: -5px;
                        }
                    }
                }

                &.dotted-bar__left {
                    @screen laptop {
                        svg {
                            left: -15%;
                        }
                    }
                    @screen desktop {
                        svg {
                            left: 0;
                        }
                    }
                }
            }
        }
    }
}
