/*
    Hide Scrollbars but keep scrollability
*/
@mixin hide-scrollbars() {
    scrollbar-width: none;
    -ms-overflow-style: none;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
        display: none;
    }
}


/*
    Full Width
*/
@mixin full-width-wrapper {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
}


/*
    One Line
*/
@mixin one-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


/*
    Media Fill
*/
@mixin media-fill {
    display: flex !important;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    object-fit: cover;

    img {
        flex-shrink: 0;
        min-width: 100%;
        min-height: 100%;
    }
}

/*
    Mixins

    Font Face
    @param {String} $name - name of the font
    @param {String} $path - path to the font, including name of the file BUT without ending
    @param {String} $weight - font-weight, optional
    @param {String} $style - font-style, optional
    @param {String} $extensions - font file extensions, eg. "eot ttf woff2" or "woff2 woff svg"
*/

@mixin font-face($name, $path, $weight: null, $style: null, $extensions: eot woff2 woff ttf svg) {
    $src: null;

    $extmods: (
            eot: '?',
            svg: '#' + str-replace($name, ' ', '_')
    );

    $formats: (
            otf: 'opentype',
            ttf: 'truetype'
    );

    @each $extension in $extensions {
        $extmod: if(map-has-key($extmods, $extension), $extension + map-get($extmods, $extension), $extension);
        $format: if(map-has-key($formats, $extension), map-get($formats, $extension), $extension);
        $src: append($src, url(quote($path + '.' + $extmod)) format(quote($format)), comma);
    }

    @font-face {
        font-display: swap;
        font-weight: $weight;
        font-family: quote($name);
        font-style: $style;
        src: $src;
    }
}

/*
    Hr
*/
@mixin hr {
    border-bottom: 1px solid $color-black-20;

    @screen tablet {
        border-color: $color-black-30;
    }
}
