.is--scrolled {
    .product-list-filter {
        @screen laptop {
            top: 112px;

            &::before,
            &::after {
                left: 50%;
                transform: translate3d(-50%, 0, 0);
                width: 100vw;
                right: auto;
            }
        }
    }
}

.product-list-filter {
    position: sticky;
    z-index: 12;
    top: 0;
    display: flex;
    max-width: 1624px;
    padding: 0;
    border: none;
    background: $color-white-100;
    box-shadow: 16px 0 0 0 $color-white-100, -16px 0 0 0 $color-white-100;

    @screen laptop {
        position: sticky;
        margin-top: 40px;
        top: 168px;
        transition: calc(#{$transition-duration} * 4) top $transition-ease;

        &::before,
        &::after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            height: 1px;
            background: $color-black-20;
        }

        &::before {
            top: 0;
        }

        &::after {
            bottom: 0;
        }
    }

    &.filter-all-wrapper {
        z-index: 1001;
        margin-top: 0;
        border: none;
    }

    &--btn-open-all {
        font-size: 14px;
        line-height: 18px;
        position: fixed;
        z-index: 9999;
        bottom: 64px;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        @apply font-OpenSans font-bold;
        width: 100%;
        height: 48px;
        text-transform: uppercase;
        border-top: 1px solid $color-black-20;
        background-color: $color-white-100;

        span {
            &:last-child {
                margin-left: 10px;
            }
        }

        @screen laptop {
            display: none;
        }
    }

    .product-list-filter-gender {
        display: flex;
        flex-grow: 1;
        margin: 16px 0;
        border: 1px solid $color-black-30;

        @screen laptop {
            margin: 20px 0;
            flex-grow: unset;
        }

        > button {
            font-size: 14px;
            @apply font-OpenSans;
            flex: 1;
            text-transform: none;
            @include transitionPrefixMultiple(calc(#{$transition-duration} * 5), all);
            border-right: 1px solid $color-black-30;
            background-color: $color-white-100;

            @screen laptop {
                min-width: 120px;
                flex: unset;
            }

            &:last-child {
                border-right: none;
            }

            @media (hover: hover) {
                &:hover {
                    background-color: $color-black-10;
                }
            }

            &.active {
                color: $color-white-100;
                background-color: $color-black-100;
            }
        }
    }

    .product-list-filter-term {
        display: none;
        flex-grow: 1;
        justify-content: flex-end;

        @screen laptop {
            display: flex;
        }

        .btn-all-filter {
            font-size: 14px;
            @apply font-OpenSans;
            margin: 20px 0;
        }
    }

    .product-filter-item {
        position: relative;
        margin-right: 32px;
        padding: 20px 0;
        @apply font-OpenSans;

        &:last-child {
            margin-right: 0;
        }

        &:first-child {
            .product-filter-item--content {
                right: auto;
                left: 0;
            }
        }

        @media (hover: hover) {
            &:hover {
                > button {
                    color: $color-red-60;

                    svg {
                        &,
                        * {
                            fill: $color-red-60;
                        }
                    }
                }
            }
        }

        &.opened {
            &::before {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 3px;
                content: '';
                background-color: $color-black-100;
            }

            .product-filter-item--content {
                top: 100%;
                display: block;
            }

            > button {
                color: $color-red-60;

                svg {
                    &,
                    * {
                        transform: scale3d(1, -1, 1);
                        fill: $color-red-60;
                    }
                }
            }

            &.static-position {
                .product-filter-item--content {
                    height: 100%;
                }
            }
        }

        > button {
            height: 100%;
            transition: calc(#{$transition-duration} * 5) color $transition-ease;

            span:first-of-type {
                order: 2;
            }

            svg {
                &,
                * {
                    transition: calc(#{$transition-duration} * 5) all $transition-ease;
                }
            }
        }

        &.product-filter-item--color {
            .product-filter-item--content {
                .filter-item {
                    label {
                        min-height: 44px;
                        margin: 0 !important;
                        padding: 0 20px 0 58px;

                        @media (hover: hover) {
                            &:hover {
                                background-color: $color-black-10;
                            }
                        }

                        &::before {
                            display: none;
                        }

                        .filter-item--color-box {
                            position: absolute;
                            top: 14px;
                            left: 20px;
                            width: 20px;
                            height: 20px;
                            content: '';

                            &.color-white {
                                border: 1px solid $color-black-20;
                            }
                        }
                    }

                    &.active {
                        label {
                            &::after {
                                position: absolute;
                                top: 12px;
                                left: 18px;
                                width: 24px;
                                height: 24px;
                                content: '';
                                border: 1px solid $color-black-100;
                            }
                        }
                    }
                }
            }
        }

        &--content {
            position: absolute;
            z-index: 1;
            top: calc(100% + 1px);
            right: 0;
            display: none;
            overflow-y: auto;
            @include hide-scrollbars();
            width: 320px;
            max-height: 300px;
            padding: 10px 0;
            transform-origin: top;
            border: 1px solid $color-black-20;
            background-color: $color-white-100;

            &.product-filter-price {
                padding: 16px 20px 20px;

                .rc-slider-handle {
                    width: 20px !important;
                    height: 20px !important;
                    margin-top: -9px !important;
                }

                .rc-slider-rail {
                    background-color: $color-black-20 !important;
                }

                .rc-slider-track {
                    background-color: $color-black-70 !important;
                }

                .product-filter-price--input {
                    display: flex;
                    margin-bottom: 20px;

                    > span {
                        position: relative;
                        display: inline-block;
                        width: 48px;
                        height: 48px;

                        &::before {
                            position: absolute;
                            top: calc(50% - 1.5px);
                            left: calc(50% - 8px);
                            width: 16px;
                            height: 2px;
                            content: '';
                            background-color: $color-black-100;
                        }
                    }

                    .product-filter-price--input-wrapper {
                        position: relative;
                        width: calc(50% - 24px);

                        &.error {
                            input {
                                border-bottom: 1px solid $color-red-60;
                            }
                        }

                        input {
                            width: 100%;
                            height: 48px;
                            padding: 16px 40px 16px 16px;
                            outline: none;
                            background-color: $color-black-10;
                        }

                        span {
                            font-size: 16px;
                            line-height: 20px;
                            @apply font-OpenSans;
                            position: absolute;
                            top: 14px;
                            right: 16px;
                        }
                    }
                }

                .product-filter-price--range {
                    padding: 0 14px 10px;
                }
            }

            .filter-item {
                cursor: pointer;

                &.active {
                    label {
                        > span {
                            display: block;
                        }
                    }
                }

                > label {
                    font-size: 14px;
                    line-height: 20px;
                    @apply font-OpenSans;
                    position: relative;
                    display: flex;
                    align-items: center;
                    min-height: 48px;
                    padding: 0 20px 0 52px;
                    cursor: pointer;
                    background-color: $color-white-100;
                    @include transitionPrefixMultiple(calc(#{$transition-duration} * 5), all);

                    @media (hover: hover) {
                        &:hover {
                            background-color: $color-black-10;
                        }
                    }

                    &::before {
                        position: absolute;
                        top: 14px;
                        left: 20px;
                        width: 20px;
                        height: 20px;
                        content: '';
                        background-color: $color-black-20;
                    }

                    > span {
                        position: absolute;
                        z-index: 1;
                        top: 17px;
                        left: 23px;
                        display: none;
                        width: 14px;
                        height: 14px;
                    }
                }
            }
        }
    }
}

// Filter sidebar
.overlay-filter {
    position: fixed;
    z-index: 9998;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    transition: all calc(#{$transition-duration} * 4);
    opacity: .5;
    background-color: $color-black-90;
}

.product-list-filter-all {
    position: fixed;
    z-index: 9999;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    transition: all calc(#{$transition-duration} * 3.5);
    background-color: $color-white-100;

    @screen laptop {
        width: 480px;
    }

    .product-filter-item {
        margin-right: 0;
        padding: 0 16px;
        border-bottom: 1px solid $color-black-40;

        @screen laptop {
            padding: 0 24px;
        }

        &::before {
            display: none;
        }

        &.product-filter-item--color {
            .product-filter-item--content {
                .filter-item {
                    margin: 2px 0;

                    &:first-child {
                        margin-top: 8px;
                    }

                    &:last-child {
                        margin-bottom: 8px;
                    }

                    &.active {
                        label {
                            background-color: $color-black-10;

                            &::after {
                                position: absolute;
                                top: 9px;
                                left: 22px;
                                width: 30px;
                                height: 30px;
                                content: '';
                                border: 1px solid $color-black-100;
                            }

                            &.filter-item--color {
                                label {
                                    &::after {
                                        border: 1px solid $color-black-100;
                                    }
                                }
                            }
                        }
                    }

                    label {
                        min-height: 44px;
                        margin: 0 -24px !important;
                        padding: 0 20px 0 65px;

                        @media (hover: hover) {
                            &:hover {
                                background-color: $color-black-10;
                            }
                        }

                        &::before {
                            display: none;
                        }

                        .filter-item--color-box {
                            position: absolute;
                            top: 11px;
                            left: 24px;
                            width: 26px;
                            height: 26px;
                            content: '';

                            &.color-white {
                                border: 1px solid $color-black-20;
                            }
                        }
                    }
                }
            }
        }

        .product-filter-item--content {
            position: static;
            width: 100%;
            padding: 0;
            border: none;

            &.product-filter-price {
                padding-bottom: 24px;
            }

            .filter-item {
                &.active {
                    label {
                        > span {
                            display: block;
                        }
                    }
                }

                label {
                    min-height: 20px;
                    margin-bottom: 20px;
                    padding: 0 20px 0 32px;

                    &:first-child {
                        margin-top: 8px;
                    }

                    &:last-child {
                        margin-bottom: 24px;
                    }

                    &::before {
                        top: 0;
                        left: 0;
                    }

                    span {
                        top: 3px;
                        left: 3px;
                    }

                    @media (hover: hover) {
                        &:hover {
                            background-color: transparent;
                        }
                    }
                }
            }
        }

        > button {
            width: 100%;
            height: 56px;

            @screen tablet {
                height: 64px;
            }
        }
    }

    &--header {
        position: relative;
        padding: 28px 16px 12px;
        text-align: center;

        @screen tablet {
            padding-top: 32px;
        }

        h6 {
            font-size: 24px;
            font-weight: bold;
        }

        > span {
            position: absolute;
            top: 32px;
            right: 20px;
            width: 20px;
            height: 20px;
            cursor: pointer;
        }

        .product-list-filter-gender {
            flex-grow: 1;
            margin: 0;
            margin-top: 32px;

            button {
                flex: 1;
            }
        }
    }

    &--content-wrapper {
        position: absolute;
        @include hide-scrollbars();
        overflow-y: auto;
        width: 100%;
        height: 100%;
    }

    &--content {
        position: relative;
        flex-grow: 1;
    }

    &--footer {
        padding: 25px 14px;
        border-top: 1px solid $color-black-40;

        @screen laptop {
            padding: 25px 6px;
        }
        p {
            @apply font-OpenSans;
            font-size: 14px;
            line-height: 20px;
            display: flex;
            justify-content: center;
            margin-bottom: 12px;
            text-align: center;

            span {
                margin-left: 2px;
            }
        }

        .filter-footer-action {
            display: flex;
            flex-grow: 1;

            button {
                font-size: 14px;
                width: 100%;
                margin: 0 10px;
                @apply font-OpenSans;
            }
        }
    }
}

// chosen filter
.product-list-chosen-filter {
    display: flex;
    flex-flow: row wrap;
    max-width: 1624px;
    margin-bottom: 8px;
    padding: 0 10px;

    @screen laptop {
        padding: 0;
        margin: 12px auto 16px;
    }

    .chosen-filter-item {
        font-size: 14px;
        display: flex;
        align-items: center;
        height: 28px;
        margin: 0 6px 8px;
        padding: 0 8px;
        cursor: pointer;
        border-radius: 6px;
        background-color: $color-black-20;
        @apply font-OpenSans;

        @screen laptop {
            margin-right: 10px;
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }

        &--btn-remove {
            margin-left: 12px;
        }

        .is--price {
            margin-right: 2px;
        }

        &.clear-all {
            border: 1px solid $color-black-20;
            background-color: $color-white-100;
        }
    }
}

// transition filter all
.filter-all-enter {
    .overlay-filter {
        opacity: 0;
    }

    .product-list-filter-all {
        transform: translate(100%, 0);
    }
}

.filter-all-enter-active {
    .overlay-filter {
        opacity: .5;
    }

    .product-list-filter-all {
        transform: translate(0, 0);
    }
}

.filter-all-exit {
    .overlay-filter {
        opacity: .5;
    }

    .product-list-filter-all {
        transform: translate(0, 0);
    }
}

.filter-all-exit-active {
    .overlay-filter {
        opacity: 0;
    }

    .product-list-filter-all {
        transform: translate(100%, 0);
    }
}
