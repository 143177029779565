.c-alert {
    font-size: 14px;
    position: fixed;
    z-index: 1000;
    right: 0;
    bottom: 64px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 16px 24px;
    color: $color-white-100;

    @screen laptop {
        max-width: 100vw;
        transform: translateX(-50%);
        left: 50%;
        bottom: 24px;
        right: auto;
        width: max-content;
    }

    &--error {
        background: $color-red-60;
    }

    &--success {
        background: $color-absolute-green-60;
    }

    &__message {
        margin-right: 16px;
        margin-bottom: 0;
    }

    .c-button {
        width: 16px;
        height: auto;
        margin-left: auto;
        padding: 0;
    }
}
