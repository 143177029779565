._1dpt6j2 {
    width: 150px !important;
}

._1gwv20v {
    ._yzlvut {
        margin-bottom: 60px !important;
    }

    ._1unq6et {
        visibility: visible !important;
        opacity: 1 !important;
        z-index: 1 !important;
        margin-bottom: 60px !important;
    }

    ._owyw4l {
        margin-bottom: 20px !important;

        @screen laptop {
            margin-bottom: 0px !important;
        }
    }
}
