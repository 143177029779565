.openning-hours {
    display: flex;
    flex-direction: column;
    width: 100%;

    p {
        display: flex;
        margin-bottom: 4px;
        width: 100%;
        max-width: 230px;
        text-transform: capitalize;

        span {
            display: flex;
            flex-grow: 1;
            justify-content: flex-end;
        }
    }
}
