$z-index-modal: 1001;
.ReactModal {
    &__Overlay {
        z-index: $z-index-modal;
    }
}

.modal {
    &__header,
    &__footer {
        @apply fixed left-0 right-0;
        z-index: $z-index-modal + 1;
        background-color: $color-white-100;
        padding-right: 16px;
        padding-left: 16px;

    }

    &__header {
        @apply top-0 text-center;
        border-bottom: 1px solid $color-black-30;
        padding-top: 20px;
        padding-bottom: 20px;

        .headline {
            margin: 0;
        }

        .btn-close {
            @apply absolute;
            top: 22px;
            right: 16px;

            &::after {
                top: -22px;
                @apply absolute;
                right: -16px;
                width: 64px;
                height: 64px;
                content: '';
            }
        }
    }

    &__body {
        padding-top: 64px;
        padding-bottom: 100px;
    }

    &__footer {
        @apply bottom-0;
        border-top: 1px solid $color-black-30;
        padding-top: 16px;
        padding-bottom: 16px;

        .btn-back {
            @apply font-OpenSans text-sm underline;
            padding-right: 5px;
            padding-left: 27px;
        }

        .btn {
            @apply font-OpenSans;
            padding-right: 32px;
            padding-left: 32px;
            font-size: 14px;
        }

        .col-span-9 {
            @apply text-right;
        }
    }
}

.slide-pane__overlay {
    z-index: $z-index-modal;
}

//border in modal
.borders {
    border-bottom: 1px solid $color-black-30;
}

.plain-modal-content {
    max-height: 700px;
    overflow-y: auto;

    &::-webkit-scrollbar {
        &-track {
            background: $color-white-100 !important;
        }

        &-thumb {
            &:vertical,
            &:horizontal {
                border: 2px solid $color-white-100 !important;
            }
        }
    }
}

//Size Table Modal
.size-table-modal {
    .ReactModal__Content {
        transform: translateY(-50%);
        margin: 0 20px;
        height: fit-content;
        inset: 50% 0 !important;

        .size-table-modal-header {
            position: relative;
            margin-bottom: 15px;
            border-bottom: 1px solid $color-black-30;
            padding-bottom: 15px;

            &-close {
                position: absolute;
                top: 50%;
                right: 0;
                margin-top: -15px;
            }
        }

        .size-table-modal-content {
            max-height: 700px;
            overflow-y: auto;

            @screen laptop {
                padding-bottom: 15px;
            }

            @media (max-width: $screen-mobile-only) {
                @include hide-scrollbars();
                max-height: 500px;
            }
        }

        @screen tablet {
            inset: unset !important;
            top: 50% !important;
            left: 45% !important;
            transform: translate(-50%, -45%);
        }

        @screen laptop {
            max-width: 750px;
            margin: auto;
        }
    }
}

.store-modal {
    .ReactModal__Content {
        top: 50% !important;
        transform: translateY(-50%);
        padding: 10px 20px !important;
        max-height: 120px;

        .store-info-modal {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}
