.c-page-foot.o-wrapper {
    .advantages-component {
        .o-wrapper {
            width: 100%;
            max-width: 1624px;
            margin: 0 auto;
            padding: 0 16px;

            @screen desktop {
                padding: 0 56px;
            }
        }
    }
}

.advantage-component {
    justify-content: space-between;
    min-height: 160px;

    @screen tablet {
        gap: 24px;
    }

    .group {
        flex: 0 0 48%;
        padding: 24px 12px;
        text-align: center;

        &:nth-child(-n+2) {
            margin-bottom: 16px;
        }

        .icon {
            font-size: 28px;
            display: inline-block;
            max-width: 28px;
            max-height: 28px;
        }

        .desc {
            font-family: sans-serif;
            font-size: 12px;
            line-height: 16px;
            max-width: 280px;
            margin: 0 auto;
            padding-top: 16px;
        }

        @screen tablet {
            flex: 1;
            padding: 20px;

            &:nth-child(-n+2) {
                margin-bottom: 0;
            }

            &:last-of-type {
                margin-right: 0;
            }

            .desc {
                font-size: 14px;
                line-height: 20px;
            }
        }

        @screen laptop {
            padding: 50px 40px;

            .desc {
                max-width: 100%;
            }
        }
    }
}
