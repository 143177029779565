.event-teaser-slider {
    .slick-slider {
        @screen tablet {
            margin: 0 -12px;
        }
    }

    .slick-slide {
        padding-right: 8px;

        @screen tablet {
            padding-right: 12px;
            padding-left: 12px;
        }
    }

    .group-header {
        margin-bottom: 35px;
    }

    .headline {
        height: 100%;
        margin: 0;

        h2 {
            margin-right: 0;
            margin-left: 0;
        }
    }

    .event-item {
        @apply relative;
        background-color: $color-black-90;

        &__links {
            padding-bottom: 10px;

            @screen tablet {
                padding-bottom: 20px;
            }
        }

        @screen desktop {
            &__links {
                display: none;
                opacity: 0;
            }

            &:hover {
                .event-item__links {
                    display: block;
                    opacity: 1;
                }

                .event-item__info {
                    margin-top: -68px;
                }
            }
        }

        &__info {
            @apply relative;
            z-index: 1;
            padding: 10px 10px 0;
            background-color: $color-black-90;
            color: $color-white-100;

            @screen tablet {
                padding: 20px 20px 0;
            }
        }

        &__date {
            @apply absolute left-0 top-0 inline-block text-white content-center;
            width: 72px;
            height: 72px;
            background: var(--color-current-theme);

            ol {
                padding-top: 10px;
            }

            li {
                @apply text-center;
                line-height: 1;
            }

            strong {
                font-size: 32px;
                font-weight: bold;
            }
        }

        &__img {
            width: 100%;
            height: 300px;
            object-fit: cover;

            @screen tablet {
                height: 360px;
            }
        }

        &__title {
            @apply font-openSans text-base;
            margin: 0 0 20px;
            @include line-clamp(2);
        }

        .btn {
            width: 100%;
            @apply inline-block;
            padding-right: 10px;
            padding-left: 10px;
        }
    }
}
