.blog-category-headline-bg {
    .blog-category-headline {
        margin-bottom: 0;
        font-family: $ff-sport2000;

        @screen laptop {
            font-size: 40px;
            line-height: 44px;
        }
    }

    &.py-comp {
        padding: 32px 0;

        @screen laptop {
            padding: 40px 0;
        }
    }

    .blog-category-list {
        display: flex;
        flex-wrap: wrap;
        margin-top: 24px;
        gap: 20px;

        @screen laptop {
            gap: 24px;
        }

        &-item {
            transition: calc(#{$transition-duration} * 2.5) all $transition-ease;
            border: 1px solid $color-black-70;
            cursor: pointer;
            padding: 17px 0;
            width: calc(100% / 2 - 10px);
            text-align: center;

            @screen laptop {
                width: calc(100% / 6 - 20px);
            }

            &-link {
                line-height: 18px;
                font-family: 'Roboto', sans-serif;
                font-weight: 500;
            }

            &:hover {
                border: 1px solid $color-white-100;
            }
        }
    }

}

.blog-category-wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: 24px;

    &-item {
        margin-bottom: 32px;

        &:hover {
            .blog-category-item {
                &-image {
                    &-img {
                        transform: scale(1.1);
                    }
                }

                &-link {
                    color: $color-red-60;
                }
            }
        }

        @screen tablet {
            flex: 0 0 49%;
            max-width: 49%;
        }

        @screen laptop {
            flex: 0 0 calc(100% / 3 - 24px);
            max-width: calc(100% / 3 - 24px);
            margin-bottom: 68px;
        }
    }

    @screen tablet {
        flex-direction: row;
        justify-content: space-between;
    }

    @screen laptop {
        margin-top: 40px;
    }

    .blog-category-item {
        &-image {
            overflow: hidden;

            &-img {
                height: 250px;
                object-fit: cover;

                @screen tablet {
                    height: 345px;
                }

                @screen desktop {
                    transform: scale(1);
                    @include transitionPrefixMultiple(calc(#{$transition-duration} * 5), transform);
                }
            }

            &-placeholder {
                position: relative;
                background: $color-black-20;
                width: 100%;
            }
        }

        &-tags {
            margin: 16px 0;

            &-text {
                transition: calc(#{$transition-duration} * 3) all $transition-ease;
                border-radius: 4px;
                background: $color-black-20;
                padding: 3px 12px;
                line-height: 16px;
                font-size: 12px;
                font-weight: 600;
            }

            @screen laptop {
                margin-bottom: 20px;
            }
        }

        &-headline {
            margin: 16px 0 12px 0;
        }

        &-description {
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 20px;
            white-space: nowrap;
            font-size: 14px;
            @supports (-webkit-line-clamp: 3) {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: initial;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }

            div {
                display: none;
            }
        }

        &-link {
            margin-top: 8px;
            text-decoration: underline;
            font-size: 14px;

            &:hover,
            &:active {
                color: $color-red-60;
            }
        }
    }
}

.blog-category-load-more {
    margin-bottom: 56px;
    text-align: center;

    .btn-load-more {
        width: 100%;
        min-width: 232px;

        @screen tablet {
            width: auto;
            padding-left: 40px;
            padding-right: 40px;
        }
    }

    @screen laptop {
        margin-bottom: 100px;
    }
}

.see-more-blogs-wrapper {
    margin: 20px 0 64px 0;

    .blog-category-wrapper {
        .blog-category-item-description {
            br {
                display: none;
            }
        }

        .blog-category-item-tags-text {
            margin-right: 12px;
            margin-bottom: 12px;
        }
    }

    @screen laptop {
        margin: 45px 0 100px 0;
    }
}
