.text-image-component {
    min-height: 480px;
    .content-wrapper {
        .group {
            margin: auto;
            padding: 40px 0;
            max-width: 284px;

            @screen desktop {
                max-width: 320px;
            }

            @media (max-width: $screen-mobile-only) {
                text-align: center !important;
            }
        }

        p {
            margin: 20px 0;
            line-height: 24px;
            font-size: 16px;
        }

        &--button {
            display: flex;
            flex-wrap: wrap;

            @media (max-width: $screen-mobile-only) {
                justify-content: center !important;
            }
        }

        .markdown--align {
            &__center {
                @apply text-center;

                .content-wrapper--button {
                    justify-content: center;
                }
            }

            &__left {
                @apply text-left;

                .content-wrapper--button {
                    justify-content: left;
                }
            }

            &__right {
                @apply text-right;

                .content-wrapper--button {
                    justify-content: right;
                }
            }
        }
    }


    .image-wrapper {
        .image-box {
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            height: 100%;
        }
    }

    .teaser-item-wrap {
        .teaser-video-bg {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            width: 100%;
            height: 100%;
        }
    }
}

//For Thank You Component
.s-node--checkoutFinished {
    .c-page-body {
        padding-bottom: 0;
    }
}

.thank-you-component {
    padding: 28px 16px 40px;

    @screen tablet {
        padding: 45px 0 100px;
    }

    .is-thank-you {
        .btn {
            padding-right: 40px;
            padding-left: 40px;
        }

        @media (max-width: $screen-mobile-only) {
            .headline {
                margin-right: 0;
                margin-left: 0;
            }

            .content-wrapper .group {
                padding: 24px 16px 32px;
                text-align: left !important;
            }

            .btn {
                display: block;
                width: 100%;
            }
        }

        .content-wrapper .group {
            max-width: unset;

            @screen tablet {
                margin-top: auto;
                margin-left: unset;
                padding-left: 48px;
                padding-right: 48px;
            }

            @screen desktop {
                padding-top: 78px;
                padding-bottom: 78px;
            }
        }
    }
}
