/*
    Document
*/

html,
body {
    font: 400 16px/1.5 $ff-openSans;
    color: $color-black-100;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overscroll-behavior: none;
    font-synthesis: none;
    -moz-font-feature-settings: 'kern';
    -webkit-tap-highlight-color: transparent;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    touch-action: manipulation;
}

body {
    float: left;
    overflow-x: hidden !important;
    overflow-y: scroll;
    width: 100%;
    height: 100%;
}

/*
    Document

    Global Styles
*/

* {
    box-sizing: border-box;
    scroll-behavior: var(--scroll-behavior);
}

/*
    Document

    User Selection & Focus
*/

::selection {
    color: $color-white-100;
    background: var(--color-current-theme);
}
