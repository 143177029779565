.contact-form {
    .dotted-bar {
        @media (max-width: $screen-mobile-only) {
            &:nth-of-type(odd) {
                @apply hidden;
            }
        }

        @screen tablet {
            &:nth-last-of-type(1) {
                right: -15%;
            }

            &:nth-last-of-type(2) {
                left: -10%;
            }
        }
    }
}