/* ==========================================================================
   # GRID
========================================================================== */

.o-grid {
    margin: 0;
    column-gap: 24px;
}

@mixin container {
    margin: 0 auto;
    max-width: 1624px;
    padding: 0 16px;

    @screen desktop {
        padding: 0 56px;
    }
}

.o-container {
    @include container;
}
