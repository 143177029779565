.brand-list-component {
    &-category {
        .brand-category {
            overflow-y: scroll;
            white-space: nowrap;
            @include hide-scrollbars();

            &-list {
                @screen laptop {
                    display: flex;
                    justify-content: space-between;
                }

                &-item {
                    display: inline-block;
                    margin-right: 8px;
                    border: 1px solid $color-black-20;
                    background: $color-black-10;
                    padding: 12px 0;
                    width: 40px;
                    text-align: center;
                    font-family: $ff-openSans;
                    font-size: 16px;
                    font-weight: 400;

                    @screen laptop {
                        margin-right: 0;
                        width: 35px;
                    }

                    @screen desktop {
                        width: 40px;
                    }

                    &:hover {
                        border: 1px solid $color-black-100;
                    }

                    &-disabled {
                        border: 1px solid $color-black-20;
                        background: $color-black-20;
                        color: $color-black-50;
                        pointer-events: none;
                    }
                }
            }

            @screen laptop {
                overflow-y: auto;
            }
        }
    }

    &-search {
        margin: 24px 0;

        .search-box--submit-icon {
            left: 0;
            right: auto;
        }

        .search-brand--input {
            background: $color-black-10 !important;
            padding: 0 50px;

            ~ label {
                left: 50px;
            }

            &:not(:placeholder-shown),
            &:focus {
                ~ label {
                    display: none;
                }
            }
        }

        @screen laptop {
            margin: 39px 0 32px 0;
        }
    }

    &-group {
        margin-top: 4px;

        .brand-list-wrap {
            display: flex;
            border-bottom: 1px solid $color-black-30;
            padding: 20px 0;

            &-headline {
                width: 105px;
            }

            &-content {
                width: auto;
                line-height: 32px;

                &-item {

                    text-transform: capitalize;

                    &:hover {
                        text-decoration: underline;
                    }
                }

                @screen laptop {
                    display: grid;
                    width: 100%;
                    grid-template-columns: repeat(3, 1fr);
                }
            }

            @screen laptop {
                padding: 24px 0;
            }
        }

        @screen laptop {
            margin-top: 16px;
        }
    }
}

.brand-list-page {
    [class*='/markdown'] {
        margin-top: 24px;

        @screen laptop {
            margin-top: 46px;
        }
    }
}

.brands-list-component {
    &.text-white {
        button {
            color: var(--color-black-100);
        }
    }
}
