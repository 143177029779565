.slider {
    padding: 0 16px;

    @screen tablet {
        padding: 0 50px;
    }

    @screen desktop {
        padding: 0;
    }

    .swiper-button {
        &-prev,
        &-next {
            position: absolute;
            top: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            padding: 0;
            cursor: pointer;
            transform: translate(0, -50%);
            border: none;
            outline: 0;
            @include transitionPrefixMultiple(calc(#{$transition-duration} * 5), transform);

            &:focus {
                outline: none;
            }

            &-disabled {
                pointer-events: none;
                opacity: .5;
            }

            &.icon-black path {
                fill: $color-black-100;
            }

            &.icon-white path {
                fill: $color-white-100;
            }

            &.icon-grey-dark path {
                fill: $color-black-80;
            }

            &.icon-grey-light path {
                fill: $color-black-10;
            }
        }

        &-prev {
            left: 0;

            @screen desktop {
                left: -50px;
            }

            @screen desktop {
                &:focus::before,
                &:hover::before {
                    opacity: 1;
                }

                &:hover {
                    @include translate(-5px, -50%);
                }
            }
        }

        &-next {
            right: 0;

            @screen desktop {
                right: -50px;
            }

            @screen desktop {
                &:focus::before,
                &:hover::before {
                    opacity: 1;
                }

                &:hover {
                    @include translate(5px, -50%);
                }
            }
        }

        &-disabled {
            display: none;
        }
    }
}
