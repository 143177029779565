.product-tiles {
    .dotted-bar {
        @media (max-width: $screen-mobile-only) {
            &:nth-of-type(odd) {
                @apply hidden;
            }
        }

        @screen tablet {
            &:nth-last-of-type(1) {
                right: -15%;
            }

            &:nth-last-of-type(2) {
                left: -10%;
            }
        }
    }

    .slider {
        .swiper-wrapper {
            padding-bottom: 1px;
        }

        .swiper-container {
            margin: 0 -1px;
            padding: 1px;

            @screen tablet {
                @apply p-2 -mx-2;
            }

            @screen laptop {
                @apply p-4 -mx-4;
            }

            .swiper-slide {
                height: auto;

                &:first-of-type {
                    .product-teaser {
                        @apply border-l;
                    }
                }

                .product-teaser {
                    @media (max-width: $screen-mobile-only) {
                        @apply border-t;
                    }
                }
            }
        }

        .swiper-button-prev,
        .swiper-button-next {
            @apply z-10;
        }
    }
}

.product-tiles-algolia-wrapper {
    margin: 0 16px !important;

    @screen laptop {
        width: 100%;
        max-width: 1624px;
        margin: auto !important;
    }

    .product-teaser-list {
        @screen tablet {
            display: flex;
            flex-wrap: wrap;
        }

        .product-teaser {
            @screen tablet {
                flex: 0 0 calc(33.33% - 12px);
                max-width: calc(33.33% - 12px);
            }

            @screen laptop {
                flex: 0 0 calc(20% - 16px);
                max-width: calc(20% - 16px);
            }
        }
    }

    .slider {
        .swiper-button-next {
            right: -20px;

            @screen tablet {
                right: 0;
            }

            @screen laptop {
                right: -50px;
            }
        }

        .swiper-button-prev {
            left: -20px;

            @screen tablet {
                left: 0;
            }

            @screen laptop {
                left: -50px;
            }
        }
    }

    @media (max-width: $screen-under-tablet) {
        .product-teaser {
            > a {
                padding: 0 10px;
            }
        }
    }
}
