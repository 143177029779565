.search {
    &-box {
        background: $color-white-100;

        @media (max-width: $screen-under-tablet) {
            left: 0;
            height: 60px;
            box-shadow: $shadow-header-mobile;
        }

        @screen laptop {
            will-change: width, right;
            transition: calc(#{$transition-duration} * 5) width $transition-ease, calc(#{$transition-duration} * 5) right $transition-ease;
            width: 450px;

            @screen desktop {
                width: 640px;
            }

            @screen desktopxx {
                width: 924px;
            }

            &::before,
            &::after {
                position: absolute;
                top: 0;
                width: 0;
                height: 48px;
                content: '';
                transition: calc(#{$transition-duration} * 5) opacity $transition-ease;
                opacity: 0;
                background: $color-white-100;
            }

            &::before {
                left: -50vw;
            }

            &::after {
                right: -50vw;
            }
        }

        @screen desktopx {
            &::before {
                display: none;
            }
        }

        .input--wrapper {
            z-index: 30;

            .search-box--input {
                padding-left: 48px;
            }

            @media (max-width: $screen-under-tablet) {
                flex: 1;
                margin: 8px 16px;
            }

            @screen laptop {
                width: 100%;
                transition: calc(#{$transition-duration} * 5) width $transition-ease;
                will-change: width;
            }
        }

        &--input {
            &[type='search'] {
                @media (max-width: $screen-under-tablet) {
                    height: 44px;
                    padding-left: 145px;
                    background: $color-black-20;
                }

                &:not(:placeholder-shown),
                &:focus {
                    ~ label {
                        display: none;
                    }

                    ~ .search-box--clear {
                        animation: calc(#{$transition-duration} * 5) $transition-ease forwards scaleAndfadeIn;
                    }
                }

                ~ label {
                    left: 48px;
                    white-space: nowrap;

                    @media (max-width: $screen-under-tablet) {
                        top: 10px;
                        left: 145px;
                    }

                    @screen desktopx {
                        display: initial;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        font-size: 14px;
                    }
                }

                ~ .input--border {
                    @media (max-width: $screen-under-tablet) {
                        top: 43px;
                    }

                    @screen laptop {
                        display: none;
                    }

                    @screen desktopx {
                        display: initial;
                    }
                }
            }
        }

        &--submit-icon.btn {
            position: absolute;
            top: 0;
            padding-right: 16px;
            padding-left: 16px;

            @media (max-width: $screen-under-tablet) {
                right: 0;
                height: 44px;
            }

            @screen desktopx {
                pointer-events: auto;
            }
        }

        &--form {
            @media (max-width: $screen-under-tablet) {
                display: flex;
            }
        }

        &--submit,
        &--close {
            @screen laptop {
                position: absolute;
                top: 0;
                right: 0;
                pointer-events: none;
                opacity: 0;
            }
        }

        &--submit {
            @screen laptop {
                z-index: 20;
                width: 148px;
                transform: scale3d(.75, .75, .75);
                transition: calc(#{$transition-duration} * 5) right $transition-ease;
                will-change: right;
            }
        }

        &--close {
            @media (max-width: $screen-under-tablet) {
                font-size: 14px;
                margin: 8px 16px 8px 0;
                text-decoration: underline;
            }

            @screen laptop {
                height: 100% !important;
                right: -34px;
                z-index: 10;
                transform: scale3d(0, 0, 0);
            }
        }

        &--clear.btn {
            position: absolute;
            top: 0;
            right: 0;
            height: 44px;
            transform: scale3d(0, 0, 0);
            opacity: 0;
        }

        &.is--open {
            @screen laptop {
                &::before,
                &::after {
                    width: 50vw;
                    opacity: 1;
                }
            }

            .dropdown-categories {
                @screen laptop {
                    animation: calc(#{$transition-duration} * 2.5) $transition-ease calc(#{$transition-duration} * 3) forwards scaleAndfadeIn;
                    pointer-events: auto;
                }
            }

            .search-box {
                &--input {
                    &[type='search'] {
                        padding-left: 145px;

                        @screen laptop {
                            padding-left: 170px;
                            opacity: 1;
                            background: $color-black-20;

                            ~ .input--border {
                                display: block;
                            }
                        }

                        ~ label {
                            left: 145px;

                            @screen laptop {
                                left: 170px;
                            }
                        }
                    }
                }

                &--submit {
                    @screen laptop {
                        right: 56px;
                        animation: calc(#{$transition-duration} * 2.5) $transition-ease calc(#{$transition-duration} * 3) forwards scaleAndfadeIn;
                        pointer-events: auto;

                        &-icon.btn {
                            pointer-events: auto;
                        }

                        &-icon-animation {
                            left: 0;
                            animation: calc(#{$transition-duration} * 2.5) $transition-ease calc(#{$transition-duration} * 3) forwards swingViewport;
                            animation-iteration-count: unset;
                        }

                        @keyframes swingViewport {
                            from {
                                transform: translateX(0);
                                left: 0;
                            }
                            to {
                                left: 100%;
                                transform: translateX(-100%);
                            }
                        }
                    }
                }

                &--close {
                    @screen laptop {
                        animation: calc(#{$transition-duration} * 2.5) $transition-ease calc(#{$transition-duration} * 3) forwards scaleAndfadeIn;
                        pointer-events: auto;
                    }
                }
            }
        }
    }

    &-hits {
        position: absolute;
        top: 59px;
        right: 0;
        left: 0;
        transform: scale3d(1, 0, 1);
        transform-origin: 50% 0;
        animation: calc(#{$transition-duration} * 5) $transition-ease forwards scaleAndfadeIn;
        opacity: 0;

        @media (max-width: $screen-under-tablet) {
            bottom: 0;
            display: flex;
            flex-direction: column;
            padding: 25px 16px 0;
        }

        @screen laptop {
            z-index: 1;
            max-height: calc(#{$viewport-height} - 167px);
            overflow: auto;
            //padding: 33px 0 32px;
            box-shadow: 50vw 0 0 0 $color-white-100, -50vw 0 0 0 $color-white-100;
            background: $color-white-100;
            transition: calc(#{$transition-duration} * 4) top $transition-ease;

            .is--scrolled & {
                top: 56px;
                max-height: calc(#{$viewport-height} - 112px);
            }
        }

        @screen desktop {
            padding: 40px 16px;
            max-width: 1544px;
            margin: auto;
        }

        &--headline {
            @media (max-width: $screen-under-tablet) {
                font-size: 18px;
            }
        }

        &-count {
            font-size: 14px;
            line-height: 20px;
            color: $color-black-40;
        }

        &,
        &--list {
            scrollbar-color: $color-black-20 $color-white-100;

            &::-webkit-scrollbar {
                width: 6px;

                &-track {
                    background: $color-white-100;
                }

                &-thumb {
                    &:vertical,
                    &:horizontal {
                        border: 0;
                    }
                }
            }
        }

        &--list {
            display: grid;
            grid-auto-flow: row;

            @media (max-width: $screen-under-tablet) {
                margin: 0 -16px;
            }

            @screen laptop {
                gap: 24px 24px;
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            }

            &-empty {
                text-align: left;
            }
        }

        &--all-results {
            display: flex;

            @media (max-width: $screen-under-tablet) {
                position: relative;
                margin-top: auto;

                &::after {
                    position: absolute;
                    top: -48px;
                    right: 0;
                    left: 0;
                    height: 48px;
                    content: '';
                    pointer-events: none;
                    background: linear-gradient(to bottom, transparent 0%, $color-white-100 100%);
                }
            }

            @screen laptop {
                margin-top: 24px;
                justify-content: center;
            }

            .btn {
                @media (max-width: $screen-under-tablet) {
                    width: 100%;
                }

                @screen laptop {
                    font-size: 14px;
                    text-decoration: underline;
                }
            }
        }

        .search-hits-wrapper {
            @media (max-width: $screen-under-tablet) {
                &--left {
                    display: flex;
                    flex-direction: column;

                    .search--category {
                        order: 2;
                    }

                    .search--suggest {
                        order: 1;
                    }

                    .search--brand {
                        order: 3;
                        padding-top: 10px;
                    }
                }
            }

            @screen laptop {
                display: flex;
                justify-content: space-between;

                &--left {
                    flex: 0 0 30%;
                    max-width: 30%;
                    //padding-top: 30px;
                }

                &--right {
                    flex: 0 0 68%;
                    max-width: 68%;
                    //padding-top: 30px;
                }
            }
        }
    }

    &-hit {
        display: flex;
        flex: 0 0 50%;
        flex-direction: column;
        padding: 16px;
        border: 1px solid $color-black-20;

        @screen tablet {
            flex: 0 0 33.33%;
        }

        @screen laptop {
            flex: 0 0 auto;
            padding: 24px 24px 16px;
            transition: calc(#{$transition-duration} * 5) border $transition-ease;

            @media (hover: hover) {
                &:hover {
                    border: 1px solid $color-black-100;
                }
            }
        }

        &--image {
            width: 100%;
            height: 262px;
            margin-bottom: 16px;
        }

        &--placeholder {
            width: 100%;
            margin-bottom: 16px;
            padding-top: 0;
            background: $color-black-10;
        }

        &--brand {
            font-size: 14px;
            font-weight: 700;
            text-transform: uppercase;
        }

        &--name {
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 2px;
        }

        &--price {
            font-weight: 700;
            margin-top: auto;
            margin-bottom: 2px;

            span {
                font-size: 14px !important;
                line-height: 24px;
            }
        }
    }
}

.search--brand {
    @screen laptop {
        padding-top: 24px;
    }
}

// Search empty
.search--empty {
    padding: 0 0 15px 0;

    @screen laptop {
        padding: 15px 0;
    }

    &-all-product {
        font-weight: 700;
        margin-bottom: 0;
        text-align: center;
    }

    &-product {
        padding: 12px 8px;
        background-color: $color-absolute-blue-background;
    }

    &-icon {
        &,
        * {
            fill: $color-absolute-blue-60;
        }
    }

    p {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 0;
        color: $color-absolute-blue;
    }
}

.search--blog {
    margin-bottom: 32px;
    padding-bottom: 32px;

    @media (max-width: $screen-under-tablet) {
        margin-top: 20px;
    }

    .search-hits--list {
        &.blog-category-wrapper {
            margin: 0;

            @screen laptop {
                display: grid;
                gap: 16px 24px;
                grid-template-columns: 1fr 1fr;
            }

            .blog-category-item {
                display: flex;
                justify-content: space-between;
                height: 105px;

                @screen laptop {
                    height: 160px;
                }

                .search-hit--placeholder {
                    flex: 40%;
                    max-width: 40%;

                    @screen laptop {
                        height: 155px;
                    }
                }

                &-image {
                    flex: 40%;
                    max-width: 40%;
                    object-fit: cover;

                    @screen laptop {
                        height: 155px;
                    }
                }

                &-content {
                    flex: 57%;
                    max-width: 57%;
                    margin: auto 0 auto 20px;

                    .blog-category-item-tags {
                        margin: 0 0 8px 0;
                    }

                    .blog-category-item-headline {
                        font-family: $ff-sport2000;
                        font-size: 18px;
                        font-weight: bold;
                        line-height: 22px;
                        margin: 0 0 8px 0;

                        @screen laptop {
                            margin: 8px 0;
                            font-size: 20px;
                            line-height: 24px;
                        }
                    }

                    @screen laptop {
                        flex: auto;
                        max-width: unset;
                    }
                }

                @media (max-width: $screen-under-tablet) {
                    margin-bottom: 16px;
                }
            }
        }
    }
}

.search--product {
    padding-top: 20px;

    @screen laptop {
        margin-bottom: 32px;
        padding-top: 30px;
    }

    .search-hits--list {
        &.search-product--list {
            margin: 0;
            @screen laptop {
                grid-template-columns: 1fr 1fr 1fr;
            }

            .search-product-hit {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-bottom: 8px;

                .search-hit--placeholder {
                    flex: 30% 1;
                    max-width: 30%;

                    @screen laptop {
                        flex: 1;
                        max-width: 50%;;
                        height: 115px;
                    }
                }

                &--image {
                    flex: 30%;
                    max-width: 30%;

                    @screen laptop {
                        flex: 1;
                        max-width: 50%;;
                        height: 115px;
                    }
                }

                &-content {
                    flex: 67%;
                    max-width: 67%;
                    margin: auto 0;

                    @screen laptop {
                        flex: 1;
                        margin-left: 20px;
                        max-width: unset;
                    }
                }

                @screen laptop {
                    margin-bottom: 16px;
                }
            }
        }
    }
}

.search--common {
    .search-hits--headline {
        @media (max-width: $screen-under-tablet) {
            display: none;
        }
    }

    &-wrapper {
        flex-direction: column;
        margin: 0;

        &-content {
            display: flex;
            margin-bottom: 20px;

            &-name {
                font-size: 16px;
                line-height: 20px;
                margin-left: 20px;
            }

            @screen laptop {
                margin-bottom: 0;
            }
        }

        &-category {
            font-size: 10px;
            line-height: 20px;
            top: 5px;
            color: $color-black-40;
        }

        @screen laptop {
            grid-template-columns: 1fr;
        }
    }
}

.search--suggest {
    @screen laptop {
        padding-top: 24px;
    }

    .search-hits--list {
        .search--suggest-content--item {
            position: relative;

            &:before {
                position: absolute;
                left: 0;
                width: 20px;
                height: 20px;
                content: '';
                background-image: url('../../icons/sport2000-icons/search.svg');
                background-repeat: no-repeat;
                background-size: cover;
            }

            .ais-Highlight {
                margin-left: 40px;
            }
        }
    }
}

.search--category {
    @screen laptop {
        padding-top: 30px;
    }

    .search--category-content {
        position: relative;
        display: flex;
        flex-direction: column;

        a {
            display: block;
            margin-bottom: 15px;
            margin-left: 40px;

            @media (max-width: $screen-mobile-only) {
                max-width: 70%;
            }

            &:before {
                position: absolute;
                left: 0;
                width: 20px;
                height: 20px;
                content: '';
                background-image: url('../../icons/sport2000-icons/categories.svg');
                background-repeat: no-repeat;
                background-size: cover;
            }

            &:last-child {
                margin-bottom: 0;
            }

            em {
                font-weight: bold;
            }
        }
    }
}

.search--store {
    order: 4;

    @screen laptop {
        padding-top: 30px;
    }

    &-content--item {
        @media (max-width: $screen-mobile-only) {
            svg {
                width: auto !important;
            }
            &-name {
                width: 70%;
                max-width: 70%;
            }
        }
    }
}

//Highlight CSS
.ais-Highlight {
    em {
        font-weight: bold;
    }

    &-highlighted {
        font-weight: 700;
    }
}

.search--link {
    &-all {
        font-size: 14px;
        line-height: 20px;
        text-decoration: underline;
    }
}

// first search layout
.search--hits-first-layout {
    padding: 32px 0;

    .search--layout {
        @media (max-width: $screen-under-tablet) {
            margin-bottom: 20px;

            &--content {
                display: flex;
                overflow-x: scroll;
                overflow-y: hidden;
                gap: 8px;
                @include hide-scrollbars();

                &-wrap {
                    display: flex;
                    padding: 0 16px;
                    border: 1px solid $color-black-30;
                    border-radius: 2px;
                }
            }
        }

        @screen laptop {
            flex: 0 0 calc((100% / 3) - 24px);
            max-width: calc((100% / 3) - 24px);
            margin-right: 24px;

            &:last-of-type {
                margin-right: 0;
            }
        }
    }

    @screen laptop {
        display: flex;
        padding: 48px 0;
        margin: auto;
        max-width: 1244px;
        width: calc(60vw - 25px);
    }
}

.search--layout {
    &--content {
        &-wrap {
            > a {
                line-height: 36px;
                overflow: hidden;
                white-space: nowrap;
            }

            &-item {
                line-height: 36px;
                width: 100%;
                height: 36px;
                text-align: left;

                span {
                    display: inline-block;
                    overflow: hidden;
                    width: 100%;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}

// Search Page
.search-page {
    padding-top: 0;

    @screen tablet {
        padding: 5% 10% 0;
    }

    .search-page-headline {
        margin-bottom: 50px;
        text-align: center;

        @screen tablet {
            margin-bottom: 80px;
        }
    }

    &--wrapper {
        display: flex;
        flex-direction: column;

        @screen tablet {
            flex-direction: row;
        }
    }

    .search--layout {
        display: flex;
        flex: 0 1 33.33%;
        flex-direction: column;
        margin-bottom: 30px;

        @screen tablet {
            margin-bottom: 0;
        }
    }
}

//Dropdown Categories
.dropdown-categories {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    position: absolute;
    z-index: 35;
    top: 8px;
    left: 0;
    height: 44px;
    padding: 0 16px;

    @screen laptop {
        opacity: 0;
    }

    &.is--search-page {
        @screen laptop {
            opacity: 1;
        }
    }

    &.is--open {
        width: 100%;
        max-width: inherit;

        .filter-panel-select,
        .filter-buttons-toggle {
            max-width: inherit !important;
        }
    }

    @screen laptop {
        max-width: 180px;
        width: 180px;
        height: 48px;
        top: 0;
        left: 0;
        padding: 0;
    }

    .filter-panel-select {
        max-width: 130px;
        height: 100%;

        @screen laptop {
            max-width: 180px;
        }

        .filter-buttons-toggle {
            width: 130px;
            max-width: 130px;
            height: 100% !important;
            border: 1px solid $color-black-30;

            @screen laptop {
                width: 150px;
                max-width: 150px;
            }

            &.theme--green {
                border: 1px solid $color-absolute-green-60;
            }

            &.theme--blue {
                border: 1px solid $color-absolute-blue-60;
            }

            span {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            &-arrow {
                margin-left: 10px;
            }

            &.is--open {
                width: 100%;
                border: 1px solid $color-black-100;

                @screen laptop {
                    width: 150px;
                    max-width: 150px;
                }
            }
        }
    }

    .dropdown-categories-wrap {
        overflow-y: scroll;
        max-height: 220px;
        border-bottom: 1px solid $color-black-100;
        background-color: $color-black-10;

        @screen laptop {
            width: 350px;
            max-width: 350px;
        }

        &-list {
            &-item {
                font-size: 16px;
                padding: 10px 11px;
                cursor: pointer;

                &:hover {
                    background-color: $color-black-20;
                }
            }
        }
    }
}

.s-node--search {
    .search-box--submit-icon {
        right: 0;
    }
}
