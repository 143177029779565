.disable-overlay {
    pointer-events: none;
}

.teaser-item-block {
    margin-bottom: 8px;
}

.masonry-grid {
    display: flex;
    width: auto;

    &--center {
        align-items: center;
    }

    &--has-gap {
        margin-left: -10px;
    }
}

.masonry-grid-column {
    background-clip: padding-box;

    &--has-gap {
        padding-left: 10px;
    }
}

.masonry-item {
    &--has-gap {
        margin-bottom: 10px;
    }
}

.ytp-large-play-button {
    & .svg {
        width: 100px !important;
        height: 100px !important;
        border-radius: 50% !important;
        background-color: white !important;
    }
}
