.minimal-header-component {
    background: $color-black-100;
    color: $color-white-100;

    .header-minimal {
        display: flex;
        align-items: center;
        margin: 0 auto;
        padding: 2px 0;
        width: 100%;

        @screen laptop {
            padding: 20px 0;
        }

        @screen desktop {
            position: relative;
            max-width: 1001px;
        }

        .header-minimal-left {
            display: flex;
            align-items: center;

            @screen desktop {
                position: absolute;
                left: -20%;
            }

            .btn-minimal-header {
                padding: 0;
                text-transform: capitalize;
                font-size: 14px;

                span {
                    text-transform: none;

                    &:last-of-type {
                        display: none;

                        @screen desktop {
                            display: block;
                            font-size: 14px;
                            font-weight: 400;
                            letter-spacing: 0px;
                            @include link-hover--underline;
                        }
                    }
                }

                svg {
                    float: left;
                    margin-top: 2px;

                    @screen laptop {
                        width: 16px;
                        height: 16px;
                        margin-right: 10px;
                    }
                }
            }
        }

        .header-minimal-right {
            padding: 10px 0;
            width: 100%;

            .header-minimal-logo {
                display: flex;
                align-items: center;
                width: 100%;

                &-sport {
                    margin-left: 12px;

                    img {
                        width: 50px;
                    }

                    @screen tablet {
                        img {
                            width: 60px;
                        }
                    }

                    @screen laptop {
                        margin-left: 30px;

                        img {
                            width: 80px;
                        }
                    }

                    @screen desktop {
                        margin-left: 0px;
                    }
                }

                &-absolute-wrap {
                    display: flex;
                    justify-content: flex-end;
                    width: 100%;
                }

                &-absolute {
                    margin-left: 12px;

                    img {
                        width: 220px;
                    }

                    @screen laptop {
                        margin-left: 30px;

                        img {
                            width: 200px;
                        }
                    }

                    @screen desktop {
                        margin-right: 0px;
                        margin-left: 30px;

                        img {
                            width: 240px;
                        }
                    }
                }
            }

            @screen laptop {
                padding: 0;
            }
        }
    }
}
