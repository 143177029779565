.storefinder {
    &--iframe {
        @apply -mt-6;

        @media (max-width: $screen-under-tablet) {
            height: calc(#{$viewport-height} - 96px);
        }

        @screen laptop {
            height: calc(#{$viewport-height} - 28px);
            min-height: 640px;
            margin-bottom: -196px;
        }
    }
}