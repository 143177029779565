.gallery-slider {
    .slick-slider {
        padding: 0 16px;

        @screen laptop {
            padding: 0;
        }
    }

    .hide-arrow {
        .slick-arrow {
            display: none !important;
        }
    }

    .thumbnail-slider-wrap {
        @apply mx-auto cursor-pointer;

        .slick-prev {
            left: -60px;
            display: block !important;

            &::before {
                width: 16px;
                height: 16px;
            }
        }

        .slick-track {
            display: flex;
            justify-content: center;
            margin: 10px 0;
        }

        .slick-next {
            right: -50px;
            display: block !important;

            &::before {
                width: 16px;
                height: 16px;
            }
        }

        .slick-slide {
            width: 65px !important;
            height: 65px !important;
            opacity: 0.4;

            .gallery-slider-img {
                img {
                    height: 100%;
                    object-fit: cover;
                }
            }

            &:not(:first-of-type) {
                margin: 0 0 0 10px;
            }

            &.slick-current {
                outline: $color-black-100 solid 1px !important;
                opacity: 1;
            }

            div {
                @apply h-full;
            }
        }

        .slick-track {
            @apply mx-auto;
        }
    }
}

.lg-download::after {
    transition: linear 300ms;
    opacity: 0.8;
    color: #fff;
}

.lg-download {
    &:hover {
        &::after {
            opacity: 1 !important;
        }
    }
}

.lg-next {
    background-color: transparent;

    & > svg {
        width: 18px;
        height: 30px;

        & > path {
            opacity: 0.8;
            fill: #fff;
        }

        &:hover {

            & > path {
                transition: linear 300ms;
                opacity: 1;
            }
        }
    }
}

.lg-counter {
    color: #fff
}

.lg-prev {
    background-color: transparent;

    & > svg {
        width: 18px;
        height: 24px;

        & > path {
            opacity: 0.8;
            fill: #fff;
        }

        &:hover {

            & > path {
                transition: linear 300ms;
                opacity: 1;
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .lg-next {
        background-color: rgba(0, 0, 0, 0.45);
    }
    .lg-prev {
        background-color: rgba(0, 0, 0, 0.45);
    }
}

.gallery-video {
    .teaser-item-block {
        height: 200px;

        @screen tablet {
            height: 350px;
        }

        @screen laptop {
            height: 500px;
        }

        .teaser-item-wrap, .cms-element-youtube-video {
            height: 100%;
        }
    }
}
