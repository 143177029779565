.btn {
    @apply font-bold py-2 px-4 rounded;

    &:disabled {
        @apply bg-neutral-400;

        &:hover {
            @apply bg-neutral-400;
        }
    }
}

.btn-primary {
    @apply bg-primary-600 text-white;

    &:hover {
        @apply bg-primary-800;
    }
}

// Below classes should be themed as well
.btn-blue {
    @apply bg-blue-500 text-white;

    &:hover {
        @apply bg-blue-700;
    }
}

.btn-indigo {
    @apply bg-indigo-500 text-white;

    &:hover {
        @apply bg-indigo-800;
    }
}

.btn-gray {
    @apply bg-gray-400;

    &:hover {
        @apply bg-indigo-700 text-white;
    }
}

.btn-pill {
    @apply btn;
    @apply rounded-full;

    &:disabled {
        @apply bg-neutral-400;

        &:hover {
            @apply bg-neutral-400;
        }
    }
}

.btn-outline {
    @apply btn bg-transparent font-semibold border-2;

    &:hover {
        @apply border-transparent;
    }
}

.btn-outline-blue {
    @apply border-blue-500;

    &:hover {
        @apply bg-blue-500 text-white;
    }
}

.btn-outline-gray {
    @apply border-gray-400;

    &:hover {
        @apply bg-gray-400 text-white;
    }
}

.btn-outline-black {
    @apply border-neutral-900;

    &:hover {
        @apply bg-neutral-400 text-white;
    }
}

.btn-elevated {
    @apply btn bg-background-primary border border-gray-400 shadow;

    &:hover {
        @apply bg-gray-100;
    }
}
