/* ==========================================================================
   #BUTTON
========================================================================== */
.btn-link {
    @apply underline;
    @include transitionPrefixMultiple(calc(#{$transition-duration} * 5), all);

    @media (hover: hover) {
        &:hover {
            @apply text-redsport-60;
        }
    }

    &:active,
    &:focus {
        @apply text-redsport-80;
    }
}

.is-hover--red {
    @include transitionPrefixMultiple(calc(#{$transition-duration} * 5), all);

    &:hover {
        svg {
            &:not(.no-overrides) {
                &,
                * {
                    fill: $color-red-60 !important;
                }
            }
        }
    }
}

.read-more-link {
    @apply relative inline-block;
    padding-right: 27px;

    @screen tablet {
        padding-right: 33px;
    }

    .text {
        @apply font-OpenSans text-base;
        text-transform: none;
        font-weight: 400;
        @include transitionPrefixMultiple(calc(#{$transition-duration} * 5), all);
        @include link--underline;

        &::after {
            background: $color-black-100;
        }

        @screen tablet {
            @apply text-sm;
        }
    }

    .icon-arrow-r {
        @apply absolute;
        top: 3px;
        @apply right-0;
        @include transitionPrefixMultiple(calc(#{$transition-duration} * 5), right);
    }

    @screen tablet {
        &:hover {
            .text {
                @apply text-redsport-60;

                &::after {
                    @apply bg-redsport-60;
                }
            }

            .icon-arrow-r {
                right: -12%;
            }
        }
    }
}


.btn-back {
    @apply relative inline-block;
    padding-left: 27px;

    @screen tablet {
        padding-left: 33px;
    }

    .text {
        @apply font-OpenSans text-base;
        @include transitionPrefixMultiple(calc(#{$transition-duration} * 5), all);
        @include link--underline;
        text-transform: none;
        font-weight: 400;

        &::after {
            background: $color-black-100;
        }

        @screen tablet {
            @apply text-sm;
        }
    }

    .icon-arrow-l {
        @apply absolute left-0 top-0;
        @include transitionPrefixMultiple(calc(#{$transition-duration} * 5), left);
    }

    @screen tablet {
        &:hover {
            .text {
                @apply text-redsport-60;

                &::after {
                    @apply bg-redsport-60;
                }
            }

            .icon-arrow-l {
                left: -12%;
            }

            svg {
                &:not(.no-overrides) {
                    &,
                    * {
                        fill: $color-red-60 !important;
                    }
                }
            }
        }
    }
}
