.mini-cart,
.mini-cart--temp {
    position: absolute;
    z-index: 1010;
    top: 100%;
    right: 0;
    width: 400px;
    cursor: auto;
    pointer-events: none;
    opacity: 0;
    color: $color-black-100;
    background-color: $color-white-100;
    box-shadow: $shadow-mini-cart;
    @include transitionPrefixMultiple(calc(#{$transition-duration} * 5), opacity);

    &--empty-state {
        font-family: $ff-sport2000;
    }

    &--wrapper {
        @screen laptop {
            &:hover {
                .mini-cart {
                    opacity: 1;
                    pointer-events: auto;
                }
            }

            &.active {
                .mini-cart--temp {
                    opacity: 1;
                    pointer-events: auto;

                    + .mini-cart {
                        opacity: 0;
                        pointer-events: none;
                    }
                }
            }
        }
    }

    &--close {
        position: relative;
        margin-bottom: 16px;

        span {
            font-size: 18px;
            font-weight: bold;
            line-height: 1;
            @apply font-Sport2000;
        }

        button[type='button'] {
            position: absolute;
            top: 2px;
            right: 0;
            width: 16px;
            height: 16px;
        }
    }

    &--empty-state {
        font-size: 18px;
        font-weight: bold;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        padding: 32px 0;

        h5 {
            margin: 16px 0 0;
        }
    }

    &--list-item {
        overflow-y: auto;
        max-height: 350px;
        padding: 20px 20px 0;
        border-bottom: 1px solid $color-black-30;
        @include hide-scrollbars();
    }

    &--item {
        display: flex;
        margin-bottom: 20px;

        .mini-cart--item-image {
            display: flex;
            width: 300px;
            max-width: 100px;
            padding: 5px;
            border: 1px solid $color-black-20;
        }

        .mini-cart--item-info {
            padding-left: 16px;

            h5 {
                font-size: 16px;
                line-height: 18px;
                margin: 0 0 4px;
                cursor: pointer;
                color: $color-black-100;
            }

            div {
                @apply font-OpenSans;
                font-size: 12px;
                line-height: 20px;
                margin: 0;
                color: $color-black-50;

                &.is--price {
                    font-size: 14px;
                    margin: 4px 0 0;
                    color: $color-black-100;
                }

                .is--discount-price {
                    color: $color-red-60;
                }
            }
        }
    }

    &--loading {
        &,
        * {
            fill: var(--color-current-theme);
        }
    }

    &--summary {
        padding: 17px 16px 24px;

        .mini-cart--actions {
            display: flex;

            button {
                display: flex;
                justify-content: center;
                width: calc(50% - 8px);

                &:first-child {
                    margin-right: 16px;
                }

                span span {
                    margin: 4px 0 0 0;
                }

                &.btn-default {
                    color: $color-white-100;
                    background-color: $color-black-80;
                }

                &.btn-secondary {
                    color: $color-black-100;
                    border-color: $color-black-100;
                }
            }
        }

        p {
            @apply font-OpenSans;

            &.is--save {
                font-size: 14px;
                line-height: 24px;
                display: flex;
                margin: 0 0 8px;
                color: $color-red-60;

                span {
                    &:last-child {
                        display: flex;
                        flex-grow: 1;
                        justify-content: flex-end;
                    }
                }
            }

            &.is--total {
                font-size: 14px;
                font-weight: bold;
                line-height: 24px;
                position: relative;

                .is--sub-total {
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 16px;
                    color: $color-black-70;
                }

                > span {
                    &:last-child {
                        position: absolute;
                        top: 0;
                        right: 0;
                    }
                }
            }
        }
    }
}
