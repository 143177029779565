.loading-full-screen:after {
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    content: ' ';
    animation: loading-full-screen 1.5s linear infinite;
    border: 6px solid #667eea;
    border-color: #667eea transparent #667eea transparent;
    border-radius: 50%;
}

@keyframes loading-full-screen {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
