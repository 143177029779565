/* ==========================================================================
   #Form Newsletter
========================================================================== */
.newsletter {
    @include full-width-wrapper();
    padding: 32px 16px;
    color: $color-white-100;

    @screen tablet {
        padding: 35px 16px 48px;
    }

    @screen desktop {
        padding: 35px 56px 48px;
    }

    .headline {
        @screen tablet {
            margin-bottom: 8px;
        }
    }

    .wrapper {
        max-width: 1512px;
        margin: 0 auto;
    }

    .btn-search-wrap {
        padding-top: 12px;

        @screen tablet {
            min-width: 140px;
            padding-top: 0;
            padding-left: 20px;
        }

        .btn {
            width: 100%;
            letter-spacing: 1px;
        }
    }

    .form-input-newsletter {
        @apply font-openSans;
        position: relative;
        min-height: 80px;

        .input--border {
            background: transparent;
        }

        .form-input {
            @apply rounded-none shadow-none;

            &:focus {
                ~ label {
                    transform: translate(0, 0);
                    opacity: 0;
                }
            }

            &:not(:placeholder-shown) {
                ~ label {
                    opacity: 0;
                }
            }
        }

        .btn-search-wrap {
            .btn {
                span {
                    margin: auto;
                }
            }
        }
    }

    .dotted-bar__bottom {
        background-color: $color-black-100;
    }
}
