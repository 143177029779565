.pdp-campaign {
    @screen laptop {
        margin: 50px 32px;
    }

    &-brand-logo {
        width: 120px;

        @screen laptop {
            width: 150px;
        }

        &.brand-logo {
            top: 20px;
            left: 0;
        }

        &.brand-logo-bottom {
            bottom: 20px;
        }

        &.brand-logo-top {
            top: 20px;
        }
    }
}

//Style button based on theme
.absolute-teamsport--theme {
    .pdp-campaign {
        .btn {
            background-color: $color-absolute-green;
        }
    }
}

.absolute-run--theme {
    .pdp-campaign {
        .btn {
            background-color: $color-absolute-blue;
        }
    }
}
