// Common Text Box Blog
.box-blog-short {
    background: $color-white-100;

    &-logo {
        margin-bottom: 16px;
        width: 200px;
        height: 85px;

        @screen laptop {
            margin-bottom: 32px;
            width: 296px;
            height: 125px;
        }

        &-img {
            width: auto;
        }
    }
}

// Blog Short on Desktop
.blog-short-desc-desktop {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    .box-blog-short {
        margin: 69px 0;
        padding: 52px 40px;
        max-width: 740px;

        &-no-bg {
            margin: 32px 0;
            padding: 0;
        }
    }
}

// Blog Short on Mobile
.blog-short-desc-mobile {
    &-bg {
        margin-bottom: 32px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 200px;

        @screen tablet {
            min-height: 400px;
        }

        &-empty {
            margin-top: 32px;
        }
    }
}

// Blog Full on Mobile/Desktop
.blog-full-desc {
    margin-top: 56px;

    &-content {
        ul,
        ol {
            list-style: disc !important;
            padding-left: 1.5em;

            li {
                margin: 1em 0;
            }
        }
        background: $color-black-10;
        padding: 32px 16px;

        @screen laptop {
            padding: 48px 100px;
        }

        @screen desktop {
            padding: 48px 123px 56px 128px;
        }

        &-button {
            margin: auto;
            width: 100%;
            text-transform: none !important;
            letter-spacing: 0 !important;
            font-weight: 400 !important;

            svg {
                margin-right: 8px;
            }
        }

        &-mobile {
            height: 300px;
            overflow: hidden;

            &.blog-full-desc-content-mobile-show {
                height: auto;
            }
        }
    }

    @screen laptop {
        margin-top: 100px;
    }
}
