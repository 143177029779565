button, a {
    &.full-width {
        @apply w-full;
    }

    &:active,
    &:focus {
        outline: none;
    }

    &.btn {
        @apply uppercase h-12 rounded-none px-5 font-bold text-sm inline-flex items-center justify-center text-center whitespace-no-wrap overflow-hidden;
        @include transitionPrefixMultiple(calc(#{$transition-duration} * 5), background-color, color);
        letter-spacing: 1px;

        svg {
            &:not(.no-overrides) {
                &,
                * {
                    @include transitionPrefixMultiple(calc(#{$transition-duration} * 5), fill, color);
                }
            }
        }

        &.icon--left,
        &.icon--right {
            &:disabled {
                svg {
                    opacity: .6;
                    filter: grayscale(1);
                }
            }
        }

        &.icon--left {
            svg {
                margin: 0 10px 0 0;
            }
        }

        &.icon--right {
            svg {
                margin: 0 0 0 10px;
            }
        }
    }

    &.btn-text {
        @apply normal-case h-auto px-0 py-0 font-normal;

        svg {
            &:not(.no-overrides) {
                &,
                * {
                    @apply fill-current text-blacksport-100;
                }
            }
        }

        @media (hover: hover) {
            &:hover {
                @apply text-redsport-60;

                svg {
                    &,
                    * {
                        @apply fill-current text-redsport-60;
                    }
                }
            }
        }

        &:disabled {
            @apply bg-transparent text-blacksport-50 pointer-events-none;

            svg {
                &:not(.no-overrides) {
                    &,
                    * {
                        @apply fill-current text-blacksport-50;
                    }
                }
            }
        }
    }

    &.btn-cta {
        @apply bg-redsport-60 text-white;

        @media (hover: hover) {
            &:hover {
                @apply bg-redsport-80;
            }
        }

        &:active {
            @apply bg-redsport-90
        }

        svg {
            @apply w-5;

            &:not(.no-overrides) {
                &,
                * {
                    @apply fill-current text-white;
                }
            }
        }

        &,
        .theme--dark &,
        &.dark-mode {
            &:disabled {
                @apply bg-blacksport-50 text-white cursor-default pointer-events-none;

                svg {
                    &:not(.no-overrides) {
                        &,
                        * {
                            @apply fill-current text-white;
                        }
                    }
                }
            }
        }
    }

    &.btn-default {
        @apply bg-blacksport-80 text-white;

        @media (hover: hover) {
            &:hover {
                @apply bg-blacksport-100;
            }
        }

        &:active {
            @apply bg-blacksport-80;
        }

        svg {
            @apply w-5;

            &:not(.no-overrides) {
                &,
                * {
                    @apply fill-current text-white;
                }
            }
        }

        .theme--dark &,
        &.dark-mode {
            @apply text-blacksport-100 bg-white;

            @media (hover: hover) {
                &:hover {
                    @apply bg-white text-redsport-60;

                    svg {
                        &:not(.no-overrides) {
                            &,
                            * {
                                @apply fill-current text-redsport-60;
                            }
                        }
                    }
                }
            }

            &:active {
                @apply bg-white text-redsport-80;

                svg {
                    &:not(.no-overrides) {
                        &,
                        * {
                            @apply fill-current text-redsport-80;
                        }
                    }
                }
            }

            svg {
                &:not(.no-overrides) {
                    &,
                    * {
                        @apply fill-current text-blacksport-100;
                    }
                }
            }
        }

        &,
        .theme--dark &,
        &.dark-mode {
            &:disabled {
                @apply bg-blacksport-50 text-white cursor-default pointer-events-none;

                svg {
                    &:not(.no-overrides) {
                        &,
                        * {
                            @apply fill-current text-white;
                        }
                    }
                }
            }
        }
    }

    &.btn-secondary {
        @apply bg-transparent text-blacksport-100 border border-blacksport-100 border-solid;

        svg {
            @apply w-5;

            &:not(.no-overrides) {
                &,
                * {
                    @apply fill-current text-blacksport-100;
                }
            }
        }

        @media (hover: hover) {
            &:hover {
                @apply border-redsport-60 text-redsport-60 bg-transparent;

                svg {
                    &:not(.no-overrides) {
                        &,
                        * {
                            @apply fill-current text-redsport-60;
                        }
                    }
                }
            }
        }

        &:active {
            @apply border-redsport-80 text-redsport-80 bg-transparent;

            svg {
                &:not(.no-overrides) {
                    &,
                    * {
                        @apply fill-current text-redsport-80;
                    }
                }
            }
        }

        .theme--dark &,
        &.dark-mode {
            @apply border-white text-white;

            svg {
                &:not(.no-overrides) {
                    &,
                    * {
                        @apply fill-current text-white;
                    }
                }
            }

            @media (hover: hover) {
                &:hover {
                    @apply border-redsport-60 text-redsport-60;

                    svg {
                        &:not(.no-overrides) {
                            &,
                            * {
                                @apply fill-current text-redsport-60;
                            }
                        }
                    }
                }
            }

            &:active {
                @apply border-redsport-80 text-redsport-80;

                svg {
                    &:not(.no-overrides) {
                        &,
                        * {
                            @apply fill-current text-redsport-80;
                        }
                    }
                }
            }
        }

        &,
        .theme--dark &,
        &.dark-mode {
            &:disabled {
                @apply bg-transparent border-blacksport-50 text-blacksport-50 pointer-events-none;

                svg {
                    &:not(.no-overrides) {
                        &,
                        * {
                            @apply fill-current text-blacksport-50;
                        }
                    }
                }
            }
        }
    }

    &.btn-default,
    &.btn-cta {
        &.btn--color {
            &-white {
                color: var(--color-black-100);
                background: var(--color-white-100);
            }

            &-black {
                color: var(--color-white-100);
                background: var(--color-black-80);
            }

            &-green {
                color: var(--color-white-100);
                background: var(--color-absolute-green-60);
            }

            &-blue {
                color: var(--color-white-100);
                background: var(--color-absolute-blue-60);
            }

            &-red {
                color: var(--color-white-100);
                background: var(--color-current-theme-60);
            }
        }

        &.btn--hover {
            &-white:hover {
                background: var(--color-white-100);
            }

            &-black:hover {
                background: var(--color-black-100);
            }

            &-green:hover {
                background: var(--color-absolute-green-80);
            }

            &-blue:hover {
                background: var(--color-absolute-blue-80);
            }

            &-red:hover {
                background: var(--color-current-theme-80);
            }
        }
    }

    &.btn-secondary {
        &.btn--color {
            &-white {
                color: var(--color-white-100);
                border-color: var(--color-white-100);
            }

            &-black {
                color: var(--color-black-100);
                border-color: var(--color-black-100);
            }

            &-green {
                color: var(--color-absolute-green-60);
                border-color: var(--color-absolute-green-60);
            }

            &-blue {
                color: var(--color-absolute-blue-60);
                border-color: var(--color-absolute-blue-60);
            }

            &-red {
                color: var(--color-absolute-blue-60);
                border-color: var(--color-absolute-blue-60);
            }
        }

        &.btn--hover {
            &-white:hover {
                color: var(--color-white-100);
                border-color: var(--color-white-100);
            }

            &-black:hover {
                color: var(--color-black-100);
                border-color: var(--color-black-100);
            }

            &-green:hover {
                color: var(--color-absolute-green-80);
                border-color: var(--color-absolute-green-80);
            }

            &-blue:hover {
                color: var(--color-absolute-blue-80);
                border-color: var(--color-absolute-blue-80);
            }

            &-red:hover {
                color: var(--color-current-theme-90);
                border-color: var(--color-current-theme-80);
            }
        }
    }
}
