/*
    Checkboxes Radios

    Wrapper
*/

.checkbox-radio--wrapper {
    display: flex;
    position: relative;
}

input {
    /*
        Checkboxes Radios

        Global Styles
    */

    &[type='checkbox'],
    &[type='radio'] {
        position: relative;
        transition: $transition-duration box-shadow $transition-ease;
        border: 0;
        background: $color-black-20;
        width: 20px;
        height: 20px;
        appearance: none;

        &:not(:disabled) {
            cursor: pointer;

            @media (hover: hover) {
                &:hover {
                    box-shadow: inset 0 0 0 1px $color-black-100;
                }
            }

            .is--invalid & {
                box-shadow: inset 0 0 0 1px $color-red-60;

                ~ label {
                    color: $color-red-60;

                    a {
                        text-decoration-color: $color-red-60;
                    }
                }
            }
        }

        &:disabled {
            border: 1px solid $color-black-40;
            background: transparentize($color-black-60, .85);
            cursor: default;

            + label {
                cursor: default;
                color: $color-black-60;
            }
        }

        + label {
            position: relative;
            margin: 0 0 0 -32px;
            cursor: pointer;
            padding: 0 0 0 44px;
            width: 100%;
            user-select: none;
        }

        .dark-mode & {
            background: $color-black-80;

            &:not(:disabled) {
                @media (hover: hover) {
                    &:hover {
                        box-shadow: inset 0 0 0 1px $color-black-60;
                    }
                }
            }
        }
    }

    /*
        Checkboxes Radios

        Checkboxes
    */

    &[type='checkbox'] {
        border-radius: 1px;

        &:checked {
            &:not(:disabled) {
                &::before,
                &::after {
                    transition: $transition-duration transform $transition-ease-in-out, $transition-duration opacity $transition-ease;
                    background: $color-black-100;
                }
            }

            &:disabled {
                &::before,
                &::after {
                    background: $color-black-40;
                }
            }

            &::before,
            &::after {
                opacity: 1;
            }

            &::before {
                transform: scale3d(1, 1, 1) rotate(-60deg);
            }

            &::after {
                transform: scale3d(1, 1, 1) rotate(35deg);
            }
        }

        &:not(:checked) {
            &:not(:disabled) {
                &::before,
                &::after {
                    background: $color-black-60;
                }
            }
        }

        &::before,
        &::after {
            position: absolute;
            transform-origin: 50% 50%;
            transition: $transition-duration transform $transition-ease, $transition-duration opacity $transition-ease;
            opacity: 0;
            border-radius: 1px;
            content: '';
            pointer-events: none;
        }

        &::before {
            top: 9px;
            left: 6px;
            transform: scale3d(0, 0, 0) rotate(-60deg);
            width: 16px;
            height: .09rem;
        }

        &::after {
            top: 13px;
            left: 2px;
            transform: scale3d(0, 0, 0) rotate(35deg);
            width: 9px;
            height: .09rem;
        }

        .dark-mode & {
            &:checked {
                &:not(:disabled) {
                    &::before,
                    &::after {
                        background: $color-white-100;
                    }
                }
            }

            &:not(:checked) {
                &:not(:disabled) {
                    &::before,
                    &::after {
                        background: $color-black-40;
                    }
                }
            }
        }
    }

    /*
        Checkboxes Radios

        Radios
    */

    &[type='radio'] {
        border-radius: 50%;

        &:checked {
            &:not(:disabled) {
                &::before {
                    transition: $transition-duration transform $transition-ease-in-out, $transition-duration opacity $transition-ease;
                    background: $color-black-100;
                }
            }

            &:disabled {
                &::before {
                    background: $color-black-40;
                }
            }

            &::before {
                transform: scale3d(1, 1, 1) translate(-50%, -50%);
                opacity: 1;
            }
        }

        &:not(:checked) {
            &:not(:disabled) {
                &::before,
                &::after {
                    background: $color-black-60;
                }
            }
        }

        &::before {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: scale3d(0, 0, 0) translate(-50%, -50%);
            transform-origin: 0 0;
            transition: $transition-duration transform $transition-ease, $transition-duration opacity $transition-ease;
            opacity: 0;
            border-radius: 50%;
            width: 10px;
            height: 10px;
            content: '';
            pointer-events: none;
        }

        .dark-mode & {
            &:checked {
                &:not(:disabled) {
                    &::before {
                        background: $color-white-100;
                    }
                }
            }

            &:not(:checked) {
                &:not(:disabled) {
                    &::before,
                    &::after {
                        background: $color-black-40;
                    }
                }
            }
        }
    }
}
