@import 'scss/variables';

.loading-button {
    &::after {
        display: block;
        width: 24px;
        height: 24px;
        margin: 4px auto;
        padding: 4px;
        content: '';
        animation: loading-button 1.5s linear infinite;
        border: 0;
        border-radius: 50%;
        background: linear-gradient(to right, $color-red-60 0%, $color-white-100 100%);
        mask: linear-gradient($color-white-100 0 0) content-box, linear-gradient($color-white-100 0 0);
        mask-composite: exclude;
    }
}

@keyframes loading-button {
    0% {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
