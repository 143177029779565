.alert--wrapper {
    &.Toastify__toast-container {
        @apply bottom-0 m-0 p-0 w-full;
        min-height: 56px;

        &--top-right {
            top: 56px;
            right: 0px;
            bottom: auto;
            width: auto;
            min-width: 100%;
            max-width: 100%;
            @include transitionPrefixMultiple(calc(#{$transition-duration} * 5), top);

            @screen tablet {
                min-width: 488px;
                top: 192px;
                right: 40px;

                .is--scrolled & {
                    top: 152px;
                }
            }
        }
    }

    .Toastify__toast {
        @apply m-0 p-0;
        min-height: 56px;
    }

    .Toastify__toast-body {
        @apply m-0;
        min-height: 56px;
        padding: 0;
    }

    .Toastify__close-button {

    }

    .Toastify__close-button--default {
        position: absolute;
        top: 20px;
        right: 16px;
        opacity: 1;
        width: 16px;
        height: 16px;

        &:before {
            position: absolute;
            top: 0;
            left: 0;
            background: url('../../icons/sport2000-icons/close-w.svg') no-repeat center;
            background-size: contain;
            width: 16px;
            height: 16px;
            content: '';
        }
    }

    .Toastify__close-button > svg {
        display: none;
    }

    .alert {
        display: flex;
        padding: 16px;

        &--error {
            background-color: $color-error;
            padding: 16px 32px 16px 16px;
            max-width: 550px;
        }

        &--success {
            background-color: $color-success;
        }

        p {
            font-family: $ff-notoSans;
            font-size: 16px;
            margin: 0 8px 0 16px;
            color: $color-white-100;
        }

        > span {
            svg {
                path, g {
                    fill: $color-white-100;
                }
            }
        }
    }
}
