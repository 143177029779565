.markdown-text { 
    @apply p-3;

    ul {
        @apply ml-4 inline-block;

        li {
            @apply list-disc;

            + li {
                @apply m-1;
            }
        }
    }

    h1, h2, h3, h4, h5, h6 {
       @apply font-bold text-neutral-600;
    }

    h1 {
        @apply text-3xl;
    }
    
    h2 {
        @apply text-2xl;
    }
    
    h3 {
        @apply text-xl;
    }
    
    h4 {
        @apply text-base;
    }
    
    h5 {
        @apply text-base;
    }
    
    h6 {
        @apply text-base;
    }

    strong, b {
        @apply font-bold;
    }

    a {
       @apply underline text-red-600;
    }

    blockquote {
       @apply m-4;
    }

    code {
        @apply text-red-600;
    }
}

.markdown-position--align-center {
    @apply text-center;
}

.markdown-position--align-right {
    @apply text-right;
}

.markdown-position--align-justify {
    @apply text-justify;
}

.markdown-position--padding-none {
    @apply p-0;
}

.markdown-position--padding-small {
    @apply p-3;
}

.markdown-position--padding-middle {
    @apply p-12;
}

.markdown-position--padding-large {
    @apply p-24;
}
