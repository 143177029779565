.brand-size-table {
    table {
        display: inline;
        padding-right: 16px;
        width: 100%;
        max-width: 100%;
        border-collapse: collapse;
        font-family: $ff-sport2000;

        &.w-max,
        .w-max & {
            @screen laptop {
                width: 100% !important;
            }
        }

        td {
            border: 1px solid $color-black-30;
            padding: 10px;

            p {
                margin-bottom: 0;
            }
        }

        th {
            background-color: $color-black-40;
            padding: 8px;
            text-align: left;
            color: white;
        }

        tr {
            &:nth-child(even) {
                background-color: $color-black-20;
            }
        }

        &.customers,
        .customers & {
            th {
                border: 1px solid $color-black-30;
                padding: 8px;
            }
        }
    }

    &.brand-size-table-tastic {
        overflow-x: scroll;

        table {
            padding-right: 0;
        }

        &::-webkit-scrollbar {
            &-track {
                background: $color-white-100 !important;
            }

            &-thumb {
                &:vertical,
                &:horizontal {
                    border: 2px solid $color-white-100 !important;
                }
            }
        }
    }
}

