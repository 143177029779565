@keyframes scale-in {
    0% {
        transform: scaleX(0)
    }
    to {
        transform: scaleX(1)
    }
}

@mixin link-hover--underline {
    text-decoration: none;
    display: inline-block;
    cursor: pointer;

    &.border-black {
        &::after {
            background: $color-black-100;
        }
    }

    &::after {
        position: relative;
        display: block;
        height: 1px;
        margin: 2px 0 0;
        background: $color-white-100;
        transform: scaleX(0);
        content: '';
    }

    &:hover,
    &.selected {
        &::after {
            transform: scaleX(1);
            -webkit-animation: scale-in .3s ease;
            animation: scale-in .3s ease;
        }
    }
}

@mixin link--underline {
    text-decoration: none;
    display: inline-block;
    cursor: pointer;

    &::after {
        position: relative;
        display: block;
        height: 1px;
        margin: 2px 0 0;
        background: $color-white-100;
        transform: scaleX(1);
        content: '';
    }

    &:focus {
        @apply outline-none;
    }

    &.border-black {
        &::after {
            background: $color-black-100;
        }
    }
}
