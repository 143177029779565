.header-mobile {
    height: 56px;
    @apply flex items-center font-openSans;
    margin: 0 -16px;
    background: $color-white-100;
    box-shadow: $shadow-header-mobile;

    &--logo {
        display: flex;
        justify-content: center;
        width: 190px;
        margin: 8px auto;

        img {
            width: 190px;
            height: 40px;
        }
    }

    &--bottom-bar {
        font-weight: 600;
        position: fixed;
        z-index: 1000;
        bottom: 0;
        left: 0;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: 64px;
        color: $color-white-100;
        background-color: $color-black-100;

        button,
        .badge-wrapper {
            font-size: 9px;
            font-weight: 600;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            width: 20%;
            transition: calc(#{$transition-duration} * 5) transform $transition-ease;

            &.active,
            &.is--active {
                background: $color-black-80;
            }
        }

        svg {
            margin: 0 0 8px;

            &,
            * {
                fill: $color-white-100;
            }
        }
    }

    .badge-count {
        top: -3px;
        right: 50%;
        margin: 0 !important;
        transform: translatex(20px);
    }


    &--navigation {
        position: fixed;
        z-index: 1001;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        width: 100%;
        @include translate(0, -130%);
        height: calc(100% - 64px);
        opacity: 0;
        background-color: $color-white-100;
        @include transitionPrefixMultiple(calc(#{$transition-duration} * 6), transform, opacity);

        &.active {
            opacity: 1;
            @include translate(0, 0);
        }

        .search {
            &.search-hits {
                position: static;
                overflow-y: scroll;
                flex-grow: 1;
            }

            &.search-box {
                position: static;
            }
        }

        &.search-result-mobile {
            background: none;
            height: auto;
        }
    }

    &--navigation-top {
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        display: flex;
        align-items: center;
        flex: none;
        justify-content: center;
        height: 56px;
        color: $color-white-100;
        background-color: $color-black-100;

        span, a {
            font-family: $ff-openSans;
            font-weight: 400;
            position: relative;
            margin: 0 7px;
            text-transform: uppercase;
            color: $color-black-30;

            &:first-child {
                &.active {
                    &::before {
                        display: none;
                    }
                }
            }

            &.active {
                font-weight: 700;
                color: $color-white-100;

                &::before {
                    position: absolute;
                    top: 2px;
                    left: -7px;
                    width: 1px;
                    height: 16px;
                    content: '';
                    background-color: $color-black-30;
                }
            }
        }
    }

    &--navigation-bottom {
        display: flex;
        flex: none;
        height: 56px;
        background-color: $color-black-80;

        &.is--sport {
            button,
            a {
                opacity: 1;
            }
        }

        button, a {
            font-size: 12px;
            font-weight: 700;
            line-height: 15px;
            position: relative;
            display: flex;
            align-items: center;
            flex: 1;
            justify-content: center;
            padding: 0 20px;
            text-transform: uppercase;
            opacity: .6;
            color: $color-white-100;
            border-radius: 0;
            outline: none;
            box-shadow: none !important;

            &.main-logo {
                opacity: 1;
            }

            &.active {
                opacity: 1;

                &::before {
                    position: absolute;
                    top: 0;
                    left: calc(50% - 8px);
                    width: 0;
                    height: 0;
                    content: '';
                    border-top: 8px solid $color-white-100;
                    border-right: 8px solid transparent;
                    border-left: 8px solid transparent;
                }
            }

            span {
                span {
                    &.teamsport {
                        color: $color-absolute-green-60;
                    }

                    &.running {
                        color: $color-absolute-blue-60;
                    }
                }
            }
        }
    }

    &--navigation-main {
        position: relative;
        flex-grow: 1;

        &.is--more-sub-level {
            .header-mobile--sub-level:not(:last-child) {
                @include translate(-50px, 0);
            }
        }
    }

    &--sub-level,
    &--top-level {
        > a, > span {
            position: relative;
            display: flex;
            align-items: center;
            min-height: 44px;
            padding: 0 20px;
            border-bottom: 1px solid $color-black-20;

            &:first-child {
                border-top: 1px solid $color-black-20;
            }

            span {
                &.is--arrow {
                    position: absolute;
                    top: 16px;
                    right: 20px;
                }
            }

            .all-text-link-mobile {
                span {
                    position: relative;
                    top: 0;
                    right: 0;
                }
            }
        }

        .nav-sale {
            color: $color-sport-red;
        }

        .all-product-mobile-link {
            a, span {
                line-height: 44px;
                min-height: 44px;
                padding: 0 20px;
            }
        }
    }

    &--sub-level {
        position: absolute;
        top: 0;
        left: 0;
        overflow-y: scroll;
        width: 100%;
        height: 100%;
        opacity: 1;
        background-color: $color-white-100;
        @include transitionPrefixMultiple(calc(#{$transition-duration} * 5), all);
        @include translate(0, 0);

        &.header-mobile--sub-level-enter {
            opacity: 0;
            @include translate(100%, 0);
        }

        &.header-mobile--sub-level-enter-active {
            opacity: 1;
            @include translate(0, 0);
            @include transitionPrefixMultiple(calc(#{$transition-duration} * 5), transform, opacity);
        }

        &.header-mobile--sub-level-exit {
            opacity: 1;
            @include translate(0, 0);
        }

        &.header-mobile--sub-level-exit-active {
            opacity: 0;
            @include translate(100%, 0);
            @include transitionPrefixMultiple(calc(#{$transition-duration} * 5), transform, opacity);
        }

        &.hide {
            opacity: 0;
            @include translate(-50px, 0);
        }
    }

    &--top-level {
        position: relative;
        overflow: auto;
        max-height: 100%;
        opacity: 1;
        @include transitionPrefixMultiple(calc(#{$transition-duration} * 5), all);
        @include translate(0, 0);

        a, span {
            font-weight: 700;
        }

        &.hide {
            opacity: 0;
            @include translate(-50px, 0);
        }
    }

    &--btn-back {
        position: relative;
        display: flex;
        align-items: center;
        flex: none;
        justify-content: center;
        height: 52px;
        text-transform: capitalize;
        border-top: 1px solid $color-black-20;

        svg {
            position: absolute;
            top: 16px;
            left: 20px;

            &,
            * {
                fill: $color-black-100 !important;
            }
        }
    }
}
