.small-tile-slider-container {
    .small-tile-slider-mobile {
        display: flex;
        overflow-x: auto;
        align-items: center;
        flex-wrap: nowrap;
        justify-content: left;
        margin: 0 -16px;
        gap: 16px;

        .small-tile-item {
            flex: 0 0 80%;
            max-width: 70%;
            margin: 0;
            cursor: pointer;

            &:first-child {
                margin-left: 16px;
            }

            &:last-child {
                margin-right: 16px;
            }
        }
    }

    .small-tile-item {
        position: relative;
        overflow: hidden;
        transition: calc(#{$transition-duration} * 5) transform $transition-ease;

        @screen desktop {
            @include translate(0, 0);
            @screen laptop {
                &:hover {
                    @include translate(0, -16px);

                    &:before {
                        opacity: 1;
                    }
                }
            }
        }

        h3 {
            @apply font-bold text-center text-white absolute w-full capitalize;
            z-index: 2;
            bottom: 10%;
            left: 0;
            margin: 0;
        }

        &::before {
            z-index: 1;
            height: 80%;
            @apply bg-gradient-to-t from-blacksport-90 to-transparent absolute w-full left-0 bottom-0 pointer-events-none;
            content: '';
            transition: calc(#{$transition-duration} * 5) opacity $transition-ease;
            opacity: .7;
        }
    }
}
