/* ========================================================================
   #TYPOGRAPHY
======================================================================== */

@include font-face('Open Sans', '#{$path-fonts}/open-sans-v23-latin-400', 400, normal, woff2 woff);
@include font-face('Open Sans', '#{$path-fonts}/open-sans-v23-latin-600', 600, normal, woff2 woff);
@include font-face('Open Sans', '#{$path-fonts}/open-sans-v23-latin-700', 700, normal, woff2 woff);

@include font-face('Sport2000 Display', '#{$path-fonts}/Sport2000Display-400', 400, normal, woff2 woff);
@include font-face('Sport2000 Display', '#{$path-fonts}/Sport2000Display-700', 700, normal, woff2 woff);

@include font-face('Sport2000', '#{$path-fonts}/Sport2000-400', 400, normal, woff2 woff);
@include font-face('Sport2000', '#{$path-fonts}/Sport2000-700', 700, normal, woff2 woff);

.font-sport2000 {
    font-family: $ff-sport2000;
}

.font-sport2000-display {
    font-family: $ff-sport2000display;
}

.font-openSans {
    font-family: $ff-openSans;
}

@layer base {
    body {
        @apply font-openSans text-base text-blacksport-100;
    }

    h1, h2, h3, h4, h5 {
        @apply font-sport2000 font-bold mb-4;
    }

    h1 {
        @apply text-h2;

        @screen desktop {
            @apply text-h1;
        }
    }

    h2 {
        @apply text-h2;
    }

    h3 {
        @apply text-h3;
    }

    h4 {
        @apply text-h4;
    }

    h5 {
        @apply text-h5;
    }

    h6 {
        @apply text-base font-bold;
    }

    .text-subh1, .text-subh2, .text-subh3, .text-subh4, .text-subh5 {
        @apply font-sport2000;
    }

    p, .text-base {
        @apply mb-4;
    }
}

.sub-h1 {
    font-size: 34px;
    line-height: 1;

    @screen desktop {
        font-size: 40px;
    }
}
