/*
    Remove padding bottom of body
*/
.s-node--cart {
    > .c-page-body {
        padding-bottom: 0;
    }
}

.cart-page {
    padding: 32px 0;

    @screen tablet {
        padding-top: 35px;
    }

    @screen laptop {
        padding-bottom: 100px;
    }

    &--empty-state {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 50px 0;
    }

    &--wrapper {
        display: flex;
        flex-direction: column;

        @screen laptop {
            flex-direction: row;
        }
    }

    &--btn-submit {
        width: 100%;
        margin-top: 20px;
    }

    &--content-left {
        flex-shrink: 0;
        max-width: 100%;

        @screen laptop {
            width: 65%;
        }

        @screen desktopx {
            width: 1000px;
        }
    }

    &--content-right {
        flex-grow: 1;
        padding-left: 0;

        @screen laptop {
            padding-left: 24px;
            margin-top: 0;
        }
    }


    &--title {
        padding: 24px 16px;
        background-color: $color-white-100;

        @screen tablet {
            padding: 0;
            background-color: transparent;
        }
        .is--count-article {
            span {
                padding-left: 2px;
            }
        }

        .cart-page--title-wrapper {
            display: flex;
            max-width: 100%;

            @screen laptop {
                padding-right: 24px;
                width: 65%;
            }

            @screen desktopx {
                width: 1000px;
            }

            > span {
                font-size: 16px;
                line-height: 1.5;
                @apply font-OpenSans;
                display: flex;
                align-items: flex-end;
                flex-grow: 1;
                justify-content: flex-end;
            }
        }

        h1 {
            @apply font-Sport2000;
            font-size: 24px;
            font-weight: bold;
            line-height: 1.1;
            margin: 0;

            @screen tablet {
                font-size: 44px;
            }
        }
    }

    &--list-item {
        position: relative;
        margin-bottom: 12px;
        padding: 0;

        @screen tablet {
            background-color: $color-white-100;
            padding: 8px 24px;
            margin-bottom: 20px;
        }

        &-header {
            padding: 16px 0;
            border-bottom: 1px solid $color-black-30;
        }

        &-headline {
            font-size: 14px;
            font-weight: 700;

            @screen laptop {
                font-size: 16px;
            }

            &-link {
                font-weight: 400;
                text-decoration: underline;
            }
        }

        .cart-page--item {
            position: relative;
            margin-bottom: 12px;
            padding: 16px 16px 116px 132px;
            background-color: $color-white-100;

            @screen tablet {
                display: flex;
                margin-bottom: 0;
                padding: 16px 0;
                gap: 25px;
                background-color: transparent;
                border-bottom: 1px solid $color-black-30;

                &:last-child {
                    border-bottom: none;
                }
            }

            .cart-page--item-image {
                position: absolute;
                top: 16px;
                left: 16px;
                display: flex;
                width: 100px;
                height: 125px;
                padding: 6px;
                border: 1px solid $color-black-20;

                img {
                    margin: auto;
                }

                @screen tablet {
                    width: 160px;
                    height: 200px;
                    position: static;
                }
            }

            .cart-page--item-info {
                flex-grow: 1;
                min-height: 125px;
                padding-top: 10px;

                @media (min-width: 1023px) and (max-width: 1199.98px) {
                    max-width: 45%;
                }

                @screen tablet {
                    padding-top: 0;
                    min-height: auto;
                    margin-right: 25px;
                }

                .cart-page-logo-theme {
                    margin-bottom: 16px;

                    @screen tablet {
                        position: absolute;
                        right: 0;
                        margin-bottom: 0;
                        margin-top: 16px;
                    }
                }

                h5 {
                    @apply font-Sport2000;
                    font-size: 16px;
                    margin: 0 0 8px;
                    cursor: pointer;

                    @screen tablet {
                        font-size: 18px;
                        margin-top: 16px;
                        margin-bottom: 6px;
                    }
                }

                .item-color {
                    font-size: 14px;
                    @apply font-OpenSans;
                    line-height: 20px;
                    margin-bottom: 8px;
                    color: $color-black-50;
                }

                .item-delivery-time {
                    @apply font-OpenSans;
                    font-size: 12px;
                    line-height: 20px;
                    display: none;
                    margin-bottom: 12px;
                    color: $color-absolute-green-60;

                    @screen tablet {
                        display: block;
                    }
                }

                .item-price-mobile {
                    font-size: 20px;
                    font-weight: bold;
                    @apply font-OpenSans;
                    line-height: 24px;

                    &.has--discount {
                        color: $color-red-60;

                        .original-price-mobile {
                            font-size: 16px;
                            font-weight: 400;
                            color: $color-black-100;

                            &:last-child {
                                text-decoration: line-through;
                            }
                        }
                    }
                }
            }

            .cart-page--item-quantity {
                position: absolute;
                bottom: 52px;
                left: 16px;
                width: 50%;

                @screen tablet {
                    width: 96px;
                    position: static;
                }
            }

            .cart-page--item-action {
                position: absolute;
                bottom: 16px;
                left: 16px;
                display: flex;
                width: 100%;
                margin-top: 12px;

                @screen tablet {
                    position: static;
                }

                button {
                    font-size: 14px;
                    @apply font-OpenSans;
                    display: flex;
                    margin-right: 24px;

                    span {
                        text-decoration: underline;
                    }
                }
            }

            .cart-page--price {
                display: none;
                flex-direction: column;
                justify-content: flex-end;

                @media (min-width: 1023px) and (max-width: 1199.98px) {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    margin-bottom: 16px;
                }

                @screen tablet {
                    display: flex;
                }

                .discount-price,
                .original-price {
                    font-size: 20px;
                    font-weight: bold;
                    @apply font-OpenSans;
                    line-height: 1.1;
                    text-align: right;
                }

                .original-price {
                    color: $color-black-100;

                    &.has--discount {
                        font-size: 16px;
                        font-weight: 400;
                        margin-bottom: 2px;
                    }
                }

                .discount-price {
                    color: $color-red-60;
                }
            }
        }
    }

    &--footer {
        display: flex;
        justify-content: space-between;

        .cart-page--payment-list {
            .cart-page--payment-list-wrapper {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                gap: 16px;

                .payment-item {
                    display: flex;
                    align-items: center;
                }

                @screen desktop {
                    grid-template-columns: repeat(6, 1fr);
                }
            }
        }

        .cart-page--link-list,
        .cart-page--payment-list {
            width: 100%;
            padding: 32px 24px;
            background-color: $color-white-100;

            @screen tablet {
                width: calc(50% - 12px);
            }

            h5 {
                font-size: 18px;
                font-weight: bold;
                line-height: 1;
                margin-right: 0;
                margin-bottom: 16px;
                margin-left: 0;

                @screen tablet {
                    margin-bottom: 24px;
                }
            }
        }

        .cart-page--link-list {
            .cart-page--link-list-wrapper {
                display: flex;
                flex-direction: column;

                @screen tablet {
                    flex-flow: row wrap;
                    flex-direction: row;
                }

                a {
                    @apply font-OpenSans;
                    font-size: 14px;
                    line-height: 20px;
                    margin-bottom: 12px;
                    text-decoration: underline;

                    @screen tablet {
                        margin-right: 24px;
                        margin-bottom: 0;
                    }

                    &:last-child {
                        margin-right: 0;
                    }

                    &:hover {
                        color: var(--color-current-theme-60);
                    }
                }
            }
        }
    }

    .cart-page--summary-total {
        position: sticky;
        top: 192px;
        @apply font-OpenSans;
        padding: 24px;
        background-color: $color-white-100;
        @include transitionPrefixMultiple(calc(#{$transition-duration} * 5), all);

        .is--scrolled & {
            top: 135px;
        }

        h4 {
            font-size: 24px;
            font-weight: bold;
            line-height: 1;
            margin: 0 0 24px;
            @apply font-Sport2000;
        }

        > p {
            font-size: 16px;
            line-height: 24px;
            display: flex;
            margin-bottom: 10px;

            @screen tablet {
                margin-bottom: 20px;
            }

            &:last-child {
                margin-bottom: 16px;
            }

            span {
                &:last-child {
                    flex-grow: 1;
                    text-align: right;
                }
            }

            &.is--minus {
                padding: 10px 8px;
                background-color: rgba(82, 176, 0, 0.1);

                @screen tablet {
                    color: $color-red-60;
                    padding: 0;
                    background-color: transparent;
                }
            }
        }

        .cart-page--last-price {
            padding-top: 20px;
            border-top: 1px solid $color-black-30;

            > p {
                font-size: 16px;
                font-weight: bold;
                line-height: 1.5;
                position: relative;
                margin-bottom: 0;

                small {
                    font-size: 14px;
                    font-weight: 400;
                    color: $color-black-70;
                }

                > span {
                    &:last-child {
                        position: absolute;
                        top: 0;
                        right: 0;
                    }
                }
            }

            .cart-page--coupon-form {
                margin-top: 20px;

                label {
                    font-size: 12px;
                    display: block;
                    margin-bottom: 4px;
                }

                input[type='text'] {
                    width: 100%;
                    height: 48px;
                    padding: 0 16px;
                    outline: none;
                    background-color: $color-black-10;

                    &::placeholder {
                        opacity: 1;
                    }
                }
            }
        }
    }
}
