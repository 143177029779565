.swiper-container {
    position: relative;
    z-index: 1;
    overflow: hidden;
    margin-right: auto;
    margin-left: auto;
    padding: 0;
    /* Fix of Webkit flickering */
    list-style: none;
}

.swiper-container-vertical > .swiper-wrapper {
    flex-direction: column;
}

.swiper-wrapper {
    position: relative;
    z-index: 1;
    display: flex;
    box-sizing: content-box;
    width: 100%;
    height: 100%;
    transition-property: transform;
}

.swiper-container-android .swiper-slide,
.swiper-wrapper {
    transform: translate3d(0px, 0, 0);
}

.swiper-container-multirow > .swiper-wrapper {
    flex-wrap: wrap;
}

.swiper-container-multirow-column > .swiper-wrapper {
    flex-direction: column;
    flex-wrap: wrap;
}

.swiper-container-free-mode > .swiper-wrapper {
    margin: 0 auto;
    transition-timing-function: ease-out;
}

.swiper-container-pointer-events {
    touch-action: pan-y;

    &.swiper-container-vertical {
        touch-action: pan-x;
    }
}

.swiper-slide {
    position: relative;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    transition-property: transform;
}

.swiper-slide-invisible-blank {
    visibility: hidden;
}

/* Auto Height */
.swiper-container-autoheight {
    &,
    .swiper-slide {
        height: auto;
    }

    .swiper-wrapper {
        align-items: flex-start;
        transition-property: transform, height;
    }
}

/* 3D Effects */
.swiper-container-3d {
    perspective: 1200px;

    .swiper-wrapper,
    .swiper-slide,
    .swiper-slide-shadow-left,
    .swiper-slide-shadow-right,
    .swiper-slide-shadow-top,
    .swiper-slide-shadow-bottom,
    .swiper-cube-shadow {
        transform-style: preserve-3d;
    }

    .swiper-slide-shadow-left,
    .swiper-slide-shadow-right,
    .swiper-slide-shadow-top,
    .swiper-slide-shadow-bottom {
        position: absolute;
        z-index: 10;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
    }

    .swiper-slide-shadow-left {
        background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }

    .swiper-slide-shadow-right {
        background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }

    .swiper-slide-shadow-top {
        background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }

    .swiper-slide-shadow-bottom {
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}

/* CSS Mode */
.swiper-container-css-mode {
    > .swiper-wrapper {
        @include hide-scrollbars();
        scrollbar-width: none; /* For Firefox */
        -ms-overflow-style: none; /* For Internet Explorer and Edge */
        &::-webkit-scrollbar {
            display: none;
        }
    }

    > .swiper-wrapper > .swiper-slide {
        scroll-snap-align: start start;
    }
}

.swiper-container-horizontal.swiper-container-css-mode {
    > .swiper-wrapper {
        scroll-snap-type: x mandatory;
    }
}

.swiper-container-vertical.swiper-container-css-mode {
    > .swiper-wrapper {
        scroll-snap-type: y mandatory;
    }
}
