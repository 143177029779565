.product-detail-attributes {
    display: flex;
    flex-direction: column;
    margin: 25px -16px;
    background-color: $color-black-10;
    padding: 32px 16px;

    @screen laptop {
        margin: 50px 32px;
        flex-direction: row;
        justify-content: space-between;
        gap: 20px;
    }

    @screen desktop {
        padding: 40px 80px;
        gap: 50px;
    }

    &-left {
        margin-bottom: 40px;

        @screen laptop {
            flex: 0 0 60%;
            max-width: 60%;
        }
    }

    &-right {
        @screen laptop {
            flex: 0 0 40%;
            max-width: 40%;
            padding-right: 20px;
        }

        @screen desktop {
            padding-right: 0px;
        }
    }

    .product-detail-attributes-item {
        margin-bottom: 40px;

        @screen laptop {
            margin-bottom: 60px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .product-detail-attributes-headline {
        margin-bottom: 16px;
        text-transform: capitalize;
        line-height: 24px;
        font-family: $ff-sport2000;
        font-size: 24px;
        font-weight: 700;
    }

    &-info {
        display: inline-block;
        margin-left: 5px;
        vertical-align: middle;
    }

    &-text {
        text-transform: capitalize;

        .product-detail-attributes-headline {
            margin-bottom: 4px;
        }

        &-content {
            margin-top: 4px;
            line-height: 1;
            word-break: break-word;
            font-family: $ff-sport2000;
            font-size: 32px;
            font-weight: 700;

            @screen laptop {
                font-size: 32px;
            }
        }
    }

    &-bar {
        &-wrapper {
            display: flex;

            .product-detail-attributes-bar-item {
                display: flex;
                flex-basis: 0;
                flex-direction: column;
                flex-grow: 1;
                margin-right: 6px;
                max-width: 100px;
                overflow: hidden;

                &:last-of-type {
                    margin-right: 0;
                }

                &-box {
                    &.is--icon {
                        background: $color-detail-white;
                        padding: 15px;
                        height: 72px;

                        img {
                            margin: auto;
                            max-width: 100%;
                            max-height: 100%;
                            object-fit: contain;
                        }
                    }
                }

                &-image {
                    opacity: 0.5;

                    &.is--active {
                        opacity: 1;
                    }
                }

                &-line {
                    background: $color-bar-line;
                    width: 100%;
                    height: 6px;

                    &.is--active {
                        background: $color-black-100;
                    }

                    &-text {
                        margin-top: 8px;
                        width: 100%;
                        overflow: hidden;
                        text-align: center;
                        text-transform: capitalize;
                        text-overflow: ellipsis;
                        line-height: 16px;
                        white-space: nowrap;
                        font-size: 14px;

                        &.is--active {
                            font-weight: 700;
                        }
                    }
                }

                @screen laptop {
                    max-width: 160px;
                }
            }
        }
    }

    &-icon {
        &-image {
            max-width: 100px;
            height: 42px;

            img {
                margin: auto;
            }
        }

        .product-detail-attributes-bar-item-line-text {
            font-size: 14px;
        }
    }

    .tooltip-content {
        .info-icon {
            margin-right: 20px;
        }

        &:hover,
        &:active {
            cursor: pointer;

            .tooltip-message {
                visibility: visible;
            }

            .info-icon {
                path {
                    fill: $color-red-60;
                }
            }
        }

        .tooltip-message {
            position: absolute;
            visibility: hidden;
            z-index: 10;
            border-radius: 3px;
            box-shadow: 0 1px 10px RGBA(0, 0, 0, 0.12);
            background: #fafafa;
            text-align: center;
            color: $color-black-100;
            font-size: 14px;

            &.tooltip-top {
                right: 50%;
                bottom: 100%;
                left: auto;
                margin-right: -120px;
                margin-bottom: 15px;
                padding: 16px;
                width: 240px;

                &::before {
                    position: absolute;
                    right: 120px;
                    bottom: -9px;
                    left: auto;
                    border-top: 10px solid #fafafa;
                    border-right: 10px solid transparent;
                    border-left: 10px solid transparent;
                    content: "";
                }
            }

            .tooltip-text {
                height: 100%;

                > p {
                    margin-bottom: 0;
                }
            }
        }
    }
}
