@mixin thumbnail-item {
    height: 58px;
    width: 58px;
    max-width: 58px;
    border: 1px solid $color-black-20;
}

.product-detail-page {
    .breadcrumb-wrapper {
        @screen laptop {
            display: block;
            background: transparent;
            padding-left: 0;
            width: auto;
            top: 32px;
            left: 0;
            right: 0;
            margin-left: 0;
            margin-right: 0;

            .breadcrumb {
                width: 100%;
                max-width: 100%;
                padding: 0;
            }
        }
    }

    .c-page-body {
        @screen laptop {
            padding-bottom: 0;
        }
    }
}

.product-detail-wrapper {
    @screen laptop {
        position: relative;
        right: 50%;
        left: 50%;
        margin-right: -50vw;
        margin-left: -50vw;
        width: calc(#{$viewport-width-without-scrollbar});
    }

    .slick-arrow {
        @apply bg-white;

        @media (max-width: 1023.98px) {
            display: none !important;
        }
    }

    .slick-next,
    .slick-prev {
        display: none !important;
    }

    .slick-dots {
        @apply bottom-0;

        @screen laptop {
            display: none !important;
        }
    }

    .product-detail-component {
        @screen laptop {
            @apply relative visible w-full flex-auto;
        }
    }

    .product-detail--wrapper {
        .product-detail-image-wrapper {
            position: relative;

            .product-detail-image-logo {
                @apply absolute;
                z-index: 1;
                top: -5px;
                right: 0;
                width: 130px;
                height: 55px;

                @screen tablet {
                    right: 40px;
                }
            }

            .product-detail-tags {
                position: absolute;
                top: 22px;
                left: 0;
                max-width: 60%;
            }

            @screen laptop {
                height: calc(#{$viewport-height} - 271px);
                margin-bottom: 24px;
            }
        }

        @screen laptop {
            flex: 0 0 68%;
            max-width: 68%;
            border-right: 1px solid $color-black-30;
            position: relative;

            &::before {
                content: '';
                position: absolute;
                top: -64px;
                right: -1px;
                width: 1px;
                background: $color-black-30;
                height: 64px;
            }
        }
    }

    .product-detail-image {
        margin-bottom: 24px;

        @screen laptop {
            margin-bottom: 0;
        }
    }

    .product-article-image {
        @apply relative w-full h-full px-4;

        @screen laptop {
            @apply p-0;
            height: calc(#{$viewport-height} - 331px);

            &:last-of-type {
                padding: 0 32px;
            }
        }

        .product-video-item {
            margin: 80px 0 50px 0;
            aspect-ratio: 16/9;

            .cmplazypreviewiframe {
                position: relative !important;
                top: unset !important;
                left: unset !important;
                background-image: unset !important;

                .cmplazypreviewmsg {
                    left: 50% !important;
                    width: 50% !important;
                    transform: translate(-50%, -50%) !important;
                }
            }

            @screen tablet {
                margin: 40px 0 0 0;
            }

            @screen laptop {
                width: 100%;
                height: 100%;
                margin: 0;
                padding: 40px 0 20px 0;
            }
        }

        .product-article-img {
            padding: 80px 0 50px 0;

            @screen laptop {
                height: 100%;
                padding: 80px 0 38px 0;
            }

            img {
                max-height: 238px;
                @apply object-contain h-full m-auto;

                @screen tablet {
                    max-height: 100%;
                }

                @screen laptop {
                    max-height: 100%;
                    height: 100%;
                }
            }
        }
    }

    .product-teaser--badge {
        top: 16px;
        left: 16px;

        @screen laptop {
            @apply hidden;
        }
    }

    .thumbnail-slider-wrap {
        @apply mx-auto cursor-pointer;
        max-width: 345px;

        .slick-prev {
            left: -60px;
            display: block !important;

            &::before {
                width: 16px;
                height: 16px;
            }
        }

        .slick-track {
            display: flex;
            justify-content: center;
        }

        .slick-next {
            right: -50px;
            display: block !important;

            &::before {
                width: 16px;
                height: 16px;
            }
        }

        .slick-slide {
            @include thumbnail-item;

            &:not(:first-of-type) {
                margin: 0 0 0 10px;
            }

            &.slick-current {
                @apply border border-solid border-black;
            }

            div {
                @apply h-full;
            }
        }

        .slick-track {
            @apply mx-auto;
        }

        .product-article-image,
        .product-article-img {
            padding: 2px;
        }

        @media (max-width: 1023.98px) {
            @apply hidden;
        }

        .product-article-image {
            iframe,
            .cmplazypreviewiframe {
                display: none !important;
            }
        }

        .product-video-item {
            padding: 0 !important;
            background-image: url('../../icons/sport2000-icons/play.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 50%;
        }
    }

    .product-data-desktop {
        .product-detail-data-wrapper {
            width: 100vw;
        }

        .product-detail-data {
            flex: 0 0 320px;
            max-width: 320px;
            padding: 0 0 224px 16px;
            transition: calc(#{$transition-duration} * 4) padding-top $transition-ease;

            .is--scrolled & {
                padding-top: 148px;
            }

            @media (min-width: 1280px) and (max-width: 1380px) {
                padding-right: 70px;
            }

            @media (min-width: 1381px) and (max-width: 1500px) {
                padding-right: 40px;
            }

            @screen desktop {
                flex: 0 0 472px;
                max-width: 472px;
            }
        }

        .plain-modal-content {
            max-height: 600px;
        }
    }

    .plain-modal {
        @screen tablet {
            width: 750px;
            max-width: 750px;
        }
    }

}

//buying box for mobile
.product--data-mobile {
    display: flex;
    flex-direction: column;

    .select-attribute {
        display: none;
    }

    .product-variant-color {
        margin-bottom: 28px;

        .select-variants-component {
            background-color: $color-black-10;
        }

        .select-variant-wrapper {
            display: flex;
            overflow-x: auto;
            overflow-y: hidden;
            flex-wrap: nowrap;
            padding: 8px 0;

            .select-variant-item {
                flex: 0 0 auto;
                width: 15%;
                height: 56px;
                margin-right: 8px;
                margin-bottom: 0;
                cursor: pointer;
                border: 1px solid $color-black-20;
                background-color: $color-white-100;

                @screen tablet {
                    width: 10%;
                    margin-right: 10px;
                }

                &.select-variant-active {
                    border: 1px solid $color-black-100;
                }

                .select-variant-button {
                    .variant-label {
                        display: none !important;
                    }
                }
            }
        }
    }

    .product-detail-data {
        margin: 28px 0 32px;
    }

    .select-variant-total {
        font-size: 12px;
        line-height: 16px;
        margin: auto 10px auto 16px;
        white-space: nowrap;

        > span {
            white-space: normal;
        }
    }
}

//buying box for desktop
.product--data-desktop {
    .product--data-badge,
    .product-variant-size {
        margin-bottom: 28px;
    }

    .product--color-label {
        margin-bottom: 8px;
    }

    .product-variant-color {
        margin-bottom: 28px;
    }

    .select-variant-total {
        display: none;
    }

    .product-variant-color {
        .color-label {
            display: none;
        }

        .select-variant-wrapper {
            .select-variant-item {
                .select-variant-button {
                    .variant-label {
                        display: none;
                    }
                }
            }
        }
    }
}

//product data common
.product-detail-data {
    .product--store {
        font-size: 14px;
        display: flex;
        margin-bottom: 16px;

        svg {
            margin-right: 10px;
        }

        &__information {
            .status-stock-store {
                &.out-of-stock {
                    color: $color-not-stock
                }

                &.no-store {
                    color: $color-black-50;
                }

                &.available {
                    color: $color-success;
                }
            }
        }

        button {
            font-size: 14px;
            display: flex;
            text-decoration: underline;
        }
    }

    .product--one-color {
        margin-bottom: 24px;
    }

    .product--name {
        font-family: $ff-sport2000;
        font-size: 24px;
        font-weight: 700;
        line-height: 24px;
    }

    .product--brand {
        font-family: $ff-sport2000;
        font-size: 18px;
        line-height: 20px;
    }

    .product--data-badge {
        text-transform: uppercase;
    }

    .product--price--tax {
        margin: 28px 0;

        .product--tax {
            font-size: 12px;
            font-weight: 400;
            line-height: 20px;
            color: $color-black-60;
        }
    }

    .product--price-wrapper {
        display: flex;

        .product--price-discount {
            font-size: 24px;
            font-weight: 600;
            line-height: 24px;
            margin-right: 10px;
            color: $color-red-60;
        }

        .product--price-normal {
            font-family: $ff-openSans;
            font-size: 24px;
            line-height: 24px;
            display: flex;
        }
    }

    .product--color-label,
    .product--size-label {
        font-family: $ff-openSans;
        font-size: 14px;
        line-height: 20px;
        text-transform: capitalize;
        color: $color-black-60;

        @screen laptop {
            font-size: 12px;
            line-height: 16px;
        }
    }

    .select-variant-wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;

        .select-variant-item {
            font-size: 14px;
            line-height: 58px;
            display: inline-block;
            width: 58px;
            height: 58px;
            cursor: pointer;
            text-align: center;
            border: 1px solid $color-black-20;

            &.select-variant-active {
                border: 1px solid $color-black-100;

                &.select-disabled {
                    border: 1px solid $color-black-30;
                }
            }

            &.select-disabled {
                background-color: $color-black-20;

                .variant-label {
                    color: $color-black-50;
                }
            }

            .select-variant-button {
                font-size: 14px;
                font-weight: 400;
                text-transform: none !important;
            }

            img {
                max-width: 100%;
                height: 100%;
                max-height: 100%;
                margin: auto;
                padding: 4px;
            }
        }
    }

    .product-variant-size {
        .select-variant-item {
            line-height: normal;
            width: 65px !important;
            height: auto;
            padding: 12px 0;

            .select-variant-button {
                font-size: 13px;
                word-wrap: break-word;
                @include line-clamp(2);
            }

            img {
                display: none !important;
            }
        }
    }

    .label-name {
        font-family: $ff-openSans;
        font-size: 12px;
        margin-bottom: 8px;
    }

    .product--data-status {
        margin-bottom: 28px;

        .status-text {
            @apply text-sm font-normal leading-5;
            margin-left: 10px;

            &.status-delivery-text {
                color: $color-absolute-green-60;
            }
        }
    }

    .product--add-to-cart {
        margin-bottom: 12px;

        .btn-add-to-cart {
            background-color: $color-absolute-green;
        }

        @screen laptop {
            margin-bottom: 16px;
        }
    }

    .product--quantity {
        width: 72px;
    }
}

.product-detail--placeholder {
    width: 100%;
    padding-top: 56.2%;
    background: $color-black-20;

    @screen laptop {
        position: relative;
        top: 80px;
    }
}

//dark mode
.theme--dark {
    &.product-detail-wrapper {
        .product-detail--wrapper {
            @screen laptop {
                border-right: 1px solid $color-black-80;
            }

            &:before {
                display: none;

                @screen laptop {
                    display: block;
                    background: $color-black-80;
                }
            }
        }
    }

    .product-data-desktop {
        min-height: 100vh;
        margin-top: -2vw;
        background: $color-black-100;

        .product-detail-data {
            padding-top: 36px;
        }
    }

    .product-data-mobile {
        .product-detail-data-wrapper {
            padding: 16px;
        }
    }

    .product-detail-data-wrapper {
        color: $color-white-100;
        background: $color-black-100;

        .product-detail-data {
            .no-size-label {
                color: $color-red-60 !important;
            }

            .product--add-to-cart {
                .btn {
                    background: $color-absolute-green;

                    &:hover,
                    &:active {
                        background: $color-absolute-green-80;
                    }
                }
            }

            .product--color-label,
            .product--one-color {
                color: $color-black-40;
            }

            .product--price--tax {
                .product--tax {
                    color: $color-black-40;
                }
            }

            .product--data-status {
                svg {
                    &,
                    * {
                        fill: $color-white-100;
                    }
                }
            }

            .product-variant-color {
                .select-variants-component {
                    background: $color-black-90;

                    @screen laptop {
                        background: $color-black-100;
                    }

                    .select-variant-item {
                        @screen laptop {
                            background: $color-white-100;
                        }

                        &.select-variant-active {
                            border: none;
                            outline: 2px solid $color-absolute-green;
                        }
                    }
                }
            }

            .product-variant-size {
                .select-variant-item {
                    border: 1px solid $color-black-70;
                    background: $color-black-90;

                    &.select-variant-active {
                        border: 1px solid $color-white-100;
                    }
                }
            }
        }
    }

    .product-detail-feature {
        color: $color-white-100;
        background: $color-black-100;

        .jump-mark {
            padding: 0 16px;
            background: $color-black-100;
            box-shadow: none;

            .jump-mark--bar {
                border-top: 1px solid $color-black-80;
                border-bottom: 1px solid $color-black-80;
                box-shadow: none;

                &:after,
                &:before {
                    display: none;

                    @screen laptop {
                        display: block;
                        background: $color-black-80;
                    }
                }
            }
        }

        .jump-mark--item-link {
            span {
                color: $color-white-100;
            }

            &::after {
                background-color: var(--color-black-100);
            }

            &:hover {
                &::after {
                    background-color: var(--color-white-100);
                }
            }
        }

        .description-teaser {
            padding-right: 16px;
            padding-left: 16px;

            @screen laptop {
                padding-right: 32px;
                padding-left: 32px;
            }
        }
    }
}

//Absolute Run Theme
.theme--blue {
    .product-detail-data {
        .no-size-label {
            color: $color-red-60 !important;
        }

        .product--add-to-cart {
            .btn {
                background: $color-absolute-blue-60 !important;

                &:hover,
                &:active {
                    background: $color-absolute-blue-80 !important;
                }
            }
        }

        .product-variant-color {
            .select-variant-wrapper {
                .select-variant-item {
                    &.select-variant-active {
                        border: none;
                        outline: 2px solid $color-absolute-blue !important;
                    }
                }
            }
        }
    }

    .product--data-status {
        .status-text {
            &.status-delivery-text {
                color: $color-absolute-blue;
            }
        }
    }
}

//Sticky
.is--sticky {
    .jump-mark {
        position: sticky;
        top: 0;

        @screen laptop {
            top: 110px;
        }
    }

    .product-detail-data-wrapper {
        @screen laptop {
            position: sticky;
            top: 0;
        }
    }
}
