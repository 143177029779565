/* ==========================================================================
   #Objects
========================================================================== */

/**
 * Full Page Width Wrapper block
 */

.o-full-page-width-wrapper {
    @include full-width-wrapper;
}


/**
 * Media block
 */

.o-block--media-fill {
    a {
        @include media-fill;
    }
}


/**
 * Link
 */

.o-link-hover--underline {
    @include link-hover--underline;
}

.o-link--underline {
    @include link--underline;
}


/**
 * mobile on scroll
 */

.mobile-scroll {
    @media (max-width: 767.98px) {
        @apply flex flex-no-wrap gap-5 whitespace-no-wrap overflow-hidden overflow-x-auto;
        padding-right: 16px;
        padding-bottom: 20px;
        padding-left: 16px;

        [class*='__item '],
        [class*='__item'] {
            display: inline-block;
        }
    }
}

/**
 * Simple Select Box
 */

.simple-select-box {
    @apply relative;

    select {
        @apply font-OpenSans text-base font-bold outline-none cursor-pointer appearance-none text-right;
        padding: 12px 27px 12px 12px;
        background-color: transparent !important;
    }

    .arrow-down {
        @apply absolute right-0;
        top: 50%;
        transform: translateY(-50%);
    }
}

/**
 * Anchor target block
 */

div:target {

    @screen laptop {
        padding-top: 168px;
        margin-top:  -168px;
    }

    padding-top: 1em;
    margin-top:  -1em;
}
