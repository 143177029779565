.blog-post {
    @apply text-blacksport-100;
    .slick-slider {
        margin-right: -12px;
        margin-left: -12px;
    }

    svg {
        width: 100%;
        height: 14px;
        fill: var(--color-current-theme);

        &,
        * {
            fill: var(--color-current-theme);
        }
    }

    &.dark-mode {
        @apply text-white;
        .blog-post-item {
            padding-bottom: 20px;
            @screen tablet {
                padding-bottom: 0;
            }
        }
    }

    .blog-post-mobile {
        overflow: hidden;
        overflow-x: auto;
        margin-left: 0;
        white-space: nowrap;

        .blog-post-item {
            display: inline-block;
            margin-right: 0;
            margin-left: -12px;
            padding: 0 16px;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }

            h5 {
                overflow: hidden;
                margin-right: 0;
                margin-left: 0;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            a {
                button {
                    width: 100%;
                }
            }
        }
    }

    .blog-post-item {
        @apply mx-3;
        display: inline-block;
        overflow: hidden;
        max-width: 80vw;
        @apply font-OpenSans;

        @screen desktop {
            &:hover {
                cursor: pointer;

                img {
                    transform: scale3d(1.1, 1.1, 1.1);
                }

                .blog-post-item--link {
                    opacity: 1;
                    pointer-events: auto;
                }
            }
        }

        &--image {
            overflow: hidden;
            height: auto;
        }

        &--category-name {
            font-size: 12px;
            font-weight: bold;
            line-height: 21px;
            display: inline-block;
            height: 21px;
            margin: 16px 0;
            padding: 0 11px;
            border-radius: 4px;
            @apply font-OpenSans;
            background-color: $color-absolute-green-80;
        }

        &--date {
            font-size: 14px;
            line-height: 24px;
            margin: 0 0 4px;
        }

        &--title {
            font-size: 16px;
            font-weight: bold;
            line-height: 24px;
            margin: 0 0 4px;
            @apply font-OpenSans;
            @include line-clamp(1);
        }

        &--desc {
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 12px;
            @include line-clamp(3);
        }

        &--link {
            font-size: 14px;
            line-height: 20px;
            text-decoration: underline;
            pointer-events: none;
            opacity: 0;
            color: $color-absolute-green-60;
            @include transitionPrefixMultiple(calc(#{$transition-duration} * 5), opacity);
        }

        &--image {
            img {
                @screen desktop {
                    transform: scale3d(1, 1, 1);
                    @include transitionPrefixMultiple(calc(#{$transition-duration} * 5), transform);
                }
            }
        }
    }
}
