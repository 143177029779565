address {
    font-style: normal;
}

.hint-required {
    text-align: center;
    line-height: 16px;
    color: $color-black-50;
    font-size: 12px;
    font-weight: 400;
}
