blockquote {
    font-size: 40px;
    font-weight: 700;
    font-family: $ff-sport2000;
    line-height: 1.1;
    text-align: center;

    &:before {
        content: ",";
    }

    &:after {
        content: ",";
        display: inline-block;
        transform: rotate(180deg);
    }

}

.blockquote_description {
    text-align: center;
    line-height: 1.5;
}
