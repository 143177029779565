.blog-tile {
    position: relative;
    overflow: hidden;

    &:hover {
        img {
            transform: scale(1.1);
        }
    }

    img {
        height: 408px;
        transform: scale(1);
        @include transitionPrefixMultiple(calc(#{$transition-duration} * 5), transform);
        object-fit: cover;

        @screen laptop {
            height: 525px;
        }
    }

    &--content {
        @apply bg-gradient-to-t from-blacksport-90 to-transparent;
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: flex-end;
        width: 100%;
        height: 70%;
        padding: 0 16px 40px;

        @screen tablet {
            padding: 0 24px 32px;
        }

        h3 {
            font-size: 24px;
            font-weight: 700;
            line-height: 1;
            margin: 0;
            word-break: break-all;
            color: $color-white-100;

            @screen tablet {
                font-size: 32px;
            }
        }
    }
}
