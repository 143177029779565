@mixin hidden {
    opacity: 0;
    visibility: hidden;
}

@mixin visible {
    opacity: 1;
    visibility: visible;
    @include transitionPrefixMultiple(calc(#{$transition-duration} * 5), opacity);
}

.btn-add-to-cart {
    @apply font-OpenSans font-bold text-sm;
    padding: 0 20px;

    span {
        @apply block;
        @include one-line;
        line-height: 48px;
    }
}

.product-teaser-list {
    @apply font-OpenSans grid grid-cols-2;
    margin-bottom: 40px;

    @screen tablet {
        @apply grid-cols-3 gap-3;
    }

    @screen laptop {
        @apply grid-cols-4 gap-5;
        margin-bottom: 64px;
    }
}

.product-teaser {
    @media (max-width: 767.98px) {
        @include transitionPrefixMultiple(calc(#{$transition-duration} * 5), border);
        border-top: 1px solid $color-black-20;
        border-bottom: 1px solid $color-black-20;

        &:nth-child(1),
        &:nth-child(2) {
            @apply border-t-0;
        }

        &:nth-child(odd) {
            border-right: 1px solid $color-black-20;
        }

        &:nth-child(even) {
            border-left: 1px solid $color-black-20;
        }
    }

    @media (max-width: $screen-under-tablet) {
        background: $color-white-100;
    }

    @screen tablet {
        position: relative;
        z-index: 0;
        min-height: 355px;

        &::before {
            z-index: -1;
            content: '';
            position: absolute;
            top: -1px;
            right: 0;
            bottom: 0;
            left: 0;
            border: 1px solid $color-black-20;
            transition: calc(#{$transition-duration} * 2.5) all $transition-ease;
            will-change: top, right, bottom, left;
            background: $color-white-100;
        }

        &:hover {
            &::before {
                top: -6px;
                right: -6px;
                bottom: -6px;
                left: -6px;
                border-color: $color-black-100;
            }

            .product-teaser--slider-wrapper,
            .btn-add-to-cart {
                @include visible;
            }

            .product-teaser--count {
                @include hidden;
            }
        }
    }

    @screen laptop {
        min-height: 505px;
        &:hover {
            &::before {
                top: -12px;
                right: -12px;
                bottom: -12px;
                left: -12px;
            }
        }

        &.has--no-sidebar-items {
            min-height: 590px !important;
        }

        .product-list-algolia & {
            min-height: 470px;
        }
    }

    @screen desktop {
        &:hover {
            .product-teaser--visual-content {
                .wishlist-icon {
                    display: block;
                }
            }
        }
    }

    > a {
        @apply block;
        padding: 16px 16px 0;
    }

    .btn-add-to-cart {
        font-size: 14px;
        font-weight: 700;
        line-height: 1.429;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 3rem;
        padding-right: 1.25rem;
        padding-left: 1.25rem;
        text-align: center;
        white-space: nowrap;
        text-transform: uppercase;
        color: var(--color-white-100);
        border-radius: 0;
        @apply absolute left-0 right-0 bottom-0 hidden;
        @include hidden;
        background-color: var(--color-black-80);

        @screen laptop {
            @apply block;
        }
    }

    &--visual-content {
        @apply relative;

        @screen desktop {
            .wishlist-icon {
                display: none;
            }
        }
    }

    &--photo {
        overflow: hidden;
        flex-direction: column;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    &--placeholder {
        width: 100%;
        height: 200px;
        background: $color-black-10;

        @screen laptop {
            height: 350px;
        }
    }

    &--badge {
        @apply absolute top-0 left-0;
    }

    &--tags {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        max-width: 80%;
        margin-top: 3px;
    }

    &--wishlist {
        @apply absolute top-0 right-0;

        button {
            @apply outline-none relative;

            &:hover {
                svg {
                    &,
                    * {
                        fill: $color-red-60 !important;
                    }
                }
            }

            &::after {
                top: -16px;
                @apply block absolute;
                right: -16px;
                width: 40px;
                height: 40px;
                content: '';
            }
        }
    }

    &--count {
        @apply text-sm mb-0;
        color: $color-black-60;
    }

    &--name {
        @apply font-OpenSans text-sm;
        font-size: 14px;
        font-weight: normal;
        @include one-line;
        line-height: 20px;
        margin: 2px 0;
    }

    &--brand {
        font-size: 14px;
        font-weight: bold;
        margin: 16px 0 0;
        text-transform: uppercase;
    }

    &--category-name {
        font-size: 12px;
        margin-bottom: 12px;
        color: $color-black-60;
    }

    &--price {
        @apply font-OpenSans;
        font-size: 15px;
        margin-bottom: 8px;
    }

    &--foot {
        @apply relative;
        height: 61px;
        margin-top: 8px;
    }

    &--slider-wrapper {
        @apply absolute top-0 right-0 bottom-0 left-0 hidden;

        @screen laptop {
            @apply block;
            @include hidden;
        }

        .swiper-container {
            .swiper-slide {
                padding: 1px;

                @screen laptop {
                    min-width: 60px;
                    min-height: 60px;
                }
            }
        }
    }

    &--slider-variant {
        .slider {
            .swiper-button-prev,
            .swiper-button-next {
                width: 20px;
                height: 20px;

                svg {
                    width: 20px;
                    height: 20px;
                }
            }

            .swiper-button-next {
                right: -25px !important;
            }

            .swiper-button-prev {
                left: -25px !important;
            }

            .product-teaser-item {
                .btn {
                    height: auto;
                }
            }
        }

        &.is--algolia {
            margin-right: 0;
            margin-left: 0;

            .swiper-container {
                margin: 0 !important;
                padding: 0 !important;
            }
        }

        @screen laptop {
            margin-left: -16px;
            margin-right: -16px;
        }

        .slick-slider {
            @apply relative bg-white;
            padding-right: 16px;
            padding-left: 16px;
        }

        [dir='ltr'] {
            .slick-list {
                z-index: 8;
            }
        }

        .slick-slide {
            > div {
                @apply flex overflow-hidden;
            }
        }

        .product-teaser-item {
            @apply flex overflow-hidden;
            padding: 0 4px;

            .product-list-algolia & {
                height: auto;
                padding: 0;
            }

            .btn {
                @include media-fill;
                @include transitionPrefixMultiple(calc(#{$transition-duration} * 5), border);
                height: 56px;
                padding: 4px;
                border: 1px solid $color-black-20;
                position: relative;

                img {
                    background: $color-black-10;
                }
            }

            &.active,
            &:hover {
                .btn {
                    border-color: $color-black-100;
                }
            }
        }

        .slick-arrow {
            @apply bottom-0 top-0 transform-none opacity-100;
            @include transitionPrefixMultiple(calc(#{$transition-duration} * 5), width);
            z-index: 9;
            width: 16px;
            height: 100%;

            &:before {
                top: 50%;
                left: 50%;
                margin-top: -5px;
                margin-left: -8px;
            }

            &::after {
                z-index: 9;
                @apply absolute top-0 bottom-0;
                content: '';
            }

            &:hover {
                width: 27px;
            }

            &.slick-disabled {
                display: none !important;
            }
        }

        .slick-next {
            right: 0;

            &::after {
                left: 0;
            }
        }

        .slick-prev {
            left: 0;

            &::after {
                right: 0;
            }
        }

        .slick-track {
            .slick-slide {
                &:first-child {
                    .product-teaser-item {
                        padding-left: 0;
                    }
                }
            }
        }
    }

    &-logo {
        position: absolute;

        &-run {
            svg {
                @screen tablet {
                    width: 120px;
                }

                @screen desktopx {
                    width: 180px;
                }
            }
        }

        &-teamsport {
            svg {
                @screen tablet {
                    width: 150px;
                }

                @screen desktopx {
                    width: 220px;
                }
            }
        }
    }
}

.product-recommendation-list {
    &--on-pdp {
        max-width: 1320px !important;
    }

    .product-teaser {
        &:hover {
            &::before {
                top: -6px !important;
                right: -6px !important;
                bottom: -6px !important;
                left: -6px !important;
            }
        }
    }

    .slider {
        padding: 0 16px !important;

        .swiper-button-next {
            right: -16px !important;

            @screen tablet {
                right: 0 !important;
            }

            @screen laptop {
                right: -16px !important;
            }
        }

        .swiper-button-prev {
            left: -16px !important;

            @screen tablet {
                left: 0 !important;
            }

            @screen laptop {
                left: -16px !important;
            }
        }
    }
}
