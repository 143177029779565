@mixin transitionPrefixMultiple($timing, $properties...) {
    $transition: ();

    @each $property in $properties {
        $transition: append(
                $transition, ($timing $property $transition-ease), $separator: comma
        );
    }
    transition: $transition;
}
