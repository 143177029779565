@import 'scss/variables';

.upload-wrapper {
    @apply h-12 bg-blacksport-100 relative;
    input[type='file'] {
        @apply absolute w-12 h-12 opacity-0 cursor-pointer top-0 right-0;
    }

    label {
        @apply absolute z-10 right-0 top-0 h-12 w-12 bg-blacksport-80 text-white cursor-pointer justify-center items-center flex;
        span {
            svg {
                width: 20px;

                &,
                * {
                    fill: $color-white-100;
                }
            }
        }
    }

    p {
        @apply mr-12 leading-4 p-4;
        a {
            @apply cursor-pointer;
        }

        span {
            svg {
                @apply w-4;

                &,
                * {
                    fill: #a6a6a6;
                }
            }
        }

        &.title {
            @apply text-blacksport-50;
        }
    }

    &.dark-mode {
        @apply bg-blacksport-80;
        label {
            @apply bg-white;
            span {
                svg {

                    &,
                    * {
                        fill: inherit;
                    }
                }
            }
        }

        p {
            @apply text-white;
            span {
                svg {
                    &,
                    * {
                        fill: #737373;
                    }
                }
            }
        }
    }
}
