/*
    Keyframes

    Bounce In Down
*/

@keyframes bounceInDown {
    0% {
        opacity: 0;
        transform: translateY(-2000px);
    }

    60% {
        opacity: 1;
        transform: translateY(30px);
    }

    80% {
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/*
    Keyframes

    Scale Branch Map Pin
*/

@keyframes scaleBranchMapPin {
    to {
        transform: translateX(-10%) scale(1.2);
    }
}

/*
    Keyframes

    Fade and Scale To 1
*/

@keyframes fadeAndScaleTo-1 {
    to {
        opacity: 1;
        transform: scale3d(1, 1, 1);
    }
}

/*
    Keyframes

    Animate Characters
*/

@keyframes animateCharactersFromRight {
    30% {
        transform: translate3d(100px, 0, 0) scale3d(1.5, 1.5, 1.5) rotate(-180deg);
        opacity: 0;
    }

    to {
        transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotate(0deg);
        opacity: 1;
    }
}

@keyframes animateCharactersFromTopLeft {
    30% {
        transform: translate3d(-100px, -100px, 0) scale3d(1.5, 1.5, 1.5) rotate(-180deg);
        opacity: 0;
    }

    to {
        transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotate(0deg);
        opacity: 1;
    }
}

/*
    Keyframes

    Scale and Fade In
*/

@keyframes scaleAndfadeIn {
    to {
        opacity: 1;
        transform: scale3d(1, 1, 1);
    }
}

